import React from 'react';

import Loading from './Loading';

class InfiniteScroll extends React.Component {
  parent = window
  prevScroll = 0
  loadMore = false

  componentDidMount() {
    this.addListeners();
    document.documentElement.scrollTop = 0;
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {
    if (this.loadMore && !this.props.loading) {
      document.documentElement.scrollTop = this.prevScroll;
    }
    this.addListeners();
  }

  addListeners = () => {
    document.addEventListener('scroll', this.onScroll, false);
    document.addEventListener('touchmove', this.onScroll, false);
    document.addEventListener('resize', this.onScroll, false);
    document.addEventListener('wheel', this.onWheel, false);
  }

  removeListeners = () => {
    document.removeEventListener('scroll', this.onScroll, false);
    document.removeEventListener('touchmove', this.onScroll, false);
    document.removeEventListener('resize', this.onScroll, false);
    document.removeEventListener('wheel', this.onWheel, false);
  }

  isPassive = () => {
    let passive = false;
    const testOptions = {
      get passive() {
        passive = true;
        return true;
      }
    };

    try {
      document.addEventListener('test', null, testOptions);
      document.removeEventListener('test', null, testOptions);
    } catch (e) {
      // ignore
    }
    return passive;
  }

  onScroll = (e) => {
    const { loading, hasMore, loadMore } = this.props;
    const doc = document.documentElement;
    const offset = doc.scrollHeight - window.scrollY - window.innerHeight;

    if (offset < 550 && !loading && hasMore) {
      this.removeListeners();
      if (loadMore) loadMore();
    }
  }

  onWheel = (e) => {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1 && !this.isPassive()) {
      e.preventDefault();
    }
  }

  render() {
    const {
      children,
      hasMore
    } = this.props;

    if (hasMore || this.loadMore) {
      return (
        <React.Fragment>
          {children}
          <Loading />
        </React.Fragment>
      );
    }
    else return children;
  }
}

export default InfiniteScroll;

import React from 'react';
import { MdArrowBack, MdMoreHoriz } from 'react-icons/md';

import styles from './AboutHeader.module.css';

const AboutHeader = ({
  history,
  title,
  toggle
}) => (
  <div className={styles.contain}>
    <div
      onClick={() => history.goBack()}
      className={styles.iconContain}
    >
      <MdArrowBack size={24} />
    </div>
    <h3 className={styles.title}>
      {title}
    </h3>
    <div
      onClick={toggle}
      className={styles.iconContain}
    >
      <MdMoreHoriz size={24} />
    </div>
  </div>
);

export default AboutHeader;
export default function adminLinkPermission({
  user,
  orgId,
  isOrg
}) {
  if (isOrg && user) {
    if (user.admin) {
      return true;
    }
    else if (user.externalAccounts) {
      return user.externalAccounts.includes(orgId)
    }
  }
  return false;
}

import React from 'react';
import { MdSearch, MdClear, MdArrowBack } from 'react-icons/md';

import styles from './SearchBoxLight.module.css';

class SearchBoxLight extends React.Component {
  render() {
    const {
      onChange = () => {},
      onClear = () => {},
      onFocus = () => {},
      value,
      containClass,
      inputClass,
      goBack,
      autoFocus,
      disabled
    } = this.props;

    return (
      <div className={containClass ? `${containClass} ${styles.searchContain}` : styles.searchContain}>
        {goBack
          ? <div className={styles.searchIcon} onClick={() => goBack()}>
              <MdArrowBack size={22} color="var(--text)" />
            </div>
          : <div className={styles.searchIcon}>
              <MdSearch size={22} color="var(--text)" />
            </div>
        }
        <input
          className={inputClass ? `${inputClass} ${styles.searchInput}` : styles.searchInput}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          placeholder="Search for people and events"
          style={{paddingRight: value !== '' ? 36 : 12 }}
          autoFocus={autoFocus}
          ref={(ref) => this.input = ref}
        />
        {(value !== '' && !disabled) &&
          <div
            className={styles.searchClear}
            onClick={() => {
              this.input.focus();
              onClear();
            }}
          >
            <MdClear size={16} color="var(--text)" />
          </div>
        }
      </div>
    );
  }
}

export default SearchBoxLight;

import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import styles from './Admin.module.css';

class AddExternalAccount extends React.Component {
  state = {
    userId: '',
    orgId: ''
  }

  apply = async (addExternalAccount) => {
    try {
      const { data } = await addExternalAccount({
        variables: {
          userId: this.state.userId,
          externalAccountId: this.state.orgId
        }
      });
      if ('addExternalAccount' in data) {
        alert('Success!');
      }
      else {
        alert('Failed, please try again.');
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div style={{ padding: '20px' }}>
        <h1>Add External Account</h1>
        <p>
          User Id
          <input
            className={styles.input}
            type="text"
            value={this.state.userId}
            onChange={e => this.setState({ userId: e.target.value })}
          ></input>
        </p>
        <p>
          Org Id
          <input
            className={styles.input}
            type="text"
            value={this.state.orgId}
            onChange={e => this.setState({ orgId: e.target.value })}
          ></input>
        </p>
        <Mutation mutation={AddExternalAccountMutation}>
          {(addExternalAccount) => (
            <button
              className={styles.submitButton}
              disabled={!(this.state.userId.length > 0 && this.state.orgId.length > 0)}
              onClick={() => this.apply(addExternalAccount)}
            >
              Apply
            </button>
          )}
        </Mutation>
      </div>
    );
  }
}

const AddExternalAccountMutation = gql`
  mutation addExternalAccount($userId: ID!, $externalAccountId: ID!) {
    addExternalAccount(userId: $userId, externalAccountId: $externalAccountId)
  }
`;

export default AddExternalAccount;

import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import OrgEventForm from './OrgEventForm';

const EventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      title
      alternateTitles
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      urls {
        url
      }
      tags
      hideTime
      private
    }
  }
`;

const CreateOrgEvent = ({ match: { params: { id } } }) => (
  <Query
    query={EventQuery}
    variables={{
      eventId: id
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <h1>Loading...</h1>;
      if (error) return `Error: ${error}`;
      return (
        <OrgEventForm id={id} defaultValues={data.event} />
      )
    }}
  </Query>
);

export default CreateOrgEvent;

import React from 'react';
import { MdLocationOn } from 'react-icons/md';

import styles from './TagsRow.module.css';

class TagsRow extends React.Component {
  scrollToBeginning = () => {
    this.tagsRow.scrollLeft = 0;
  }

  render() {
    const {
      light = false,
      tags = [],
      activeTag = undefined,
      selectTag = () => {},
      locationToggle = () => {}
    } = this.props;

    return (
      <div className={light ? styles.lightContain : styles.contain}>
        <div className={light ? styles.lightLeft : styles.leftFade} />
        <div
          className={light ? styles.lightHeader : styles.sortHeader}
          ref={(el) => this.tagsRow = el}
        >
          <div
            className={styles.iconContain}
            onClick={locationToggle}
          >
            <MdLocationOn size={24} color={light ? '#fff' : 'var(--main)'} />
          </div>
          <div
            className={styles.tagsRow}
          >
            {activeTag &&
              <div
                className={light ? styles.lightActive : styles.activeTag}
                onClick={() => {
                  selectTag(activeTag);
                  this.scrollToBeginning();
                }}
              >
                {activeTag}
              </div>
            }
            {tags.map((item, i) => (
              <div
                key={i}
                className={light ? styles.lightTag : styles.tag}
                onClick={() => {
                  selectTag(item);
                  this.scrollToBeginning();
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className={light ? styles.lightFade : styles.fade} />
      </div>
    );
  }
};

export default TagsRow;

import React from 'react';

import styles from './IconInput.module.css';

const IconInput = ({ 
  icon, 
  content, 
  iconFunc = () => {} 
}) => (
  <div className={styles.contain}>
    <div className={styles.inner}>
      <div 
        className={styles.icon}
        onClick={iconFunc}
      >
        {icon && icon}
      </div>
      {content && content}
    </div>
  </div>
);

export default IconInput;
import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import GroupForm from './GroupForm';

const CreateGroup = ({ 
  match, 
  history, 
  group = { title: '', pings: [] }, 
  toggle = false 
}) => (
  <Mutation 
    mutation={CreateGroupMutation}
    awaitRefetchQueries
  >
    {(createGroup) => (
      <GroupForm 
        type="Create"
        mutate={createGroup}
        group={group}
        toggle={toggle}
        history={history}
        match={match}
      />
    )}
  </Mutation>
)

const CreateGroupMutation = gql`
  mutation createGroup($title: String, $pings: [PingInput]!) {
    createGroup(title: $title, pings: $pings) {
      id
      title
      pings {
        type
        value
        user {
          id
          name
          profileImage
        }
      }
    }
  }
`;

export default CreateGroup;
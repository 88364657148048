import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import styles from '../Home/Home.module.css';
import TabBar from '../Common/TabBar';
import Header from '../Common/Header';
import EventItem from '../Common/EventItem';
import Loading from '../Common/Loading';
import Overlay from '../Common/Overlay';
import AddToCal from '../Common/AddToCal';
import EmptyBanner from './EmptyBanner';
import InfiniteScroll from '../Common/InfiniteScroll';
import ErrorMsg from '../Common/ErrorMsg';
import { getUser, setUser } from '../../utils/userState';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import pluralize from '../../utils/pluralize';
import { fakeEvents } from '../../utils/fakeData';

class Events extends React.Component {
  state = {
    showPrivate: false,
    showOverlay: false,
    showAddToCal: false,
    eventToAdd: {},
    shareType: 'event',
    scoreType: 'date'
  }

  user = getUser();

  componentDidMount() {
    const user = getUser();
    this.user = user;

    if (user.newUser) {
      this.setState({ showOverlay: true });
      setUser(Object.assign({}, user, { newUser: false }));
    }
  }

  togglePrivate = () => {
    this.setState({
      showPrivate: !this.state.showPrivate
    });
  }

  hideOverlay = () => {
    this.setState({ showOverlay: false })
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({
      showAddToCal: true,
      eventToAdd: eventInfo,
      shareType: type
    });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  updateScoreType = (type) => {
    if (type === 'date') {
      this.setState({ scoreType: 'date' });
    }
    else {
      this.setState({ scoreType: 'friends' });
    }

    document.documentElement.scrollTop = 0;
  };

  render() {
    const { match, history } = this.props;
    const {
      showPrivate,
      showOverlay,
      showAddToCal,
      shareType,
      scoreType,
      eventToAdd
    } = this.state;

    return (
      <div>
      <Header match={match} />
        <div className="body">
          <div className={styles.eventsContain}>
            <div className={styles.homeContain}>
              {fakeEvents().results.map((eventRecord, index) => (
                <EventItem
                  event={eventRecord}
                  showAddToCal={this.showAddToCal}
                  hideAddToCal={this.hideAddToCal}
                  key={index}
                />
              ))}
            </div>
          </div>
          <TabBar match={match} />
        </div>
      </div>
    );

    /*
    return (
      <div>
        <Header
          match={match}
          customNav={
            <div className={styles.headerSort}>
              <div className={styles.sortButtons}>
                <button
                  onClick={() => this.updateScoreType('date')}
                  className={scoreType === 'date' ? styles.activeSort : styles.sortButton}
                >
                  Sort by date
                </button>
                <button
                  onClick={() => this.updateScoreType('friends')}
                  className={scoreType === 'friends' ? styles.activeSort : styles.sortButton}
                >
                  Sort by friends
                </button>
              </div>
            </div>
          }
          share={this.showAddToCal}
          pageUserId={this.user.id}
        />
        <div className="body">
          <div className={styles.contain} ref={(ref) => this.parent = ref}>
            <Query
              query={FeedQuery}
              variables={{
                userId: this.user.id,
                pagination: {
                  offset: 0,
                  limit: 10
                },
                score: scoreType
              }}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data, fetchMore }) => {
                if (error) return <ErrorMsg error={error} />;

                const { feed } = data;
                if (!feed) return <Loading />;

                const { privateEvents = [] } = feed;
                if (!feed.results.length && !privateEvents.length) {
                  return (
                    <div className={styles.eventsList}>
                      <h3 className={styles.sectionHeader}>Events</h3>
                      <EmptyBanner history={history} />
                    </div>
                  );
                }

                const loadMore = () => {
                  fetchMore({
                    variables: {
                      pagination: {
                        offset: feed ? feed.results.length : 0,
                        limit: 10
                      }
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const fetchMoreResultPrivateEvents = fetchMoreResult.feed.privateEvents ? fetchMoreResult.feed.privateEvents : [];
                      const newRes = Object.assign({}, prev, {
                        feed: {
                          total: prev.feed.total,
                          results: [...prev.feed.results, ...fetchMoreResult.feed.results],
                          privateEvents: [...prev.feed.privateEvents, ...fetchMoreResultPrivateEvents],
                          __typename: 'EventList'
                        }
                      });
                      return newRes;
                    }
                  });
                };

                return (
                  <InfiniteScroll
                    loading={loading}
                    getParent={() => this.parent}
                    hasMore={feed ? (feed.results.length < feed.total) : false}
                    loadMore={loadMore}
                    match={match}
                  >
                    <div className={styles.eventsList}>
                      <h3 className={styles.sectionHeader}>Events</h3>
                      {(privateEvents && privateEvents.length > 0) &&
                        <div
                          className={styles.privateContain}
                          style={showPrivate ? { paddingBottom: 0 } : {}}
                        >
                          <div
                            className={styles.privateToggle}
                            onClick={this.togglePrivate}
                          >
                            {showPrivate
                              ? <React.Fragment>
                                  Hide private events
                                  <MdKeyboardArrowUp size={24} />
                                </React.Fragment>
                              : <React.Fragment>
                                  You've been invited to {privateEvents.length} private {pluralize('event', privateEvents.length)}
                                  <MdKeyboardArrowDown size={24} />
                                </React.Fragment>
                            }

                          </div>
                          <div
                            className={styles.privateItems}
                            style={!showPrivate ? { height: 0 } : { padding: '24px 0 0' }}
                            // style={{ transform: !showPrivate ? 'scaleY(0)' : 'scaleY(1)' }}
                          >
                            {privateEvents.map((event) => (
                              <EventItem
                                event={event}
                                showAddToCal={this.showAddToCal}
                                hideAddToCal={this.hideAddToCal}
                                key={event.id}
                              />
                            ))}
                          </div>
                        </div>
                      }
                      {feed && feed.results.map((event) => (
                        <EventItem
                          event={event}
                          showAddToCal={this.showAddToCal}
                          hideAddToCal={this.hideAddToCal}
                          key={event.id}
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                );
              }}
            </Query>
          </div>
        </div>
        <TabBar match={match} />
        <AddToCal
          shown={showAddToCal}
          event={eventToAdd}
          hide={this.hideAddToCal}
          type={shareType}
        />
        {showOverlay &&
          <Overlay
            hideOverlay={this.hideOverlay}
            history={history}
          />
        }
      </div>
    )
    */
  }
}

const FeedQuery = gql`
  query feed($userId: ID!, $pagination: Pagination!, $score: FeedScoreType) {
    feed(userId: $userId, pagination: $pagination, score: $score) {
      total
      results {
        id
        host {
          id
          name
          email
          profileImage
        }
        private
        image
        title
        addedCount
        description
        location {
          lat
          lon
        }
        address
        eventTimestampStart
        eventTimestampEnd
        createdAt
        haveAdded
        topAdded {
          id
          name
          profileImage
        }
      }
      privateEvents {
        id
        host {
          id
          name
          email
          profileImage
        }
        private
        image
        title
        addedCount
        description
        address
        eventTimestampStart
        eventTimestampEnd
        createdAt
        haveAdded
        topAdded {
          id
          name
          profileImage
        }
      }
    }
  }
`;

export default Events;

import React from 'react';
import dateFns from 'date-fns';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const isInArray = (array, date) => (
  !!array.find((item) => dateFns.isSameDay(item, date))
);

const dateInArray = (array, date) => (
  array.some((item) => isSameDay(new Date(item), new Date(date)))
);

const isSameDay = (one, two) => {
  return one.getDate() === two.getDate()
    && one.getMonth() === two.getMonth()
    && one.getYear() === two.getYear()
}

export {
  months,
  days,
  isInArray,
  dateInArray,
  isSameDay
}

export default function formatDate(dateStr, showMonth = true, showDay = true, showMorning = true, showTime = true) {
  const date = new Date(dateStr)
  const day = date.getDay();
  let monthNum;
  let dateNum;
  let morning = true;
  let hour = date.getHours();
  let min = date.getMinutes().toString();

  if (hour === 12 && min === '0') {
    hour = 'Noon';
    showMorning = false;
    min = ''
  };

  if (hour === 0) {
    hour = 12
    if (min === '0') {
      hour = 'Midnight';
      showMorning = false;
      min = ''
    }
  };

  if (hour > 12) {
    hour = hour % 12;
    morning = false;
  }

  if (min.length === 1) min = '0' + min;

  if (showMonth) {
    monthNum = date.getMonth();
    dateNum = date.getDate();
  }
  else if (showDay) {
    dateNum = date.getDate();
  }

  return <span>{days[day]}{showMonth ? `, ${months[monthNum]} ${dateNum}` : showDay && <span>&nbsp;{dateNum}</span>}{showTime && <span>&nbsp;at&nbsp;<strong>{hour}{min.length > 1 ? `:${min}` : ''} {showMorning ? (morning ? 'am' : 'pm') : ''}</strong></span>}</span>
}

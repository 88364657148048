import React from 'react';

import styles from './IconSection.module.css';

const IconSection = ({
  icon,
  content,
  style,
  iconStyle,
  contentStyle
}) => (
  <div
    className={styles.section}
    style={style}
  >
    <div className={styles.innerSection}>
      <div
        className={styles.icon}
        style={iconStyle}
      >
        {icon}
      </div>
      <div
        className={styles.content}
        style={contentStyle}
      >
        {content}
      </div>
    </div>
  </div>
);

export default IconSection;

import React from 'react';
import { MdImage } from 'react-icons/md';

import styles from './ImageInput.module.css';
import ImageUpload from './ImageUpload';
import IconSection from './IconSection';

const ImageInput = ({
  onChange,
  onClose,
  image,
  expanded
}) => (
  <IconSection
    icon={image
      ? <img
          src={image}
          className={styles.previewImage}
          alt=""
        />
      : <MdImage
          size={20}
          color="var(--main)"
        />
    }
    content={
      <ImageUpload
        onChange={onChange}
        onClose={onClose}
        image={image}
        expanded={expanded}
      />
    }
  />
);

export default ImageInput;

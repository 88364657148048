import React from 'react';
import dateFns from 'date-fns';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import styles from './Calendar.module.css';
import { dateInArray, isSameDay } from '../../utils/formatDate';

class Calendar extends React.Component {
  state = {
    viewDate: this.props.viewDate || new Date(),
    selectedDate: this.props.selectedDate || undefined,
    eventDates: this.props.eventDates || undefined
  };

  shouldComponentUpdate(nextProps, nextState) {
    const state = nextState.eventDates;
    const props = nextProps.eventDates;

    if (state.length !== props.length) {
      this.setState({
        eventDates: props
      });
    }

    return true;
  }

  renderHeader() {
    let label = dateFns.format(this.state.viewDate, 'MMMM YYYY');
    if (this.props.loading) label = 'Loading...';

    return (
      <div className={styles.header}>
        <div
          className={styles.colStart}
          onClick={this.prevMonth}
        >
          <MdChevronLeft className={styles.icon} />
        </div>
        <div
          onClick={() => {this.onDateClick(undefined)}}
          className={styles.colCenter}
          style={this.state.selectedDate === undefined ? {color: 'var(--secondary)'} : {}}
        >
          <span>{label}</span>
        </div>
        <div
          className={styles.colEnd}
          onClick={this.nextMonth}
        >
          <MdChevronRight className={styles.icon} />
        </div>
      </div>
    );
  }

  renderDays() {
    const days = ['Su','Mo','Tu','We','Th','Fr','Sa'];

    return (
      <div className={styles.days}>
        {days.map((day, i) => (
          <div
            key={i}
            className={styles.colCenter}
          >
            {day}
          </div>
        ))}
      </div>
    );
  }

  renderCells() {
    const { viewDate, selectedDate, eventDates } = this.state;
    const monthStart = dateFns.startOfMonth(viewDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = dateFns.format(day, 'D');
        const cloneDay = day;

        let innerClass = `${styles.innerCell}`;

        if (eventDates.length) {
          if (dateInArray(eventDates, cloneDay)) {
            innerClass += ` ${new Date() < new Date(cloneDay)
              ? styles.event
              : styles.pastEvent
            }`;
          }
        }

        if (isSameDay(new Date(), cloneDay)) {
          innerClass += ` ${styles.today}`;
        }

        if (selectedDate && isSameDay(new Date(selectedDate), new Date(cloneDay))) {
          innerClass += ` ${styles.selected}`;
        }

        days.push(
          <div
            key={day}
            className={styles.cell}
            onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
          >
            <div className={innerClass}>
              <span className={styles.number}>
                {formattedDate}
              </span>
            </div>
          </div>
        );

        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div
          key={day}
          className={styles.row}
        >
          {days}
        </div>
      );

      days = [];
    }
    return <div className={styles.contain}>{rows}</div>;
  }

  onDateClick = day => {
    if (!this.props.selector && dateFns.isSameDay(this.state.selectedDate, day)) day = undefined;
    this.setState({ selectedDate: day });
    if (this.props.onChange) this.props.onChange(day);
  };

  nextMonth = () => {
    this.setState({ viewDate: dateFns.addMonths(this.state.viewDate, 1) });
  };

  prevMonth = () => {
    this.setState({ viewDate: dateFns.subMonths(this.state.viewDate, 1) });
  };

  render() {
    return (
      <div className={styles.calendar}>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

Calendar.defaultProps = {
  onChange: () => {},
  selectedMonth: new Date(),
  selectedDate: undefined,
  eventDates: [],
  loading: false
}

export default Calendar;

import React from 'react';

import Modal from './Modal';
import Button from './Button';

import styles from './ImageGallery.module.css';
import { DEFAULT_IMAGES } from '../../utils/constants';

const ImageGallery = ({ shown, hide, select }) => (
  <Modal
    shown={shown}
    hide={hide}
    title="Choose an Image"
  >
    <div className={styles.grid}>
      {DEFAULT_IMAGES.map((img, i) => (
        <img
          src={img}
          key={i}
          alt=""
          className={styles.gridImg}
          onClick={() => select(i)}
        />
      ))}
    </div>
    <Button
      onClick={hide}
      label="Close"
      type="button"
    />
  </Modal>
);

export default ImageGallery;

import React from 'react';

import styles from './Loading.module.css';
import funnies from '../../utils/funnies';

const Loading = ({
  small = false,
  message = 'Loading...'
}) => {
  if (!small) {
    return (
      <div className={styles.contain}>
        <div className={styles.loader}>
          <div className={styles.load1} />
          <div className={styles.load2} />
          <div className={styles.load3} />
        </div>
        <p className={styles.label}>{message}</p>
        <p className={styles.label}>{funnies()}</p>
      </div>
    );
  }
  else {
    return (
      <div className={styles.smallContain}>
        <div className={styles.loader}>
          <div className={styles.load1} />
          <div className={styles.load2} />
          <div className={styles.load3} />
        </div>
        <p className={styles.smallLabel}>{message}</p>
      </div>
    );
  }
}

export default Loading;

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';

import {
  PRODUCTION_GRAPHQL_ENDPOINT,
  GRAPHQL_ENDPOINT
} from './utils/constants';
import { getToken, setIp, getIp } from './utils/userState';
import { AppProvider } from './components/Context/AppProvider';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import getTimezoneOffset from './utils/getTimezoneOffset';
import getIpAddress from './utils/getIpAddress';

async function asyncInit() {
  try {
    const ip = await getIpAddress();
    setIp(ip);
  }
  catch (error) {
    console.error(error);
  }
}

asyncInit();

const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'production' ? PRODUCTION_GRAPHQL_ENDPOINT : GRAPHQL_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
      timezoneOffset: getTimezoneOffset(),
      clientIp: getIp()
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    httpLink
  ]),
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: object => {
      if (object.__typename === 'User') {
        return object.id;
      }
      else {
        return defaultDataIdFromObject(object);
      }
    }
  })
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <AppProvider>
      <App />
    </AppProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

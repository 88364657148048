/*global faker*/

export function fakeUser() {
  return {
    id: faker.random.number(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    email: faker.internet.email(),
    profileImage: faker.image.avatar(),
    bio: faker.lorem.paragraph(),
    followingCount: faker.random.number({ min: 10, max: 500 }),
    followersCount: faker.random.number({ min: 10, max: 500 }),
    upcomingEventsCount: faker.random.number({ min: 10, max: 500 }),
    address: '1800 Wallaby Drive, San Fransisco CA 24424',
    location: {
      lat: faker.address.latitude(),
      lon: faker.address.longitude()
    }
  };
}

export function fakeEvent() {
  const addedCount = faker.random.number({ min: 5, max: 20 })

  return {
    id: faker.random.number(),
    host: fakeUser(),
    title: faker.lorem.lines(),
    image: faker.image.image(),
    description: faker.lorem.paragraph(),
    location: {
      lat: faker.address.latitude(),
      lon: faker.address.longitude()
    },
    address: '1800 Wallaby Drive, San Fransisco CA 24424',
    eventTimestampStart: faker.date.future(),
    eventTimestampEnd: faker.date.future(),
    createdAt: faker.date.past(),
    haveAdded: false,
    addedCount: addedCount,
    topAdded: [fakeUser(), fakeUser()],
    added: Array(addedCount).fill(addedCount).map(() => fakeUser()),
    hideTime: false,
    private: false
  };
}

export function fakeEvents() {
  const eventsCount = faker.random.number({ min: 20, max: 50 })

  const events = {
    total: eventsCount,
    results: Array(eventsCount).fill(null).map(() => fakeEvent())
  };

  return events;
}

export function fakeCalendarDates() {
  return Array(faker.random.number({ min: 5, max: 20 })).fill(null).map(() => faker.date.future());
}

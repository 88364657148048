import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.innerFooter}>
      <Link
        to="/SignIn"
        className={styles.footerButton}
      >
        <span>Sign Up</span>
      </Link>
      <Link
        to="/SignIn"
        className={styles.footerMessage}
      >
        <p>Have an account? <span>Login</span></p>
      </Link>
    </div>
  </div>
);

export default Footer;

import dateFns from 'date-fns';

const formatTime = (date) => {
  date = new Date(date);
  return date.toISOString().replace(/-|:|\.\d+/g, '');
};

const getEndTime = (e) => {
  return e.eventTimestampEnd
    ? formatTime(e.eventTimestampEnd)
    : formatTime(dateFns.addMinutes(new Date(e.eventTimestampStart), 60));
}

const yahooDuration = (e) => {
  const eventDuration = dateFns.differenceInMinutes(e.eventTimestampEnd, e.eventTimestampStart);

  const yahooHourDuration = eventDuration < 600
    ? '0' + Math.floor((eventDuration / 60))
    : Math.floor((eventDuration / 60)) + '';

  const yahooMinuteDuration = eventDuration % 60 < 10
    ? '0' + eventDuration % 60
    : eventDuration % 60 + '';

  return yahooHourDuration + yahooMinuteDuration;
}

const ics = (e) => {
  return `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:${document.URL
    }%0ADTSTART:${formatTime(e.eventTimestampStart) || ''
    }%0ADTEND:${getEndTime(e) || ''
    }%0ASUMMARY:${encodeURIComponent(e.title) || ''
    }%0ADESCRIPTION:${encodeURIComponent(e.description) || ''
    } - More details on Appearix: https://appearix.com/Event/${e.id
    }%0ALOCATION:${encodeURIComponent(e.address) || ''
    }%0AEND:VEVENT%0AEND:VCALENDAR`;
}

const outlook = (e) => {
  return `https://outlook.live.com/owa/?rru=addevent&startdt=${formatTime(e.eventTimestampStart) || ''
    }&enddt=${getEndTime(e) || ''
    }&subject=${encodeURIComponent(e.title) || ''
    }&location=${encodeURIComponent(e.address) || ''
    }&body=${encodeURIComponent(e.description) || ''
    }&allday=false&uid=1558305504988_57114035570&path=/calendar/view/Month`;
}

const yahoo = (e) => {
  const duration = e.eventTimestampEnd
    ? yahooDuration(e)
    : '0100';

  return `http://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(e.title) || ''
    }&st=${formatTime(e.eventTimestampStart) || ''
    }&dur=${duration || ''
    }&desc=${encodeURIComponent(e.description) || ''
    }%0AMore details on Appearix: https://appearix.com/Event/${e.id
    }&in_loc=${encodeURIComponent(e.address) || ''}`;
}

const google = (e) => {
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(e.title) || ''
    }&dates=${formatTime(e.eventTimestampStart) || ''
    }/${getEndTime(e) || ''
    }&details=${e.description ? encodeURIComponent(e.description) : ''
    }%0AMore details on Appearix: https://appearix.com/Event/${e.id
    }&location=${encodeURIComponent(e.address) || ''
    }&sprop=&sprop=name:`;
}

export default function getUrl(type, e) {
  if (!Object.keys(e).length) {
    return false;
  }
  else if (type === 'Google') {
    return google(e);
  }
  else if (type === 'iCal') {
    return ics(e);
  }
  else if (type === 'Outlook') {
    return outlook(e);
  }
  else if (type === 'Yahoo') {
    return yahoo(e);
  }
  else {
    return false;
  }
}

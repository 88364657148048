import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import OrgForm from './OrgForm';

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      id
      name
      alternateNames
      profileImage
      bio
      tags
      urls {
        url
      }
      address
      location {
        lat
        lon
      }
    }
  }
`;

const CreateOrg = ({ match: { params: { id } } }) => (
  <Query 
    query={ProfileQuery}
    variables={{
      userId: id
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <h1>Loading...</h1>;
      if (error) return `Error: ${error}`;
      return (
        <OrgForm id={id} defaultValues={data.profile} />
      ) 
    }}
  </Query>
);

export default CreateOrg;
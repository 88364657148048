import React from 'react';

import styles from './Empty.module.css';
import elephant from '../../images/elephant.png';

const Empty = ({ page }) => {
  let top = '';
  let bottom = '';

  if (page === 'events') {
    top = 'Want more events?';
    bottom = 'Easy! Follow more people.';
  }
  else if (page === 'profile') {
    top = 'Want to see more events here?';
    bottom = 'Easy! Ping them about events.';
  }
  else if (page === 'notifications') {
    top = 'Want more notifications?';
    bottom = 'Easy! Start expanding your network.';
  }
  else if (page === 'followers') {
    bottom = 'No followers to show.';
  }
  else if (page === 'following') {
    top = 'Not following anybody?';
    bottom = 'Easy! Follow more people';
  }

  return (
    <div className={styles.contain}>
      <p className={styles.top}>{top}</p>
      <img src={elephant} alt="" className={styles.img} />
      <p className={styles.bottom}>{bottom}</p>
    </div>
  )
};


export default Empty;

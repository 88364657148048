import { fakeUser } from './fakeData'

export const USER_KEY = 'appearixUser';
const TOKEN_KEY = 'appearixToken';
const LOCATION_KEY = 'appearixUserLocation';
const IP_KEY = 'appearixUserIp';

export function signIn({ token, user }) {
  setUser(user);
  setToken(token);
}

export function signOut() {
  removeUser();
  removeToken();
}

export function isSignedIn() {
  const user = getUser();
  return user ? user: false;
}

export function isAdmin() {
  const user = getUser();
  return 'admin' in user;
}

export function hasExternalAccounts() {
  const user = getUser();
  return 'externalAccounts' in user;
}

export function getUser() {
  // let userObj = localStorage.getItem(USER_KEY);
  // return userObj ? JSON.parse(userObj) : undefined;

  return fakeUser();
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function setUser(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

function removeUser() {
  localStorage.removeItem(USER_KEY);
}

function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function setLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem(LOCATION_KEY, JSON.stringify(
        {
          lat: `${position.coords.latitude}`,
          lon: `${position.coords.longitude}`
        }
      ));
    });
  }
}

export function getLocation() {
  const location = localStorage.getItem(LOCATION_KEY);
  return location ? JSON.parse(location) : null;
}

export function setIp(ip) {
  localStorage.setItem(IP_KEY, ip);
}

export function getIp() {
  return localStorage.getItem(IP_KEY);
}

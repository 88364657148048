import React from 'react';
import { MdLink } from 'react-icons/md';

import styles from './UrlPreview.module.css';
import EventSection from './EventSection';

class UrlPreview extends React.Component {
  state = {
    imageError: false,
    iconError: false
  }

  onError = (name) => {
    this.setState({ [name]: true })
  }

  render() {
    const { url = {}, iconColor, index } = this.props;
    return (
      <a
        href={url.url}
        target="_blank"
        rel="noopener noreferrer"
        className={index > 0 ? styles.second : styles.preview}
      >
        <EventSection
          icon={
            (url.image && !this.state.imageError)
              ? <img
                  src={url.image}
                  alt=""
                  className={styles.image}
                  onError={() => {this.onError('imageError')}}
                />
              : (url.icon && !this.state.iconError)
                ? <img
                    src={url.icon}
                    alt=""
                    className={styles.icon}
                    onError={() => {this.onError('iconError')}}
                  />
                : <MdLink color={iconColor} size={20} />
          }
          content={
            <div style={{width: '100%'}}>
              {url.title && <p className={styles.title}>{url.title}</p>}
              {url.url && <p className={styles.url}>{url.url}</p>}
              {url.snippet && <p className={styles.snippet}>{url.snippet}</p>}
            </div>
          }
        />
      </a>
    )
  }
}

export default UrlPreview;

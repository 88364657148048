import React from 'react';

import styles from './Modal.module.css';

const Modal = ({
  shown,
  hide,
  title,
  align = 'center',
  color = '#ddd',
  shadow,
  children,
  className,
  containStyle,
  modalStyle,
  backgroundStyle,
}) => {
  const showStyle = shown
    ? { visibility: 'visible', opacity: 1 }
    : { visibility: 'hidden', opacity: 0 };

  const containTheme = {
    alignItems: align,
    ...showStyle,
    ...containStyle
  };
  
  const modalTheme = {
    borderColor: color,
    boxShadow: `var(--shadow${shadow ? `-${shadow}` : ''})`,
    ...modalStyle
  };

  return (
    <div
      className={`${styles.contain} ${className}`}
      style={containTheme}
    >
      <div
        className={styles.background}
        onClick={() => hide && hide()}
        style={backgroundStyle}
      />
      <div
        className={styles.modal}
        style={modalTheme}
      >
        {title && <p className={styles.title}>{title}</p>}
        {shown && children}
      </div>
    </div>
  )
}

export default Modal;

import React from 'react';
import { Link } from 'react-router-dom';

import styles from './AboutBanner.module.css';
import concertBG from '../../images/concertBG.png';

const AboutBanner = ({ landing = false }) => (
  <div className={styles.contain}>
    <div className={styles.fade} />
    <img src={concertBG} alt="" className={styles.background} />
    <div className={styles.header}>
      <h1 className={styles.title}>appear<span>ix</span></h1>
      <h2 className={styles.count}>Find friends, events &amp; more</h2>
      <h2 className={styles.tagline}>You could be there!</h2>
      {landing &&
        <Link 
          to="/About" 
          className={styles.more}
        >
          Learn More
        </Link>
      }
    </div>
  </div>
);

/*
<h2 className={styles.count}>302,451 events and counting</h2>
*/

export default AboutBanner;
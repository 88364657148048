import React from 'react';
import { Link } from 'react-router-dom';

const Admin = () => (
  <div style={{ padding: '10px' }}>
    <h1>Admin</h1>
    <Link to="/CreateOrg">Create Organization</Link>
    <br />
    <code>To create event for organization: https://appearix.com/CreateOrgEvent/organizationId</code>
    <br />
    <Link to="/AddExternalAccount">Link an existing organization to a user</Link>
  </div>
);

export default Admin;

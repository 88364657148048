import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import isUrl from 'is-url';
import {
  MdDirections,
  MdMoreHoriz,
  MdLocationOn,
  MdPeople,
  MdAccessTime,
  MdEvent,
  MdCheck,
  MdRemoveCircleOutline,
  MdAddCircleOutline,
  MdInfoOutline,
  MdAdd,
  MdLock,
} from 'react-icons/md';
import { FaPaperPlane } from 'react-icons/fa';
import { IoMdShareAlt } from 'react-icons/io';

import styles from './EventPage.module.css';
import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import Loading from '../Common/Loading';
import AddToCal from '../Common/AddToCal';
import EventSection from './EventSection';
import UrlPreview from './UrlPreview';
import Modal from '../Common/Modal';
import SignUpModal from '../Common/SignUpModal';
import MenuModal from '../Common/MenuModal';
import PingModal from './PingModal';
import ErrorMsg from '../Common/ErrorMsg';
import { getUser } from '../../utils/userState';
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants';
import downloadCsv from '../../utils/downloadCsv';
import adminLinkPermission from '../../utils/adminLinkPermission';
import { fakeEvent } from '../../utils/fakeData';

const Marker = () => (
  <div className={styles.marker} />
);

class EventPage extends React.Component {
  state = {
    showMenu: false,
    showConfirm: false,
    showAddToCal: (this.props.location && this.props.location.state && this.props.location.state.created) || false,
    eventToAdd: {},
    shareType: 'event',
    showPing: false,
    pings: [],
    pingShown: [],
    expandDesc: false,
    showSignUp: false,
    signUpInfo: false,
    showOverlay: true
  }

  user = getUser()

  renderAddButton = (eventInfo) => {
    if (dateFns.isPast(eventInfo.eventTimestampStart)) {
      return (
        <div className={styles.pastButton}>
          <MdAccessTime size={20} className={styles.mdIcon} />
          <span>Expired</span>
        </div>
      )
    }
    else if (eventInfo.haveAdded) {
      return (
        <Mutation
          mutation={RemoveEventMutation}
          update={(cache) => {
            const { event } = cache.readQuery({
              query: EventQuery,
              variables: {
                eventId: this.props.match.params.id
              }
            });
            cache.writeQuery({
              query: EventQuery,
              data: {
                event: Object.assign({}, event, {
                  haveAdded: false,
                  added: eventInfo.added.filter(item => item.id !== this.user.id)
                })
              }
            });
          }}
        >
          {(removeEvent) => (
            <button
              onClick={() => {
                if (this.user) {
                  removeEvent({ variables: { eventId: this.props.match.params.id } })
                  this.hideAddToCal();
                }
              }}
              className={styles.removeButton}
            >
              <MdCheck size={20} className={styles.mdIcon} />
              <span>Added</span>
            </button>
          )}
        </Mutation>
      );
    }
    else {
      return (
        <Mutation
          mutation={AddEventMutation}
          update={(cache) => {
            const { event } = cache.readQuery({
              query: EventQuery,
              variables: {
                eventId: this.props.match.params.id
              }
            });
            cache.writeQuery({
              query: EventQuery,
              data: { event: Object.assign({}, event, { haveAdded: true, added: [...event.added, this.user] }) }
            });
          }}
        >
          {(addEvent) => (
            <button
              onClick={() => {
                if (!this.user) {
                  this.toggleSignUp(eventInfo.id, eventInfo.title, 'adding');
                }
                else {
                  addEvent({ variables: { eventId: this.props.match.params.id } })
                  this.showAddToCal(eventInfo)
                }
              }}
              className={styles.addButton}
            >
              <MdAdd size={20} className={styles.mdIcon} />
              <span>Add</span>
            </button>
          )}
        </Mutation>
      );
    }
  }

  renderActionButtons = (info) => {
    return (
      <div
        className={styles.buttonRow}
        style={info.haveAdded ? { color: 'var(--main)'/*, borderColor: 'var(--main)'*/ } : {}}
      >
        {this.renderAddButton(info)}
        <button
          className={styles.pingButton}
          onClick={() => {
            if (!this.user) {
              this.toggleSignUp(info.id, info.title, 'pinging people about');
            }
            else {
              this.showPing();
            }
          }}
        >
          <FaPaperPlane size={20} className={styles.faIcon} />
          <span>Ping</span>
        </button>
        <button
          className={styles.shareButton}
          onClick={() => {
            if (!this.user) {
              this.toggleSignUp(info.id, info.title, 'sharing');
            }
            else if (!info.private) {
              this.showAddToCal(info);
            }
          }}
          style={{ cursor: !info.private ? 'pointer' : 'unset' }}
        >
          {info.private
            ? <React.Fragment>
                <MdLock
                  size={20}
                  className={styles.mdIcon}
                  color="var(--secondary)"
                />
                <span style={{ color: 'var(--secondary)' }}>Private</span>
              </React.Fragment>
            : <React.Fragment>
                <IoMdShareAlt size={22} className={styles.mdIcon} />
                <span>Share</span>
              </React.Fragment>
          }
        </button>
      </div>
    )
  }

  getMenuItems = (history, match, deleteEvent, event) => {
    let items = [
      {
        label: 'Edit Event',
        onClick: () => history.push({
          pathname: `/EditEvent/${event.id}`,
          state: {
            editEvent: {
              id: event.id,
              title: event.title,
              eventTimestampStart: event.eventTimestampStart,
              eventTimestampEnd: event.eventTimestampEnd,
              description: event.description,
              address: event.address,
              location: event.location,
              image: event.image,
              urls: event.urls,
              private: event.private
            },
            title: event.title,
            startDate: event.eventTimestampStart,
            endDate: event.eventTimestampEnd,
            description: event.description,
            address: event.address,
            location: event.location,
            image: event.image,
            private: event.private,
            showImage: event.image ? true : false
          }
        })
      },
      {
        label: 'Export CSV',
        onClick: () => downloadCsv({
          document,
          arr: event.added,
          title: `${event.title}-csv`
        })
      },
      this.state.showConfirm
        ? {
            label: 'Are you sure?',
            onClick: () => {
              deleteEvent({ variables: { eventId: match.params.id }})
              history.push('/Events')
            },
            style: { color: 'var(--secondary)', fontWeight: 'bold' }
          }
        : {
            label: 'Delete Event',
            onClick: () => this.setState({ showConfirm: true })
          }
    ];

    return items;
  }

  renderDeleteButton = () => {
    if (this.state.showConfirm) {
      return (
        <Mutation
          mutation={DeleteEventMutation}
          update={(cache) => {
            cache.writeQuery({
              query: EventQuery,
              data: { event: null }
            });
          }}
        >
          {(deleteEvent) => (
            <div
              onClick={() => {
                deleteEvent({ variables: { eventId: this.props.match.params.id}});
                this.props.history.push('/Home');
              }}
              className={styles.confirmItem}
            >
              Confirm Delete
            </div>
          )}
        </Mutation>
      );
    }
    else {
      return (
        <div
          onClick={() => {this.setState({ showConfirm: true })}}
          className={styles.deleteItem}
        >
          Delete Event
        </div>
      );
    }
  }

  renderDesc = (desc) => {
    if (desc === '') return '';

    let items;
    const split = desc.split(' ');
    if (split.length > 50 && !this.state.expandDesc) {
      items = split.slice(0,40);
    }
    else {
      items = split;
    }

    return (
      <p className={styles.sectionText}>
        {items.map((item, i) => {
          if (isUrl(item)) {
            return <span key={i}><a href={item}>{item}</a>&nbsp;</span>;
          }
          else {
            return item + ' '
          }
        })}
        {split.length > 50 &&
          <span>
            {!this.state.expandDesc && '...'}
            <br />
            <span onClick={() => {this.setState({ expandDesc: !this.state.expandDesc })}}>
              {this.state.expandDesc
                ? <span className={styles.subLink}>Show Less <MdRemoveCircleOutline /></span>
                : <span className={styles.subLink}>Show More <MdAddCircleOutline /></span>
              }
            </span>
          </span>
        }
      </p>
    );
  }

  renderTime = (event) => {
    let time = '';

    if (event.eventTimestampEnd) {
      if (dateFns.isSameDay(event.eventTimestampStart, event.eventTimestampEnd)) {
        if (event.eventTimestampStart === event.eventTimestampEnd) {
          time += dateFns.format(event.eventTimestampStart, 'dddd, MMMM D [at] h:mm a');
        }
        else {
          time += dateFns.format(event.eventTimestampStart, 'dddd [at] h:mm a - ');
          time += dateFns.format(event.eventTimestampEnd, 'h:mm a');
        }
      }
      else {
        time += dateFns.format(event.eventTimestampStart, 'dddd [at] h:mm a - ');
        time += dateFns.format(event.eventTimestampEnd, 'dddd [at] h:mm a');
      }
    }
    else {
      time += dateFns.format(event.eventTimestampStart, 'dddd, MMMM D [at] h:mm a');
    }

    return time;
  }

  renderAdded = (added, id) => {
    let width = document.documentElement.clientWidth - 70;
    if (width > 515) width = 515;
    let numItems = parseInt(width / 45) - 1;
    const diff = added.length - numItems;
    if (diff === 1) numItems += 1;

    return (
      <div className={styles.addedContain}>
        {added.slice(0, numItems).map((item, i) => (
          <Link to={`/Profile/${item.id}`} key={i}>
            <img src={item.profileImage ? item.profileImage : DEFAULT_PROFILE_IMAGE} className={styles.addedImage} alt="" />
            {item.name && <div className={styles.addedName}>{item.name.split(' ')[0]}</div>}
          </Link>
        ))}
        {diff > 1 &&
          <Link to={`/Added/${id}`}>
            <div className={styles.addedDiff}>+{diff}</div>
            <div className={styles.addedMore}>More</div>
          </Link>
        }
      </div>
    )
  }

  handleDirections = (event) => {
    const lat = parseFloat(event.location.lat);
    const long = parseFloat(event.location.lon);

    if ((navigator.platform.indexOf("iPhone") !== -1) ||
        (navigator.platform.indexOf("iPad") !== -1) ||
        (navigator.platform.indexOf("iPod") !== -1)) {
      window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
    }
    else window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  }

  createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_TOP,
        style: maps.ZoomControlStyle.SMALL
      }
    };
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
      showConfirm: false
    });
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({
      showAddToCal: true,
      eventToAdd: eventInfo,
      shareType: type,
    });
  }

  hideAddToCal = () => {
    this.setState({
      showAddToCal: false
    });
  }

  showPing = () => {
    this.setState({ showPing: true });
    this.pings && this.pings.focus();
  }

  hidePing = () => {
    this.setState({
      showPing: false,
      pings: []
    });
  }

  addGroup = (group) => {
    this.setState({
      pings: [
        ...new Set([
          ...this.state.pings,
          ...group.pings
        ]
      )]
    });
  }

  addPing = (ping) => {
    const newPing = [...new Set([...this.state.pings, ping])];
    this.setState({ pings: newPing });
  }

  delPing = (ping) => {
    const newPing = this.state.pings.filter(item => item.value !== ping.value);
    this.setState({ pings: newPing });
  }

  toggleSignUp = (id, title, type) => {
    this.setState({
      signUpInfo: { id, title, type },
      showSignUp: !this.state.showSignUp
    });
  }

  hideSignUp = () => {
    this.setState({ showSignUp: false });
  }

  setInputRef = (el) => {
    this.pings = el;
    el && el.focus();
  }

  render() {
    const { match, history } = this.props;

    const event = fakeEvent()

    const iconColor = event.haveAdded ? 'var(--main)' : 'var(--text)';

    console.log(event)

    return (
      <div>
        <Header
          match={match}
          history={history}
          navTitle="Event Details"
          backButton
        />
          <div className="body" style={{ background: 'white' }}>
            <div className={styles.contain} style={{ overflowY: this.state.showMenu ? 'hidden' : 'auto' }}>
              {event.image &&
                <a href={event.image} target="_blank" rel="noopener noreferrer">
                  <img src={event.image} alt="" className={styles.imageBanner} />
                </a>
              }
              <div className={styles.topContain}>
                <EventSection
                  icon={
                    <div className={styles.dateContain} style={{color: iconColor}}>
                      <p className={styles.month}>{dateFns.format(event.eventTimestampStart, 'MMM')}</p>
                      <p className={styles.date}>{new Date(event.eventTimestampStart).getDate()}</p>
                    </div>
                  }
                  content={
                    <div>
                      <h2 className={styles.title}>{event.title}</h2>
                      {event.host &&
                        <Link
                          to={`/Profile/${event.host.id}`}
                          className={styles.organizer}
                        >
                          Organized by <img
                            src={event.host.profileImage ? event.host.profileImage : DEFAULT_PROFILE_IMAGE}
                            className={styles.organizerImage}
                            alt=""
                          /> {event.host.name}
                        </Link>
                      }
                       {adminLinkPermission({ isOrg: event.isOrganization, orgId: event.host.id, user: this.user }) &&
                        <p className={styles.adminLink}>
                          <Link to={`/EditOrgEvent/${event.id}`}>Admin Edit Event</Link>
                        </p>
                      }
                    </div>
                  }
                />
              </div>
              {this.renderActionButtons(event)}
              <div className={styles.topContain}>
                {!event.hideTime &&
                  <EventSection
                    icon={<MdAccessTime color={iconColor} size={20} />}
                    content={
                      <p className={styles.sectionText}>
                        {this.renderTime(event)}
                        <br/>
                        <span
                          onClick={() => {
                            if (this.user) {
                              this.showAddToCal(event)
                            }
                            else {
                              this.toggleSignUp('', '', 'adding this event to your calendar')
                            }
                          }}
                          className={styles.subLink}
                        >
                          Export to Calendar <MdEvent />
                        </span>
                      </p>
                    }
                  />
                }
                {event.description &&
                  <EventSection
                    icon={<MdInfoOutline color={iconColor} size={20} />}
                    content={this.renderDesc(event.description)}
                  />
                }
                {event.urls &&
                  event.urls.map((item, i) => (
                    <UrlPreview url={item} iconColor={iconColor} key={i} index={i} />
                  ))
                }
                {event.added &&
                  <EventSection
                    iconStyle={event.added.length !== 0 ? {paddingTop: 16} : {}}
                    icon={<MdPeople color={iconColor} size={20} />}
                    content={event.added.length === 0
                      ? <p className={styles.noAddedText}>Be the first to add this event.</p>
                      : this.renderAdded(event.added, event.id)
                    }
                  />
                }
                <div>
                  {event.address &&
                    <EventSection
                      icon={<MdLocationOn color={iconColor} size={20} />}
                      content={
                        <p className={styles.sectionText}>
                          {event.address}
                          <br />
                          <span
                            onClick={() => {this.handleDirections(event)}}
                            className={styles.subLink}
                          >
                            Get Directions <MdDirections />
                          </span>
                        </p>
                      }
                    />
                  }
                  {event.location &&
                    <div className={styles.mapContain}>
                      <GoogleMapReact
                        defaultCenter={{
                          lat: parseFloat(event.location.lat),
                          lng: parseFloat(event.location.lon)
                        }}
                        defaultZoom={10}
                        options={this.createMapOptions}
                      >
                        <Marker
                          lat={parseFloat(event.location.lat)}
                          lng={parseFloat(event.location.lon)}
                        />
                      </GoogleMapReact>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        <TabBar match={match} />
      </div>
    );

    /*
    return (
      <Query
        query={!this.user ? PublicQuery : EventQuery}
        variables={{
          eventId: match.params.id
        }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading) return (
            <div>
              <Header
                match={match}
                history={history}
                navTitle="Event Details"
                backButton
              />
              <div className="body">
                <Loading />
              </div>
              <TabBar match={match} />
            </div>
          );
          if (error) return (
            <div>
              <Header
                match={match}
                history={history}
                navTitle="Event Details"
                backButton
              />
              <div className="body">
                <ErrorMsg error={error} />
              </div>
              <TabBar match={match} />
            </div>
          );

          const { event } = data;
          const isHost = this.user ? this.user.id === event.host.id : undefined;
          const iconColor = event.haveAdded ? 'var(--main)' : 'var(--text)';

          return (
            <div>
              <Header
                match={match}
                history={history}
                navTitle="Event Details"
                rightIcon={isHost && <MdMoreHoriz size={20} color="white" />}
                rightFunc={isHost && this.toggleMenu}
                backButton
                share={this.showAddToCal}
                shareType="event"
              />
              <div className="body" style={{ background: 'white' }}>
                <div className={styles.contain} style={{ overflowY: this.state.showMenu ? 'hidden' : 'auto' }}>
                  {event.image &&
                    <a href={event.image} target="_blank" rel="noopener noreferrer">
                      <img src={event.image} alt="" className={styles.imageBanner} />
                    </a>
                  }
                  {!this.user &&
                    <div
                      onClick={() => this.setState({ showOverlay: false })}
                      className={event.image
                        ? styles.publicImageOverlay
                        : styles.publicOverlay
                      }
                      style={this.state.showOverlay
                        ? { opacity: 1 }
                        : { opacity: 0, visibility: 'hidden', height: 0 }
                      }
                    >
                      <p>
                        Click the <strong>"+ Add Event"</strong>
                        <br/>
                        below to sign in and add
                        <br/>
                        this event to your calendar
                        <br/>
                        <span>tap to hide</span>
                      </p>
                    </div>
                  }
                  <div className={styles.topContain}>
                    <EventSection
                      icon={
                        <div className={styles.dateContain} style={{color: iconColor}}>
                          <p className={styles.month}>{dateFns.format(event.eventTimestampStart, 'MMM')}</p>
                          <p className={styles.date}>{new Date(event.eventTimestampStart).getDate()}</p>
                        </div>
                      }
                      content={
                        <div>
                          <h2 className={styles.title}>{event.title}</h2>
                          {event.host &&
                            <Link
                              to={`/Profile/${event.host.id}`}
                              className={styles.organizer}
                            >
                              Organized by <img
                                src={event.host.profileImage ? event.host.profileImage : DEFAULT_PROFILE_IMAGE}
                                className={styles.organizerImage}
                                alt=""
                              /> {event.host.name}
                            </Link>
                          }
                           {adminLinkPermission({ isOrg: event.isOrganization, orgId: event.host.id, user: this.user }) &&
                            <p className={styles.adminLink}>
                              <Link to={`/EditOrgEvent/${event.id}`}>Admin Edit Event</Link>
                            </p>
                          }
                        </div>
                      }
                    />
                  </div>
                  {this.renderActionButtons(event)}
                  <div className={styles.topContain}>
                    {!event.hideTime &&
                      <EventSection
                        icon={<MdAccessTime color={iconColor} size={20} />}
                        content={
                          <p className={styles.sectionText}>
                            {this.renderTime(event)}
                            <br/>
                            <span
                              onClick={() => {
                                if (this.user) {
                                  this.showAddToCal(event)
                                }
                                else {
                                  this.toggleSignUp('', '', 'adding this event to your calendar')
                                }
                              }}
                              className={styles.subLink}
                            >
                              Export to Calendar <MdEvent />
                            </span>
                          </p>
                        }
                      />
                    }
                    {event.description &&
                      <EventSection
                        icon={<MdInfoOutline color={iconColor} size={20} />}
                        content={this.renderDesc(event.description)}
                      />
                    }
                    {event.urls &&
                      event.urls.map((item, i) => (
                        <UrlPreview url={item} iconColor={iconColor} key={i} index={i} />
                      ))
                    }
                    {event.added &&
                      <EventSection
                        iconStyle={event.added.length !== 0 ? {paddingTop: 16} : {}}
                        icon={<MdPeople color={iconColor} size={20} />}
                        content={event.added.length === 0
                          ? <p className={styles.noAddedText}>Be the first to add this event.</p>
                          : this.renderAdded(event.added, event.id)
                        }
                      />
                    }
                    <div>
                      {event.address &&
                        <EventSection
                          icon={<MdLocationOn color={iconColor} size={20} />}
                          content={
                            <p className={styles.sectionText}>
                              {event.address}
                              <br />
                              <span
                                onClick={() => {this.handleDirections(event)}}
                                className={styles.subLink}
                              >
                                Get Directions <MdDirections />
                              </span>
                            </p>
                          }
                        />
                      }
                      {event.location &&
                        <div className={styles.mapContain}>
                          <GoogleMapReact
                            defaultCenter={{
                              lat: parseFloat(event.location.lat),
                              lng: parseFloat(event.location.lon)
                            }}
                            defaultZoom={10}
                            options={this.createMapOptions}
                          >
                            <Marker
                              lat={parseFloat(event.location.lat)}
                              lng={parseFloat(event.location.lon)}
                            />
                          </GoogleMapReact>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <TabBar match={match} />
              {!this.user &&
                <Modal
                  shown={this.state.showSignUp}
                  hide={this.hideSignUp}
                  modalStyle={{ height: 'auto', width: '95%', maxWidth: '400px', borderRadius: 6 }}
                >
                  <SignUpModal
                    hide={this.hideSignUp}
                    history={history}
                    id={this.state.signUpInfo.id}
                    title={this.state.signUpInfo.title}
                    type={this.state.signUpInfo.type}
                  />
                </Modal>
              }
              {this.user &&
                <AddToCal
                  shown={this.state.showAddToCal}
                  event={event}
                  hide={this.hideAddToCal}
                  type={this.state.shareType}
                />
              }
              {(this.user && this.state.showPing) &&
                <PingModal
                  shown={this.state.showPing}
                  hide={this.hidePing}
                  add={this.addPing}
                  del={this.delPing}
                  id={event.id}
                  pings={this.state.pings}
                  addGroup={this.addGroup}
                  match={match}
                  history={history}
                />
              }
              {isHost &&
                <Mutation
                  mutation={DeleteEventMutation}
                  update={(cache) => {
                    cache.writeQuery({
                      query: EventQuery,
                      data: { event: null }
                    });
                  }}
                >
                  {(deleteEvent) => (
                    <MenuModal
                      visible={this.state.showMenu}
                      toggle={this.toggleMenu}
                      items={this.getMenuItems(history, match, deleteEvent, event)}
                      tall
                    />
                  )}
                </Mutation>
              }
            </div>
          );
        }}
      </Query>
    )
    */
  }
}

const AddEventMutation = gql`
  mutation addEvent($eventId: ID!) {
    addEvent(eventId: $eventId)
  }
`;

const RemoveEventMutation = gql`
  mutation addEvent($eventId: ID!) {
    removeEvent(eventId: $eventId)
  }
`;

const DeleteEventMutation = gql`
  mutation deleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId)
  }
`;

const EventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      host {
        id
        name
        profileImage
      }
      title
      added {
        id
        name
        profileImage
      }
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      haveAdded
      isOrganization
      urls {
        url
        title
        image
        snippet
        icon
      }
      hideTime
      private
    }
  }
`;

const PublicQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      host {
        id
        name
        profileImage
      }
      title
      added {
        id
        name
        profileImage
      }
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      urls {
        url
        title
        image
        snippet
        icon
      }
      hideTime
      private
    }
  }
`;

export default EventPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './Unsubscribe.module.css';

const Unsubscribe = ({
  match,
  history
}) => {
  const email = match.params.email || '';
  return (
    <div className={styles.contain}>
      <h1>Confirm Unsubscribe</h1>
      <p>
        If you do,&nbsp;
        <strong>{email}</strong>
        &nbsp;will no longer recieve emails from Appearix
      </p>
      <Mutation mutation={UnsubscribeEmail}>
        {(unsubscribeEmail) => (
          <button 
            className={styles.button}
            onClick={async () => {
              const success = await unsubscribeEmail({ variables: { email } });
              console.log(success)
              if (success) history.push('/');
            }}
          >
            Unsubscribe
          </button>
        )}
      </Mutation>
      <br />
      <Link
        to="/"
        className={styles.cancelButton}
      >
        Cancel
      </Link>
    </div>
  );
}

const UnsubscribeEmail = gql`
  mutation unsubscribeEmail($email: String!) {
    unsubscribeEmail(email: $email)
  }
`;

export default Unsubscribe;

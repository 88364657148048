import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './HavePinged.module.css';
import PingItem from './PingItem';
import Loading from '../Common/Loading';

const HavePinged = ({ id, history }) => (
  <div className={styles.havePinged}>
    <Query
      query={PingedQuery}
      variables={{
        eventId: id
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading small message="Loading Previous Pings..." />;
        if (error) return <span className={styles.message}>Having trouble getting pings.</span>;

        const { havePinged } = data;
        if (!havePinged.total) return <span className={styles.message}>Pings you've sent will show up here.</span>;

        return (
          <React.Fragment>
            <p className={styles.pingHeader}>You've Pinged {havePinged.total}: </p>
            {havePinged.results.map((ping, i) => (
              <PingItem
                key={i}
                ping={ping}
                onClick={() => history.push(`/Profile/${ping.id}`)}
              />
            ))}
          </React.Fragment>
        );
      }}
    </Query>
  </div>
);

const PingedQuery = gql`
  query havePinged($eventId: ID!) {
    havePinged(eventId: $eventId) {
      total
      results {
        id
        name
        profileImage
      }
    }
  }
`;

export default HavePinged;
import React from 'react';
import { MdSettings } from 'react-icons/md';

import { getUser } from '../../utils/userState';
import getIdFromUrl from '../../utils/getIdFromUrl';
import withContext from '../Context/withContext';
import styles from './Profile.module.css';
import TabBar from '../Common/TabBar';
import Header from '../Common/Header';
import ProfileContent from './ProfileContent';
import AddToCal from '../Common/AddToCal';
import Modal from '../Common/Modal';
import SignUpModal from '../Common/SignUpModal';

class Profile extends React.Component {
  state = {
    showMenu: false,
    showAddToCal: false,
    showSignUp: false,
    shareInfo: {},
    shareType: 'event',
    signUpInfo: {}
  }

  showSignUp = (id, title, type) => {
    this.setState({
      signUpInfo: { id, title, type },
      showSignUp: !this.state.showSignUp
    });
  }

  hideSignUp = () => {
    this.setState({
      showSignUp: false
    });
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({
      showAddToCal: true,
      shareInfo: eventInfo,
      shareType: type
    });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  render() {
    const { match, history } = this.props;
    const user = getUser();
    const pageUserId = getIdFromUrl(window.location.href);
    const isOwner = user ? pageUserId === user.id : false;

    return (
      <div className={styles.body}>
        <Header
          match={match}
          history={history}
          navTitle="Profile"
          rightIcon={isOwner && <MdSettings size={20} color="white" />}
          rightFunc={isOwner ? () => history.push('/Settings') : undefined}
          backButton
          share={this.showAddToCal}
          pageUserId={pageUserId}
        />
        <div className="body">
          <ProfileContent
            profile={user}
            pageUserId={pageUserId}
            match={match}
            history={history}
            showAddToCal={this.showAddToCal}
            hideAddToCal={this.hideAddToCal}
            showSignUp={this.showSignUp}
          />
        </div>
        <TabBar 
          match={match} 
          pageUserId={pageUserId}
        />
        {!user &&
          <Modal
            shown={this.state.showSignUp}
            hide={this.hideSignUp}
            containStyle={{ top: 0, bottom: 0 }}
            modalStyle={{ height: 'auto', width: '95%', maxWidth: '400px', borderRadius: 6 }}
          >
            <SignUpModal
              hide={this.hideSignUp}
              history={history}
              id={this.state.signUpInfo.id}
              title={this.state.signUpInfo.title}
              type={this.state.signUpInfo.type}
            />
          </Modal>
        }
        {user &&
          <AddToCal
            shown={this.state.showAddToCal}
            event={this.state.shareInfo}
            hide={this.hideAddToCal}
            type={this.state.shareType}
          />
        }
      </div>
    )
  }
};

export default withContext(Profile);

import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

export const AppContext = React.createContext();

export class AppProvider extends React.Component {
  state = {
    firebase: null,
    discoverTag: undefined,

    setFirebase: (firebase) => {
      this.setState({
        firebase
      });
    },
    getFirebase: () => (
      this.state.firebase
    ),

    setDiscoverTag: (tag) => {
      this.setState({
        discoverTag: tag
      });
    },
    getDiscoverTag: () => (
      this.state.discoverTag
    )
  }

  componentWillMount() {
    const config = {
      apiKey: 'AIzaSyDkgaeOjUi1PuG56FibVbyl2mXI6rfD0WY',
      authDomain: 'event-app-1522057446403.firebaseapp.com',
      databaseURL: 'https://event-app-1522057446403.firebaseio.com',
      storageBucket: 'gs://event-app-1522057446403.appspot.com',
      projectId: 'event-app-1522057446403',
      messagingSenderId: '890554982627'
    };

    firebase.initializeApp(config);

    this.state.setFirebase(firebase);
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import dateFns from 'date-fns';

import styles from './Admin.module.css';
import ImageInput from '../Common/ImageInput';
import removeEmptyFields from '../../utils/removeEmptyFields';
import removeTypename from '../../utils/removeTypename';
import PingInput from '../Pings/PingInput';
import PrivacyToggle from '../CreateEvent/PrivacyToggle';

const defaultState = {
  title: '',
  image: null,
  address: '',
  location: null,
  urls: '',
  tags: '',
  description: '',
  imgUrl: '',
  eventTimestampStart: '',
  eventTimestampEnd: '',
  hideTime: false,
  msg: null,
  pings: [],
  private: false
};

class OrgEventForm extends React.Component {
  state = {
    ...defaultState,
    ...this.props.defaultValues ? removeTypename(removeEmptyFields(this.props.defaultValues)) : {},
    urls: this.props.defaultValues && this.props.defaultValues.urls ? this.props.defaultValues.urls.map(url => url.url).join(',') : '',
    tags: this.props.defaultValues && this.props.defaultValues.tags ? this.props.defaultValues.tags.join(',') : '',
  };

  editEvent = async (editEvent) => {
    try {
      this.setState({ msg: null });
      const res = await editEvent({
        variables: {
          eventId: this.props.id,
          event: this.buildEvent()
        }
      });
      if (res.data.editEvent) {
        alert('Successfully edited event');
      }
      else {
        this.setState({ msg: 'Failed to edit event, please try again' });
      }
    }
    catch (error) {
      this.setState({ msg: `Error: ${error } during edit event` });
      console.error(error);
    }
  }

  uploadEvent = async (createOrgEvent) => {
    try {
      const res = await createOrgEvent({ variables: { hostId: this.props.id, event: this.buildEvent() } });
      if (res.data.createOrgEvent) {
        window.location.reload();
        alert('Successfully created event');
      }
      else {
        this.setState({ msg: 'Failed to create event, please try again' });
      }
    }
    catch (error) {
      this.setState({ msg: `Error: ${error} during event creation` });
      console.error(error);
    }
  }

  validate = () => {
    return !(this.state.eventTimestampStart.length > 0 && this.state.title.length > 0)
  }

  splitByComma = (str) => {
    if (str.length > 0) {
      return str.split(',').map(str => str.trim())
    }
    return [];
  }

  buildEvent = () => {
    const img = this.state.image ? this.state.image : this.state.imgUrl.length > 0 ? this.state.imgUrl : null;

    const event = {
      title: this.state.title,
      image: img,
      address: this.state.address.length > 0 ? this.state.address : null,
      location: this.state.location,
      urls: this.splitByComma(this.state.urls).map(url => { return { url } }),
      tags: this.splitByComma(this.state.tags),
      description: this.state.description.length ? this.state.description : null,
      eventTimestampStart: this.state.eventTimestampStart.length > 0 ? dateFns.parse(this.state.eventTimestampStart) : null,
      eventTimestampEnd: this.state.eventTimestampEnd.length > 0 ? dateFns.parse(this.state.eventTimestampEnd) : null,
      private: this.state.private
    };

    if (!this.props.defaultValues) {
      event.pings = this.state.pings.map(({ user, __typename, ...rest }) => rest);
    }

    return event;
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ location: { lat: `${latLng.lat}`, lon: `${latLng.lng}` } }))
      .catch(error => console.error('Error', error));
  };

  formatTimestamp = (value, addHours = 1, endTimestamp = false) => {
    if (value.length > 0) {
      if (dateFns.isValid(new Date(value))) {
        return dateFns.format(value, 'YYYY-MM-DDTHH:mm')
      }
      else {
        return '';
      }
    }
    else {
      if (this.props.defaultValues) {
        return '';
      }
      else {
        if (endTimestamp && this.state.eventTimestampStart !== '') {
          return dateFns.format(dateFns.addHours(this.state.eventTimestampStart, addHours), 'YYYY-MM-DDTHH:mm');
        }
        return dateFns.format(dateFns.addHours(new Date(), addHours), 'YYYY-MM-DDTHH:mm');
      }
    }
  }

  addGroupPing = (group) => {
    this.setState({
      pings: [
        ...new Set([
          ...this.state.pings,
          ...group.pings
        ]
      )]
    });
  }

  addPing = (ping) => {
    const newPing = [...new Set([...this.state.pings, ping])];
    this.setState({ pings: newPing });
  }

  delPing = (ping) => {
    const newPing = this.state.pings.filter(item => item.value !== ping.value);
    this.setState({ pings: newPing });
  }

  privacyToggle = (val) => {
    this.setState({ 
      private: val 
    });
  }

  render() {
    return (
      <div className={styles.formContainer}>
        <h1>{this.props.defaultValues ? 'Edit' : 'Create'} Event for {this.props.id}</h1>
        Title
        <input className={styles.input} value={this.state.title} onChange={e => this.setState({ title: e.target.value })} type="text" placeholder="Title"></input>
        <br />
        <br />
        Start Timestamp:
        <br />
        <input
          className={styles.input}
          value={this.formatTimestamp(this.state.eventTimestampStart, 1)}
          onChange={e => this.setState({ eventTimestampStart: e.target.value })}
          type="datetime-local"
        />
        <br />
        End Timestamp:
        <br />
        <input
          className={styles.input}
          value={this.formatTimestamp(this.state.eventTimestampEnd, 2, true)}
          onChange={e => this.setState({ eventTimestampEnd: e.target.value })}
          type="datetime-local"
        />
        <br />
        <br />
        Description
        <textarea className={styles.input} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} rows="5" placeholder="Description"></textarea>
        <br />
        <br />
        Location
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: `location-search-input ${styles.input}`,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <br />
        Image
        <ImageInput
          onChange={(img) => {this.setState({ image: img })}}
          onClose={() => {this.setState({ image: null })}}
          image={this.state.image}
          expanded={this.state.image}
        />
        <input className={styles.input} type="text" value={this.state.imgUrl} onChange={e => this.setState({ imgUrl: e.target.value })} placeholder="Or image url"></input>
        <br />
        <br />
        Private
        <PrivacyToggle 
          toggle={this.privacyToggle}
          value={this.state.private}
        />
        <br />
        URLs
        <textarea className={styles.input} value={this.state.urls} onChange={e => this.setState({ urls: e.target.value })} rows="5" placeholder="Urls separated by comma (https://google.com/, https://example.com/)"></textarea>
        <br />
        Tags
        <textarea className={styles.input} value={this.state.tags} onChange={e => this.setState({ tags: e.target.value })} rows="5" placeholder="Tags separated by comma (boulder, skiing)"></textarea>
        <br />
        {!this.props.defaultValues &&
          <div>
            Pings
            <PingInput 
              addGroup={this.addGroupPing}
              add={this.addPing}
              del={this.delPing}
              pings={this.state.pings}
            />
            <br />
          </div>
        }
        {this.props.defaultValues
          ?
            <Mutation
              mutation={EditEventMutation}
            >
              {(editEvent) => (
                <button className={styles.submitButton} onClick={() => this.editEvent(editEvent)} disabled={this.validate()}>Edit Event</button>
              )}
            </Mutation>
          :
            <Mutation
              mutation={CreateOrgEventMutation}
            >
              {(createOrgEvent) => (
                <button className={styles.submitButton} onClick={() => this.uploadEvent(createOrgEvent)} disabled={this.validate()}>Create Event</button>
              )}
            </Mutation>
        }
        {this.state.msg && <p>{this.state.msg}</p>}
      </div>
    );
  }
}

const CreateOrgEventMutation = gql`
  mutation createOrgEvent($hostId: ID!, $event: EventInput!) {
    createOrgEvent(hostId: $hostId, event: $event)
  }
`;

const EditEventMutation = gql`
  mutation editEvent($eventId: ID!, $event: EditEventInput!) {
    editEvent(eventId: $eventId, event: $event) {
      id
    }
  }
`;

export default OrgEventForm;

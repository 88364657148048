import React from 'react';
import { MdCollections, MdCollectionsBookmark } from 'react-icons/md';

import { MAX_FILE_SIZE_BYTES, DEFAULT_IMAGES } from '../../utils/constants';
import styles from './ImageUpload.module.css';
import ImageGallery from './ImageGallery';

class ImageUpload extends React.Component {
  state = {
    previewImage: this.props.image || undefined,
    expanded: this.props.expanded || this.props.image,
    error: null,
    showGallery: false
  }

  componentDidUpdate() {
    const { image } = this.props;
    if (image && image !== this.state.previewImage) {
      this.setState({
        previewImage: this.props.image,
        expanded: true
      })
    }
  }

  toggleUpload = (event) => {
    this.state.error && this.setState({ error: null });

    const { onChange } = this.props;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('abort', (e) => {
      this.toggleImage(e)
    });
    reader.addEventListener('load', () => {
      if (file.size >= MAX_FILE_SIZE_BYTES) {
        this.setState({ error: 'Image size too large, please upload a smaller image.' });
      }
      else {
        this.setState({
          previewImage: reader.result
        });
        onChange && onChange(reader.result);
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  toggleImage = (e) => {
    if (this.state.expanded) {
      e.preventDefault();
      this.props.onClose();
    }

    this.setState({
      error: null,
      expanded: !this.state.expanded
    });
  }

  gallerySelect = (key) => {
    this.props.onChange && this.props.onChange(DEFAULT_IMAGES[key]);
    this.setState({
      showGallery: false
    });
  }

  toggleGallery = () => {
    this.setState({
      showGallery: !this.state.showGallery
    });
  }

  render() {
    const {
      placeholder  = 'Add image',
      expanded,
      image
    } = this.props;

    return (
      <div className={styles.contain}>
        <p onClick={this.toggleImage}>
          {image
            ? 'Remove image'
            : this.state.expanded
              ? 'Hide source options'
              : placeholder
          }
        </p>
        <input
          type="file"
          name="file"
          id="file"
          accept="image/jpg, image/jpeg, image/png"
          onChange={this.toggleUpload}
          style={{ display: 'none' }}
        />
        {(this.state.expanded || expanded) &&
          <div className={styles.optionsContain}>
            <label htmlFor="file">
              <div className={styles.option} >
                <MdCollectionsBookmark size={18} />
                <h5 className={styles.optionLabel}>From Device</h5>
              </div>
            </label>
            <div
              onClick={this.toggleGallery}
              className={styles.option}
            >
              <MdCollections size={18} />
              <h5 className={styles.optionLabel}>From Gallery</h5>
            </div>
          </div>
        }
        {/*(this.state.expanded || this.props.expanded) &&
          <div className={styles.imageRow}>
            {this.state.previewImage
              ? <img
                  className={styles.imageFrame}
                  src={this.state.previewImage}
                  alt=""
                  style={selectedStyle}
                />
              : <div
                  className={styles.imageFrame}
                  style={selectedStyle}
                >
                  <MdPhoto size={25} />
                  <span className={styles.uploadLabel}>No Image</span>
                </div>
            }
            <label htmlFor="file">
              <div className={styles.imageFrame}>
                <MdCloudUpload size={25} />
                <span className={styles.uploadLabel}>Upload</span>
              </div>
            </label>
            <div className={styles.imageFrame} onClick={this.toggleGallery}>
              <MdCollections size={25} />
              <span className={styles.uploadLabel}>Gallery</span>
            </div>
          </div>
          */}
        {this.state.error && <p>{this.state.error}</p>}
        <ImageGallery
          shown={this.state.showGallery}
          hide={this.toggleGallery}
          select={this.gallerySelect}
        />
      </div>
    );
  }
}

export default ImageUpload;

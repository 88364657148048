import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { MdCheckCircle } from 'react-icons/md';

import styles from './PingModal.module.css';
import Modal from '../Common/Modal';
import Button from '../Common/Button';
import PingInput from '../Pings/PingInput';
import HavePinged from '../Pings/HavePinged';

class PingModal extends React.Component {
  state = {
    showSuccess: false
  }

  toggleSuccess = () => {
    this.setState({
      showSuccess: !this.state.showSuccess
    });
  }

  render() {
    const {
      shown,
      hide,
      add,
      addGroup,
      del,
      id,
      pings,
      history,
      match
    } = this.props;

    const canSend = pings.length;

    return (
      <Modal
        shown={shown}
        hide={hide}
        align="stretch"
      >
        {this.state.showSuccess
          ? <div className={styles.successContain}>
              <h1 className={styles.success}>
                <MdCheckCircle size={40} style={{ marginRight: 8 }} />
                Success!
              </h1>
              <h3 className={styles.message}>You've Pinged {pings.length}</h3>
              <Button
                className={styles.successButton}
                onClick={() => {
                  hide();
                  this.setState({ showSuccess: false });
                }}
                label="Close"
                light={true}
              />
            </div>
          : <div>
              <div className={styles.pingContain}>
                <p className={styles.sectionHeader}>Ping your friends</p>
                <PingInput
                  add={add}
                  del={del}
                  pings={pings}
                  addGroup={addGroup}
                  match={match}
                  history={history}
                  style={{ borderTop: '1px solid #eee' }}
                />
                <HavePinged
                  id={id}
                  history={history}
                />
              </div>
              <div className={styles.pingButtonContain}>
                <Mutation
                  mutation={PingMutation}
                  update={(cache) => {
                    const { havePinged } = cache.readQuery({
                      query: PingedQuery,
                      variables: {
                        eventId: id
                      }
                    });
                    cache.writeQuery({
                      query: PingedQuery,
                      data: {
                        havePinged: Object.assign({}, havePinged, {
                          results: [...havePinged.results, ...pings],
                          total: havePinged.total + 1
                        })
                      }
                    });
                  }}
                >
                  {(createPings) => (
                    <Button
                      className={canSend ? styles.pingButton : styles.disabledButton}
                      onClick={() => {
                        if (canSend) {
                          createPings({
                            variables: {
                              eventId: id,
                              pings: pings.map(({ user, __typename, ...rest}) => rest)
                            }
                          });
                          this.toggleSuccess();
                        }
                      }}
                      label="Send Pings"
                    />
                  )}
                </Mutation>
                <Button
                  onClick={hide}
                  label="Cancel"
                  light={true}
                />
              </div>
            </div>
        }
      </Modal>
    );
  }
};

/*
{this.user &&
  <EventSection
    icon={<MdPersonAdd color={iconColor} size={20} />}
    content={
      <p className={styles.pingText} onClick={this.showPing}>
        Ping Your Friends
        <br />
        <Query
          query={PingedQuery}
          variables={{
            eventId: match.params.id
          }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            if (loading) return <span>Loading pings...</span>;
            if (error) return <span>Having trouble getting pings.</span>;

            const { havePinged } = data;
            if (!havePinged.total) return <span>Spread the word!</span>;

            return (
              <span>You've pinged {havePinged.total} {havePinged.results.map((item, i) => (
                <Link to={`/Profile/${item.id}`} key={i} style={{ height: 16 }}>
                  <img
                    src={item.profileImage ? item.profileImage : DEFAULT_PROFILE_IMAGE}
                    className={styles.pingImg}
                    alt="User profile"
                  />
                </Link>
              ))}</span>
            );
          }}
        </Query>
      </p>
    }
  />
}
*/

const PingMutation = gql`
  mutation createPings($eventId: ID!, $pings: [PingInput]!) {
    createPings(eventId: $eventId, pings: $pings)
  }
`;

const PingedQuery = gql`
  query havePinged($eventId: ID!) {
    havePinged(eventId: $eventId) {
      total
      results {
        id
        name
        profileImage
      }
    }
  }
`;

export default PingModal;

import React from 'react';
import { MdClear, MdGroup, MdGroupAdd } from 'react-icons/md';

import styles from './PingInput.module.css';
import { getUser } from '../../utils/userState';
import GroupSelect from '../Groups/GroupSelect';
import CreateGroup from '../Groups/CreateGroup';
import PingItem from './PingItem';
import GroupInput from './GroupInput';

class PingInput extends React.Component {
  state = {
    value: '',
    hasFocused: false,
    showGroups: false,
    showCreate: false
  }

  user = getUser()

  toggleGroups = () => {
    this.setState({
      showGroups: !this.state.showGroups
    });
  }

  toggleCreate = () => {
    this.setState({
      showCreate: !this.state.showCreate
    });
  }

  onChange = (e) => {
    const value = e.target.value;
    this.setState({ value });
  }

  onFocus = () => {
    this.setState({
      hasFocused: true
    });
  }

  handleClear = () => {
    this.setState({ value: '' });
  }

  addGroup = (group) => {
    this.setState({ showGroups: false });
    this.props.addGroup && this.props.addGroup(group);
  }

  addPing = (ping) => {
    this.setState({ value: '' });
    this.props.add && this.props.add(ping);
  }

  delPing = (ping) => {
    this.props.del && this.props.del(ping);
  }

  isInPings = (item) => {
    const { pings } = this.props;
    return pings.some((ping) => (
      ping.value === item.id
    ));
  }

  render() {
    const {
      pings = [],
      history,
      match,
      style = {}
    } = this.props;

    const {
      value,
      showCreate,
      showGroups,
      hasFocused
    } = this.state;

    const anyPings = pings.length > 0;
    const morePings = pings.length > 1;
    
    if (showGroups) {
      return (
        <GroupSelect 
          toggle={this.toggleGroups}
          addGroup={this.addGroup}
        />
      )
    }
    else if (showCreate) {
      return (
        <CreateGroup 
          history={history}
          match={match}
          group={{ 
            title: '',
            pings: pings
          }}
          toggle={this.toggleCreate}
        />
      )
    }

    return (
      <GroupInput
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        clear={this.handleClear}
        addPing={this.addPing}
        pings={pings}
        placeholder="Ping by name or email"
        imageStyle={{ marginLeft: 54 }}
        style={style}
      >
        <div className={styles.contain}>
          {(pings.length > 0) &&
            <div style={anyPings ? {paddingBottom: 10} : {}}>
              <p className={styles.pingHeader}>Pinging: </p>
              {pings.map((ping, i) => (
                <PingItem 
                  key={i}
                  ping={ping}
                  onClick={() => this.delPing(ping)}
                  actionIcon={<MdClear size={16} />}
                />
              ))}
              {morePings &&
                <PingItem 
                  ping={{ value: 'Create New Group' }}
                  onClick={this.toggleCreate}
                  icon={<MdGroupAdd />}
                  imageStyle={{ background: 'var(--main)' }}
                />
              }
            </div>
          }
          {(hasFocused || anyPings) &&
            <PingItem 
              ping={{ value: 'Add Existing Group' }}
              onClick={this.toggleGroups}
              style={{ marginBottom: 12 }}
              icon={<MdGroup />}
              imageStyle={{ background: 'var(--text)' }}
            />
          }
        </div>
      </GroupInput>
    )
  }
}

export default PingInput;

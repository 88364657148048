import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { MdClear, MdPeople, MdEmail, MdGroupAdd } from 'react-icons/md';

import styles from './GroupInput.module.css';
import ErrorMsg from '../Common/ErrorMsg';
import PingItem from './PingItem';
import { getUser } from '../../utils/userState';

class GroupInput extends React.Component {
  state = {
    isEmail: false
  }

  user = getUser()
  
  onChange = (e) => {
    const val = e.target.value;
    const regex = /.+@.+\..+/;
    this.setState({ isEmail: val.match(regex) });

    const { onChange } = this.props;
    onChange && onChange(e);
  }

  clear = () => {
    const { clear } = this.props;
    clear && clear();

    this.ping.focus();
  }

  isInPings = (item) => {
    const { pings } = this.props;
    return pings.some((ping) => (
      ping.value === item.id
    ));
  }

  render() {
    const {
      value,
      addPing,
      children,
      placeholder = "Add people by name or email",
      clear,
      pings,
      onChange,
      imageStyle = { marginLeft: 12 },
      style = {},
      ...props
    } = this.props;

    return (
      <div className={styles.contain} style={style}>
        <div className={styles.innerContain}>
          <div className={styles.inputRow}>
            <div
              className={styles.inputIcon}
              onClick={() => this.ping.focus()}
            >
              <MdPeople size={20} color="var(--main)" />
            </div>
            <div className={styles.inputContain}>
              <input
                ref={(el) => this.ping = el}
                placeholder={placeholder}
                className={styles.textInput}
                value={value}
                onChange={this.onChange}
                autoCapitalize="none"
                autoComplete="off"
                {...props}
              />
              <div
                className={styles.clearContain}
                style={{ opacity: value !== '' && value ? 1 : 0 }}
                onClick={this.clear}
              >
                <MdClear size={16} />
              </div>
            </div>
          </div>
          {(this.state.isEmail && value !== '') &&
            <PingItem
              onClick={() => {
                addPing({
                  type: 'email',
                  value: value
                });
                this.ping.focus();
              }}
              icon={<MdEmail />}
              ping={{ value: 'Add Email' }}
              style={{ marginBottom: 12 }}
              imageStyle={imageStyle}
            />
          }
          <Query
            query={FollowingQuery}
            variables={{
              userId: this.user.id,
              pagination: {
                offset: 0,
                // limit: 100
              }
            }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return '';
              if (error) return (
                <ErrorMsg
                  error={error}
                  align="left"
                  small
                />
              );
              if (value === '') return '';

              const { following } = data;
              let { results } = following;
              if (!results.includes(this.user)) {
                results.unshift(this.user);
              }

              if (value !== '') {
                results = results.filter(item => (
                  item
                  &&
                  (item.name.toLowerCase().startsWith(value.toLowerCase())
                  &&
                  !this.isInPings(item)
                  &&
                  !item.isOrganization)
                ));
              }

              return (
                <div
                  className={styles.pingAuto}
                  style={results.length ? { paddingBottom: 10 } : {}}
                >
                  {results.map((person, i) => (
                    <PingItem 
                      key={i}
                      ping={person}
                      actionIcon={<MdGroupAdd />}
                      onClick={() => {
                        addPing({
                          type: 'id',
                          value: person.id,
                          user: {
                            id: person.id,
                            name: person.name,
                            profileImage: person.profileImage
                          }
                        });
                        this.ping && this.ping.focus();
                      }}
                      imageStyle={imageStyle}
                    />
                  ))}
                </div>
              );
            }}
          </Query>
          {children}
        </div>
      </div>
    )
  }
};

const FollowingQuery = gql`
  query following($userId: ID!, $pagination: Pagination!) {
    following(userId: $userId, pagination: $pagination) {
      total
      results {
        id
        name
        profileImage
        isOrganization
      }
    }
  }
`;

export default GroupInput;
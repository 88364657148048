import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './DeleteGroupButton.module.css';

class DeleteGroupButton extends React.Component {
  state = {
    deleted: false,
    showConfirm: false
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  };

  handleClick = (e) => {
    if (this.button && !this.button.contains(e.target)) {
      this.setState({ showConfirm: false });
    }
  }

  render() {
    const { 
      group: { id }, 
      toggleDelete = () => {}
    } = this.props;
    const { deleted, showConfirm } = this.state;

    if (deleted) {
      return (
        <div className={styles.confirm}>
          Deleted
        </div>
      )
    }
    else if (showConfirm) {
      return (
        <Mutation mutation={DeleteGroupMutation}>
          {(deleteGroup) => (
            <button 
              ref={(el) => this.button = el}
              className={styles.confirm}
              onClick={() => {
                deleteGroup({ 
                  variables: { 
                    groupId: id 
                  },
                  update: (cache, { data: { deleteGroup } }) => {
                    const data = cache.readQuery({ query: GroupsQuery });
                    let { getGroups } = data;
                    
                    cache.writeQuery({ 
                      query: GroupsQuery, 
                      data: { 
                        getGroups: {
                          total: getGroups.total - 1,
                          results: getGroups.results.filter((item) => item.id !== id),
                          __typename: 'GroupList'
                        }
                      }
                    });
                  }
                });
                toggleDelete();
                this.setState({ deleted: true });
              }}
            >
              Confirm Delete
            </button>
          )}
        </Mutation>
      )  
    }
    else {
      return (
        <button
          className={styles.button}
          onClick={() => {
            this.setState({ showConfirm: true })
          }}
        >
          Delete Group
        </button>
      )
    }
  }
}

const DeleteGroupMutation = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) 
  }
`;

const GroupsQuery = gql`
  query getGroups {
    getGroups {
      total
      results {
        id
        title
        pings {
          type
          value
        }
      }
    }
  }
`;

export default DeleteGroupButton;
/*
  const arrr = [
    ['heading1', 'heading2'],
    ['content1', 'content2']
  ]
*/

export default function downloadCsv ({
  document,
  arr,
  title = 'appearix-csv'
}) {
  let content = arr.map(item => item.name);
  content.unshift('People who\'ve added this event on Appearix:');
  const elem = document.createElement('a');
  const blob = new Blob([content], {
    type: 'text/csv;charset=utf-8;'
  });
  const url = URL.createObjectURL(blob);
  elem.href = url;
  elem.setAttribute('download', title);
  elem.click();
}

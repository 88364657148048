import React from 'react';
import { MdCheck, MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import dateFns from 'date-fns';

import { getUser } from '../../utils/userState';
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants';
import styles from './PersonItem.module.css';

const FollowMutation = gql`
  mutation follow($userId: ID!) {
    follow(userId: $userId)
  }
`;

const UnfollowMutation = gql`
  mutation unfollow($unfollowUserId: ID!) {
    unfollow(unfollowUserId: $unfollowUserId)
  }
`;

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      id
      name
      profileImage
      haveFollowed
      upcomingEventsCount
      followersCount
      topUpcomingEvents {
        id
        title
        eventTimestampStart
      }
    }
  }
`;

class PersonItem extends React.Component {
  iconButton(profile, user) {
    if (!user) {
      return (
        <div
          onClick={() => this.props.toggleSignUp && this.props.toggleSignUp(profile.id, profile.name, 'following')}
          className={styles.iconContain}
        >
          <MdAdd size={25} color="var(--text)" />
        </div>
      );
    }
    else if (profile.id === user.id || profile.haveFollowed) {
      return (
        <Mutation
          mutation={UnfollowMutation}
          update={(cache) => {
            cache.writeQuery({
              query: ProfileQuery,
              data: { profile: Object.assign({}, profile, { haveFollowed: false }) }
            });
          }}
        >
          {(unfollow) => (
            <div
              onClick={async () => {
                try {
                  await unfollow({ variables: { unfollowUserId: profile.id } });
                }
                catch (error) {
                  console.error(error);
                }
              }}
              className={styles.iconContain}
            >
              <MdCheck size={25} color="var(--main)" />
            </div>
          )}
        </Mutation>
      );
    }
    else {
      return (
        <Mutation
          mutation={FollowMutation}
          update={(cache) => {
            cache.writeQuery({
              query: ProfileQuery,
              data: { profile: Object.assign({}, profile, { haveFollowed: true }) }
            });
          }}
        >
          {(follow) => (
            <div
              onClick={async () => {
                try {
                  await follow({ variables: { userId: profile.id } });
                }
                catch (error) {
                  console.error(error);
                }
              }}
              className={styles.iconContain}
            >
              <MdAdd size={25} color="var(--text)" />
            </div>
          )}
        </Mutation>
      );
    }
  }

  render() {
    const { person } = this.props;
    const user = getUser();

    if (!person) return <div></div>;

    return (
      <div className={styles.contain}>
        <div className={styles.header}>
          <Link
            to={`/Profile/${person.id}`}
            className={styles.nameContain}
          >
            <h3 className={styles.name}>{person.name}</h3>
          </Link>
          {this.iconButton(person, user)}
        </div>
        <Link to={`/Profile/${person.id}`}>
          <div className={styles.mainContain}>
            <div className={styles.textContain}>
              <span className={styles.upcoming}>
                Upcoming events: {person.upcomingEventsCount > 0 && person.upcomingEventsCount}
              </span>
              {person.topUpcomingEvents.length > 0
                ? <ul className={styles.eventsList}>
                    {person.topUpcomingEvents.map((item, i) => (
                      <li
                        key={i}
                        className={styles.eventItem}
                      >
                        {dateFns.format(new Date(item.eventTimestampStart), 'MMM D')}: {item.title}
                      </li>
                    ))}
                  </ul>
                : <p className={styles.noUpcoming}>No upcoming events</p>
              }
            </div>
            <img src={person.profileImage ? person.profileImage : DEFAULT_PROFILE_IMAGE} className={styles.profile} alt="" />
          </div>
        </Link>
      </div>
    )
  }
}

export default PersonItem;

export const GRAPHQL_ENDPOINT = 'http://localhost:4000/graphql';
export const PRODUCTION_GRAPHQL_ENDPOINT = 'https://api.appearix.com/graphql';
export const GOOGLE_ANALYTICS_ID = 'UA-30447587-2';
export const MAX_FILE_SIZE_BYTES = 10485760;
export const DEFAULT_PROFILE_IMAGE = 'https://appearix.com/static/media/defaultProfile.png';
export const SEARCH_DEBOUNCE = 150;
export const PRESETS = {
  'Boulder': {
    lat: '40.0150',
    lon: '-105.2705'
  }
};
export const DEFAULT_IMAGES = [
  'https://appearix.com/static/media/concert.a9ca19d9.jpg',
  'https://appearix.com/static/media/sports.2115f795.jpg',
  'https://appearix.com/static/media/shred.5079b00e.jpg',
  'https://appearix.com/static/media/flight.95a87b18.jpg',
  'https://appearix.com/static/media/forest.63a935de.jpg',
  'https://appearix.com/static/media/park.04d25e3e.jpg',
  'https://appearix.com/static/media/museum.b6c2378c.jpg',
  'https://appearix.com/static/media/comedy.3579ff1a.jpg',
  'https://appearix.com/static/media/beach.b237371a.jpg',
  'https://appearix.com/static/media/theater.jpg',
  'https://appearix.com/static/media/conference.jpg',
  'https://appearix.com/static/media/outdoor.jpg',
  'https://appearix.com/static/media/culture.jpg',
  'https://appearix.com/static/media/meetup.jpg',
  'https://appearix.com/static/media/kids.jpg',
  'https://appearix.com/static/media/meeting4.jpg',
  'https://appearix.com/static/media/meeting2.jpg',
  'https://appearix.com/static/media/beach2.jpg',
  'https://appearix.com/static/media/sports2.jpg',
  'https://appearix.com/static/media/sports3.jpg',
  'https://appearix.com/static/media/sports6.jpg',
  'https://appearix.com/static/media/food.jpg',
  'https://appearix.com/static/media/sports4.jpg',
  'https://appearix.com/static/media/beach.jpg',
  'https://appearix.com/static/media/sports.jpg',
  'https://appearix.com/static/media/music.jpg',
  'https://appearix.com/static/media/meeting.jpg'
];
export const SOURCE_TAGS = {
  bandsintown: [
    'Band',
    'Music',
    'Entertainment',
    'Concert'
  ],
  laughstub: [
    'Comedy',
    'Entertainment'
  ],
  meetup: [
    'Meetup'
  ],
  redtri: [
    'Kids',
    'Fun',
    'Children'
  ],
  songkick: [
    'Band',
    'Music',
    'Entertainment',
    'Concert'
  ],
  techmeme: [
    'Tech'
  ],
  theathermania: [
    'Theater',
    'Entertainment'
  ],
  songkickFestivals: [
    'Music',
    'Festival'
  ]
};
export const DISCOVER_TAGS = [
  'Band',
  'Music',
  'Concert',
  'Comedy',
  'Kids',
  'Entertainment',
  'Tech',
  'Theater',
  'Festival'
];
export const SF_GEO = {
  lat: '37.7749',
  lon: '-122.4194'
}

import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { MdClear, MdMyLocation } from 'react-icons/md';

import styles from './NewUserFlow.module.css';
import Loading from '../Common/Loading';
import PersonItem from '../Common/PersonItem';
import AboutBanner from '../About/AboutBanner';
import Empty from '../Common/Empty';
import InfiniteScroll from '../Common/InfiniteScroll';
import Modal from '../Common/Modal';
import Footer from './Footer';
import SignUpModal from '../Common/SignUpModal';
import TagsRow from '../Discover/TagsRow';
import { getUser } from '../../utils/userState';
import { shuffle } from '../../utils/shuffle';
import { DISCOVER_TAGS } from '../../utils/constants';
import SearchBoxLight from '../Search/SearchBoxLight';
import ErrorMsg from '../Common/ErrorMsg';
import { PRESETS } from '../../utils/constants';

class NewUserFlow extends React.Component {
  constructor(props) {
    super(props);

    const address = JSON.parse(localStorage.getItem('formattedAddress') || 'false');
    let latLong = JSON.parse(localStorage.getItem('latLong'));
    latLong = 'id' in this.props.match.params ? PRESETS[this.props.match.params.id] : null;

    this.state = {
      address: address ? address : '',
      location: latLong ? latLong : false,
      showModal: false,
      loading: false,
      showSignUp: false,
      signUpInfo: false,
      activeTag: false
    }
  }

  user = getUser()
  tags = DISCOVER_TAGS

  componentWillMount() {
    this.tags = shuffle(DISCOVER_TAGS);
  }

  selectTag = (tag) => {
    if (tag === this.state.activeTag) {
      this.tags.unshift(tag);
      this.setState({
        activeTag: false
      });
    }
    else {
      if (this.state.activeTag) this.tags.unshift(this.state.activeTag);
      this.tags = this.tags.filter(item => item !== tag);
      this.setState({
        activeTag: tag
      });
    }
  }

  clearLocation = () => {
    this.address.focus();
    this.setState({ address: '', location: false });
    localStorage.setItem('formattedAddress', '');
    localStorage.setItem('latLong', 'false')
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
    this.address && this.address.focus();
  }

  locationChange = (address) => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.setState({ loading: true });
    geocodeByAddress(address)
      .then(results => {
        const res = results[0];
        this.setState({ address: res.formatted_address, loading: true });
        localStorage.setItem('formattedAddress', JSON.stringify(res.formatted_address));
        return res;
      })
      .then(re => getLatLng(re))
      .then(({ lat, lng }) => {
        const coords = { lat: lat.toString(), lon: lng.toString() };
        this.parent.scrollTop = 0;
        this.setState({ location: coords, showModal: false, loading: false })
        localStorage.setItem('latLong', JSON.stringify(coords));
      })
      .catch(error => console.error('Error', error));
  };

  toggleSignUp = (id, title, type) => {
    this.setState({ signUpInfo: { id, title, type }, showSignUp: !this.state.showSignUp });
  }

  hideSignUp = () => {
    this.setState({ showSignUp: false });
  }

  render() {
    const { match, history } = this.props;

    return (
      <div className={styles.contain} onScroll={this.handleScroll} ref={(ref) => this.parent = ref}>
        {this.state.showModal &&
          <Modal
            shown={this.state.showModal}
            hide={this.toggleModal}
            className={styles.locationModal}
          >
            <p className={styles.sectionHeader}>Change Location</p>
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.locationChange}
              onSelect={this.handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div className={styles.locationContain}>
                    <div className={styles.locationIcon}>
                      <MdMyLocation size={20} />
                    </div>
                    <input
                      {...getInputProps({
                        ref: (el) => {this.address = el},
                        placeholder: 'Change location',
                        className: styles.locationInput,
                        autoFocus: true
                      })}
                    />
                    <div
                      onClick={this.clearLocation}
                      className={styles.locationClear}
                      style={{opacity: this.state.address !== '' ? 1 : 0 }}
                    >
                      <MdClear size={12} />
                    </div>
                  </div>
                  <div className={styles.placesAutocomplete}>
                    {loading && <div className={styles.address}>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? styles.activeAddress
                        : styles.address;
                      return (
                        <div {...getSuggestionItemProps(suggestion, { className })}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                    {this.state.loading && <Loading />}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div className={styles.closeContain}>
              <div onClick={this.toggleModal} className={styles.closeModal}>Close</div>
            </div>
          </Modal>
        }
        <AboutBanner landing={true} />
        <div
          className={styles.search}
          ref={(el) => this.header = el}
          onClick={() => history.push('/Search')}
        >
          <div className={styles.searchContain}>
            <SearchBoxLight disabled />
          </div>
        </div>
        <div className={styles.discover}>
          <Query
            query={DiscoverQuery}
            variables={{
              pagination: {
                offset: 0,
                limit: 10
              },
              location: this.state.location
                ? this.state.location
                : this.user
                  ? this.user.location
                  : undefined,
              sortTag: this.state.activeTag
                ? this.state.activeTag
                : undefined
            }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data, fetchMore }) => {
              if (error) return <ErrorMsg error={error} />;

              let { discover } = data;
              if (!discover) return <Loading />;
              else if (discover.results.length === 0 && !this.state.activeTag) return <Empty page="discover" />;

              const loadMore = () => {
                fetchMore({
                  variables: {
                    pagination: {
                      offset: discover ? discover.results.length : 0,
                      limit: 10
                    }
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const newRes = Object.assign({}, prev, {
                      discover: {
                        total: prev.discover.total,
                        results: [...prev.discover.results, ...fetchMoreResult.discover.results],
                        __typename: 'EventList'
                      }
                    });
                    return newRes;
                  }
                });
              };

              return (
                <InfiniteScroll
                  loading={loading}
                  getParent={() => this.parent}
                  hasMore={discover
                    ? (discover.results.length < discover.total && discover.results.length > 9)
                    : false
                  }
                  loadMore={loadMore}
                  match={match}
                >
                  <div className={styles.mainColumn}>
                    <TagsRow
                      locationToggle={this.toggleModal}
                      activeTag={this.state.activeTag}
                      tags={this.tags}
                      selectTag={this.selectTag}
                    />
                    {'discover' in data && data.discover.results.map((person, i) => (
                      <PersonItem
                        key={i}
                        person={person}
                        toggleSignUp={this.toggleSignUp}
                      />
                    ))}
                    {discover.results.length === 0 &&
                      <div>
                        <h1>No results to show, David...</h1>
                      </div>
                    }
                  </div>
                </InfiniteScroll>
              )
            }}
          </Query>
        </div>
        <Modal
          shown={this.state.showSignUp}
          hide={this.hideSignUp}
          containStyle={{ top: 0, bottom: 0 }}
          modalStyle={{ height: 'auto', width: '95%', maxWidth: '400px', borderRadius: 6 }}
          backgroundStyle={{ background: 'rgba(0,0,0,0.7)' }}
        >
          <SignUpModal
            hide={this.hideSignUp}
            history={history}
            id={this.state.signUpInfo.id}
            title={this.state.signUpInfo.title}
            type={this.state.signUpInfo.type}
          />
        </Modal>
        <Footer />
      </div>
    )
  }
}

const DiscoverQuery = gql`
  query discover($pagination: Pagination!, $location: GeoPointInput, $sortTag: String) {
    discover(pagination: $pagination, location: $location, sortTag: $sortTag) {
      total
      results {
        id
        profileImage
        name
        upcomingEventsCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
      }
    }
  }
`;

export default NewUserFlow;

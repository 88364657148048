import React from 'react';
import {
  FaApple,
  FaGoogle,
  FaYahoo,
  FaCalendar,
  FaFacebookSquare,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaLink
} from 'react-icons/fa';
import {
  MdCheck
} from 'react-icons/md';

import styles from './AddToCal.module.css';
import getUrl from '../../utils/calendarUrl';
import { getUser } from '../../utils/userState';
import copyToClipboard from '../../utils/copyToClipboard';

const calTypes = [
  'Google',
  'iCal',
  'Outlook',
  'Yahoo'
];

class AddToCal extends React.Component {
  state = {
    copied: false
  }

  component() {
    this.setState({ copied: false });
  }

  handleSubmit = (key, event) => {
    const url = getUrl(calTypes[key], event);
    url && window.open(url, '_blank');
  }

  render() {
    const {
      shown,
      hide,
      type,
      event = {},
      hideCal = false
    } = this.props;

    const id = event.id;
    const user = getUser();
    let self = false;
    if (user && event.id === user.id) self = true;

    const link = `https://appearix.com/${type}/${id}`;

    return (
      <div
        className={styles.contain}
        style={shown
          ? { visibility: 'visible', opacity: 1 }
          : { visibility: 'hidden', opacity: 0 }
        }
      >
        <div
          className={styles.background}
          onClick={hide}
        />
        <div className={styles.cardContain}>
          {(type === 'event' && !hideCal) &&
            <div>
              {event.haveAdded &&
                <div className={styles.addedHeader}>
                  <MdCheck size={20} />
                  <span>Added!</span>
                </div>
              }
              <h4 className={styles.header}>Export to calendar</h4>
              <div className={styles.iconRow}>
                <div
                  onClick={() => this.handleSubmit(0, event)}
                  className={styles.iconContain}
                >
                  <FaGoogle size={20} />
                  Google
                </div>
                <div
                  onClick={() => this.handleSubmit(1, event)}
                  className={styles.iconContain}
                >
                  <FaApple size={22} />
                  iCal
                </div>
                <div
                  onClick={() => this.handleSubmit(2, event)}
                  className={styles.iconContain}
                >
                  <FaCalendar size={20} />
                  Outlook
                </div>
                <div
                  onClick={() => this.handleSubmit(3, event)}
                  className={styles.iconContain}
                >
                  <FaYahoo size={20} />
                  Yahoo!
                </div>
              </div>
              <div className={styles.divider} />
            </div>
          }
          <div>
            <h4 className={styles.header}>
              Share {type === 'profile'
                ? self
                  ? 'your profile'
                  : 'this profile'
                : 'this event'
              }
            </h4>
            <div className={styles.iconRow}>
              <a
                href={`mailto:?subject=${user ? user.name : 'Someone you know on appearix'} has shared this ${type} with you&body=Check out this ${type} on appearix: ${link}`}
                className={styles.email}
              >
                <FaEnvelope size={20} color="white" />
              </a>
              <div
                onClick={() => copyToClipboard({
                  window,
                  document,
                  content: link,
                  callback: () => this.setState({ copied: true })
                })}
                className={styles.copyLink}
              >
                <FaLink size={20} color="white" />
              </div>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.fb}
              >
                <FaFacebookSquare size={20} color="white" />
              </a>
              <a
                href={`https://twitter.com/home?status=${link}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.twit}
              >
                <FaTwitter size={20} color="white" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.in}
              >
                <FaLinkedinIn size={20} color="white" />
              </a>
            </div>
          </div>
          {this.state.copied && <div className={styles.copyMsg}>Copied to clipboard.</div>}
          <div className={styles.buttonRow}>
            <div
              className={styles.button}
              onClick={() => {
                hide();
                this.setState({ copied: false });
              }}
            >
              Hide
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddToCal

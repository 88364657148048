import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import Connections from './Connections2';
import Loading from '../Common/Loading';
import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import AddToCal from '../Common/AddToCal';
import Modal from '../Common/Modal';
import SignUpModal from '../Common/SignUpModal';
import styles from './Connections.module.css';
import { getUser } from '../../utils/userState';

class Added extends React.Component {
  state = {
    showSignUp: false,
    signUpInfo: {},
    showShare: false,
    shareItem: {},
    shareType: 'event'
  }

  user = getUser()

  showShare = (item, type) => {
    this.setState({
      showShare: true,
      shareItem: item,
      shareType: type
    });
  }

  hideShare = () => {
    this.setState({
      showShare: false
    });
  }

  showSignUp = (id, title, type) => {
    this.setState({
      showSignUp: true,
      signUpInfo: {
        id,
        title,
        type
      }
    });
  }

  hideSignUp = () => {
    this.setState({
      showSignUp: false
    });
  }

  render() {
    const { history, match } = this.props;

    return (
      <div>
        <Header
          history={history}
          match={match}
          backButton
          navTitle="Added By"
          share={this.showShare}
          shareType="event"
        />
        <div className={`body ${styles.swiper}`}>
          <Query
            query={this.user ? EventQuery : PublicEventQuery}
            variables={{
              eventId: match.params.id
            }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return <p>{error.message}</p>;

              const { event } = data;
              return (
                <div className={styles.innerScroll}>
                  <Connections
                    items={event.added}
                    toggleSignUp={this.showSignUp}
                  />
                </div>
              )
            }}
          </Query>
        </div>
        <TabBar match={match} />
        <Modal
          shown={this.state.showSignUp}
          hide={this.hideSignUp}
          containStyle={{ top: 0, bottom: 0 }}
          modalStyle={{ height: 'auto', width: '95%', maxWidth: '400px', borderRadius: 6 }}
          backgroundStyle={{ background: 'rgba(0,0,0,0.7)' }}
        >
          <SignUpModal
            hide={this.hideSignUp}
            history={history}
            id={this.state.signUpInfo.id}
            title={this.state.signUpInfo.title}
            type={this.state.signUpInfo.type}
          />
        </Modal>
        <AddToCal
          shown={this.state.showShare}
          event={this.state.shareItem}
          hide={this.hideShare}
          type={this.state.shareType}
          hideCal
        />
      </div>
    );
  }
};

const EventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      added {
        id
        name
        profileImage
        upcomingEventsCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
        haveFollowed
      }
    }
  }
`;

const PublicEventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      added {
        id
        name
        profileImage
        upcomingEventsCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
      }
    }
  }
`;

export default Added;

import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './GroupSelect.module.css';
import Loading from '../Common/Loading';
import Button from '../Common/Button';
import { MdPeople, MdGroupAdd } from 'react-icons/md';

const GroupSelect = ({ addGroup, toggle }) => (
  <div className={styles.contain}>
    <Query
      query={GroupsQuery}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return (
          <div className={styles.inner}>
            <h4>Error getting groups.</h4>
            <Button 
              onClick={toggle}
              label="Back"
            />
          </div>
        );

        const { getGroups } = data;
        if (getGroups.total === 0) {
          return (
            <div className={styles.inner}>
              <h4>Groups you create will show up here.</h4>
              <Button 
                onClick={toggle}
                label="Back"
                light
              />
            </div>
          )
        }

        return (
          <div className={styles.inner}>
            <h4>Select Group</h4>
            {getGroups.results.map((group, i) => (
              <GroupSelectItem
                key={i}
                group={group}
                onClick={() => addGroup(group)}
              />
            ))}
            <Button 
              onClick={toggle}
              label="Close"
              light
            />
          </div>
        )
      }}
    </Query>
  </div>
);

const GroupSelectItem = ({ group, onClick }) => (
  <div 
    onClick={onClick}
    className={styles.item}
  >
    <div className={styles.itemIcon}>
      <MdPeople size={20} />
    </div>
    <div className={styles.itemText}>
      <p className={styles.title}>{group.title}</p>
      <span className={styles.subtitle}>{group.pings.length} members</span>
    </div>
    <div className={styles.itemAction}>
      <MdGroupAdd size={20} />
    </div>
  </div>
)

const GroupsQuery = gql`
  query getGroups {
    getGroups {
      total
      results {
        id
        title
        pings {
          type
          value
          user {
            id
            name
            profileImage
          }
        }
      }
    }
  }
`;

export default GroupSelect;
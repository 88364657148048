import React from 'react';

import styles from './About.module.css';
import AboutHeader from './AboutHeader';
import AboutSection from './AboutSection';
import AboutBanner from './AboutBanner';
import TeamSection from './TeamSection';
import TabBar from '../Common/TabBar';
import MenuModal from '../Common/MenuModal';

import mikeProfile from '../../images/mikeProfile.jpeg';
import maxProfile from '../../images/maxProfile.jpg';
import davidProfile from '../../images/davidProfile.jpg';
import bryceProfile from '../../images/bryceProfile.jpg';

class About extends React.Component {
  state = {
    showMenu: false
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    const { history, match } = this.props;

    return (
      <div>
        <AboutHeader
          title="About Us"
          history={history}
          toggle={this.toggleMenu}
        />
        <div className={styles.contain}>
          <AboutBanner />
          <AboutSection
            header="What We Do"
            body="Appearix is the social event planner that allows you to find cool things to do and tell the world which ones you are interested in. Discover events, create new ones for others to find and let the world know where you are appearing next."
            className={styles.first}
          />
          <AboutSection
            header="Discovering People on Appearix"
            body="In the Discover tab, you will find people and organizations that already have created events for discovery in the Appearix index.  Clicking the plus button next to a person’s name means you are now following that person.  Any event that they create or follow will now appear in your own Event Feed.  At the top of the Discover tab, you will see category tags so that you can sort by your specific interests.   You can also search the Discover tab to find specific people or places that you are interested in following. The more people you follow means the more events you will find in your Event Feed"
          />
          <AboutSection
            header="The Event Feed"
            body={`In the Event tab, you will find a feed of events from the people you are following (your friends).  As these folks add new events to the system, they will automatically funnel into your Event Feed.  If you see an event your interested in, click the + button to add it to your Appearix calendar.${'\n\n'}The default sort for the event feed is chronological, with the events coming up the soonest appearing at the top.  You can sort the event feed by friends as well.  This sort moves the events your friends have added to the top of the list.  As the people you follow create new events and/or add them to their calendar, those new events show up automatically in your feed.  Events that have already occurred are automatically removed from your event feed.`}
          />
          <AboutSection
            header="Your Appearix Calendar"
            body="Any event you create or add automatically gets added to your Appearix calendar.  You can view your calendar by clicking the Added tab to see the events you have upcoming.  If you want to view someone else’s calendar just click on their profile.  Appearix will send you a notification when events you have added are coming up.  Appearix also allows you to copy any event from your appearix calendar to your private calendar (Google, iCal, etc.).  No events from your private calendar will ever be added to your Appearix calendar absent you typing them in."
          />
          <AboutSection
            header="Create Your Own Events"
            body={`In addition to finding events by adding them, you can create your own events.  These events will not only show up on your calendar, but also in the event feed of people who follow you.${'\n\n'}To create an event, click on the floating + button and fill in the form.  You can alert people of your new event by pinging them directly from the event creation form.  You can ping people by typing their Appearix ID, their email or their phone number (SMS) and they will be sent a notification of your event directly.  Any event you create can be added by others to their calendar (the more the merrier!).`}
          />
          <AboutSection
            header="Share"
            body="You can share your profile (i.e. your event feed and your Appearix calendar), others’ profiles and/or any specific event with others by clicking on the upper right hand arrow.  This is an easy way to alert others of your appearance schedule and gain more followers!"
          />
          <AboutSection
            header="Profile and Notifications"
            body="Clicking on your icon on the upper left of the page takes you to your profile page. There you can add/ edit your description and picture and change certain settings.  Your profile is also the home for any notifications you receive.  Notifications are sent when events you’ve added are upcoming, someone new follows you, someone pings you on an event using your Appearix ID, anyone adds an event you created and when others you follow adds an event that you have previously added."

          />
          <AboutSection
            header="Meet the Team"
            className={styles.last}
            content={
              <div className={styles.team}>
                <TeamSection
                  name="Mike Markson"
                  title="Cofounder, CEO"
                  body="Mike has spent the past 19 years in Silicon Valley building and running technology startups, including Terraspring, Topix and blekko.  His areas of focus include initial product design, business development and marketing distribution.  Prior to moving to Silicon Valley he spent 6 years as a mergers and acquisitions attorney in NYC for Brown and Wood."
                  image={mikeProfile}
                />
                <TeamSection
                  name="David Skrenta"
                  title="Cofounder, CTO"
                  body={`David is a versatile developer with experience in various front end and back end technologies.${'\n'}He has developed a number of projects, including Better America, a platform to crowdsource potential legislation for government, Harvix, a research engine for students, and Octograde, a test-administering tool for teachers. He has also consulted for a number of clients and regularly participates in hackathons. David is a sophomore in computer science at the University of Colorado, Boulder.`}
                  image={davidProfile}
                />
                <TeamSection
                  name="Bryce DesBrisay"
                  title="Cofounder, VP of Engineering"
                  body="Bryce is a talented graphic designer and artist, and is adept at front end coding. Bryce has implemented front-end designs and UX for a variety of projects, including Better America, Harvix, Octograde, and others. Bryce is a sophomore in computer science at the University of Colorado, Boulder."
                  image={bryceProfile}
                />
                <TeamSection
                  name="Max Lieberman"
                  title="VP of Marketing"
                  body="Max is an experienced sales and marketing specialist. In addition to being the VP of Marketing for Harvix, he also sports an excellent sales record at Nordstrom. Now, Max is a senior studying Marketing and Information Analytics with a certificate in Technology, Art, and Media at the University of Colorado Boulder Leeds School of Business and has held leadership positions for Delta Sigma Pi and for the Multicultural Business Student Association. Professionally, Max previously held an intern position at National Storage Affiliates where he worked to build an expense forecasting spreadsheet to streamline pro forma processes, and now interns for Epsilon as a data analyst."
                  image={maxProfile}
                />
              </div>
            }
          />
        </div>
        <TabBar match={match} />
        <MenuModal
          visible={this.state.showMenu}
          toggle={this.toggleMenu}
          tall={false}
          items={[
            {
              label: 'Terms of Service',
              onClick: () => history.push('/TOS')
            },
            {
              label: 'Privacy Policy',
              onClick: () => history.push('/Privacy')
            }
          ]}
        />
      </div>
    )
  }
}

export default About;

import React from 'react';

import styles from './TeamSection.module.css';

const TeamSection = ({
  name,
  title,
  body,
  image
}) => (
  <div>
    <div className={styles.header}>
      <img 
        src={image}  
        className={styles.image}
        alt=""
      />
      <div className={styles.textContain}>
        <h2 className={styles.name}>
          {name}
        </h2>
        <h3 className={styles.title}>
          {title}
        </h3>
      </div>
    </div>
    <p className={styles.body}>
      {body}
    </p>
  </div>
);

export default TeamSection;
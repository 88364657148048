import React from 'react';
import { Link } from 'react-router-dom';
import { MdEventAvailable, MdPublic, MdAdd } from 'react-icons/md';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './TabBar.module.css';
import EventsIcon from './EventsIcon';
import Footer from '../Landing/Footer';
import { getUser } from '../../utils/userState';
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants';

const getProfile = (pageUserId, user, match) => (
  <Query
    query={UnreadNotificationCountQuery}
    fetchPolicy="network-only"
  >
    {({ loading, error, data }) => {
      let notificationsCount = 0;
      let profileStyle = {};
      if (error) console.error(`Error! ${error.message}`);
      else if (match.path === '/Profile/:id' && pageUserId && pageUserId === user.id) {
        notificationsCount = 0;
        profileStyle = { borderColor: 'var(--main)' };
      }
      else notificationsCount = data.unreadNotificationCount;

      return (
        <div
          className={styles.profileContain}
          style={profileStyle}
        >
          <Query
            query={ProfileQuery}
            fetchPolicy="cache-first"
            variables={{
              userId: user.id
            }}
          >
            {({ loading, error, data: profileData }) => {
              if (loading) return (
                <img
                  src={DEFAULT_PROFILE_IMAGE}
                  className={styles.profile}
                  alt=""
                />
              );
              if (error) return (
                <img
                  src={DEFAULT_PROFILE_IMAGE}
                  className={styles.profile}
                  alt=""
                />
              );

              return (
                <img
                  src={profileData.profile.profileImage}
                  className={styles.profile}
                  alt=""
                />
              );
            }}
          </Query>
          {!!notificationsCount &&
            <div className={styles.smallBadge}></div>
          }
        </div>
      );
    }}
  </Query>
);

const getTabs = (pageUserId, user, match) => (
  [
    {
      label: 'Events',
      icon: <EventsIcon />,
      path: ['/Events'],
      id: 'events-tab'
    },
    {
      label: 'Discover',
      icon: <MdPublic size={24} />,
      path: ['/Discover'],
      id: 'discover-tab'
    },
    {
      label: 'Create',
      icon: (
        <div className={styles.create}>
          <MdAdd size={27} color="white" />
        </div>
      ),
      path: ['/CreateEvent'],
      id: 'upcoming-tab'
    },
    {
      label: 'Added',
      icon: <MdEventAvailable size={24} />,
      path: ['/Home', '/'],
      id: 'upcoming-tab'
    },
    {
      label: 'Added',
      icon: (
        <img
          src={user.profileImage}
          className={styles.profile}
          alt=""
        />
      ),
      path: [`/Profile/${user.name.split(' ').join('-')}-${user.id}`],
      id: 'upcoming-tab'
    },
  ]
);

const TabBar = ({ match, pageUserId }) => {
  const user = getUser();
  if (!user) {
    return (
      <Footer />
    );
  }
  else {
    const tabs = getTabs(pageUserId, user, match);
    return (
      <div className={styles.row} id="tab-bar">
        <div className={styles.innerRow}>
          {tabs.map((tab, i) => {
            const style = {
              color: tab.path.includes(match.path) ? 'var(--main)' : 'var(--text)',
              fill: tab.path.includes(match.path) ? 'var(--main)' : 'var(--text)'
            }
            return (
              <Link
                to={tab.path[0]}
                key={i}
                className={styles.tab}
                style={style}
                id={tab.id}
              >
                {tab.icon}
                {/*<p className={styles.label}>{tab.label}</p>*/}
              </Link>
            )
          })}
        </div>
      </div>
    );
  }
}

const UnreadNotificationCountQuery = gql`
  query unreadNotificationCount {
    unreadNotificationCount
  }
`;

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      id
      profileImage
    }
  }
`;

/*
else {
  return (
    <div
      className={styles.badge}
      style={notificationsCount > 99 ? {fontSize: 13} : {}}
    >
      {notificationsCount}
    </div>
  );
}
*/

/*
  Light Theme

  backgroundColor: match.path === tab.path ? '#4a90e2' : '#fafbfc',
  color: match.path === tab.path ? '#fff' : '#555',
  borderRight: i === 1 ? '1px solid #eee' : 'none',
  borderLeft: i === 1 ? '1px solid #eee' : 'none'

  Dark Theme

  backgroundColor: match.path === tab.path ? '#4a90e2' : '#555',
  borderRight: i === 1 ? '1px solid #666' : 'none',
  borderLeft: i === 1 ? '1px solid #666' : 'none'
*/

export default TabBar;

import React from 'react';

import NewUserFlow from './NewUserFlow';
import { isSignedIn } from '../../utils/userState';

class Landing extends React.Component {
  componentWillMount() {
    if (isSignedIn()) {
      this.props.history.push('/Events');
    }
  }

  render() {
    const { history, match } = this.props;
    return <NewUserFlow history={history} match={match} />;
  }
}

export default Landing;

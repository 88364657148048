import React from 'react';
import { Link } from 'react-router-dom';
import { MdArrowBack, MdSearch } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';

import { getUser } from '../../utils/userState';
import styles from './Header.module.css';
import SearchBoxLight from '../Search/SearchBoxLight';

class Header extends React.Component {
  formatPathName = (match) => {
    if (match.path === '/Home') {
      return 'Home';
    }
    else if (match.path === '/CreateEvent') {
      return 'Create Event';
    }
    else if (match.path === '/Profile/:id') {
      return 'Profile';
    }
    else if (match.path === '/Followers/:id') {
      return 'Followers';
    }
    else if (match.path === '/Following/:id') {
      return 'Following';
    }
    else {
      return match.path.slice(1);
    }
  }

  mainTab = (match) => {
    return ['/Events', '/Discover', '/Home'].includes(match.path);
  }

  render() {
    const {
      match,
      history,
      backButton,
      backFunc,
      rightIcon,
      rightFunc,
      navTitle,
      share,
      shareType = 'profile',
      customNav = false,
      pageUserId = false
    } = this.props;

    const user = getUser();

    if (!user) {
      return (
        <div className={styles.contain}>
          <div className={styles.top}>
            <Link
              to="/Search"
              className={styles.searchContain}
            >
              <SearchBoxLight value="" />
            </Link>
          </div>
          <div className={styles.bottom}>
            {backButton &&
              <div
                className={styles.backButton}
                onClick={backFunc
                  ? backFunc
                  : () => {
                      history.length !== 2
                        ? history.goBack()
                        : history.push('/');
                    }
                }
              >
                <MdArrowBack size={24} color="white" />
              </div>
            }
            {navTitle
              ? <p>{navTitle}</p>
              : <p>{user.name}: {this.formatPathName(match)}</p>
            }
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.contain}>
          <div className={styles.top}>
            <Link
              to='/Search'
              className={styles.leftContain}
            >
              <MdSearch
                size={26}
                color="var(--text)"
                className={styles.search}
              />
            </Link>
            <Link
              to="/Events"
              style={{ textDecoration: 'none' }}
            >
              <p>appear<span>ix</span></p>
            </Link>
            <div
              className={styles.rightContain}
              onClick={() => {share && share({
                id: pageUserId
                  ? pageUserId
                  : match.params.id
                },
                shareType
              )}}
              style={{ cursor: share ? 'pointer' : 'unset' }}
            >
              <IoMdShareAlt
                size={26}
                color={share ? 'var(--text)' : 'var(--text-light)'}
                className={styles.share}
              />
            </div>
          </div>
          {customNav
            ? customNav
            : <div className={styles.bottom}>
                {backButton &&
                  <div
                    className={styles.backButton}
                    onClick={backFunc
                      ? backFunc
                      : () => {history.goBack()}
                    }
                  >
                    <MdArrowBack size={24} color="white" />
                  </div>
                }
                {navTitle
                  ? <p>{navTitle}</p>
                  : <p>{user.name}: {this.formatPathName(match)}</p>
                }
                {(rightIcon && rightFunc) &&
                  <div
                    className={styles.rightButton}
                    onClick={rightFunc}
                  >
                    {rightIcon}
                  </div>
                }
              </div>}
        </div>
      );
    }
  }
}

export default Header;

import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './Groups.module.css';
import { getUser } from '../../utils/userState';

import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import Loading from '../Common/Loading';
import Button from '../Common/Button';
import Group from './Group';

class Groups extends React.Component {
  user = getUser()

  render() {
    const { match, history } = this.props;
    return (
      <div>
        <Header
          match={match}
          history={history}
          navTitle="My Groups"
          // share={this.showShare}
          backButton
          pageUserId={this.user.id}
        />
        <div className="body">
          <div className={styles.contain}>
            <h1 className={styles.sectionHeader}>My Groups</h1>
            <Query
              query={GroupsQuery}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                if (loading) return <Loading />;
                if (error) return <span>Error</span>;

                const { getGroups } = data;
                if (getGroups.total === 0) {
                  return (
                    <div className={styles.span}>
                      No groups to show.
                    </div>
                  )
                }

                return (
                  <div>
                    {getGroups.results.map((group, i) => (
                      <Group 
                        group={group} 
                        key={group.id}
                        history={history}
                      />
                    ))}
                  </div>
                )
              }}
            </Query>
            <Button 
              onClick={() => history.push('/CreateGroup')}
              label="Create New Group"
            />
            {/*
            <h1>Current groups (edit, delete)</h1>
            <h3>Group Name (expand)</h3>
            <h3>Group Name (collapse)</h3>
            <p>Username (remove)</p>
            <p>Username (remove)</p>
            <button>Add new people to group</button>
            <br/>
            <button>Create New Group</button>
            <input placeholder="Group Name" />
            */}
          </div>
        </div>
        <TabBar match={match} />
      </div>
    )
  }
}

const GroupsQuery = gql`
  query getGroups {
    getGroups {
      total
      results {
        id
        title
        pings {
          type
          value
          user {
            id
            name
            profileImage
          }
        }
      }
    }
  }
`;

export default Groups;
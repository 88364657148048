import React from 'react';
import dateFns from 'date-fns';
import { MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import styles from './Notification.module.css';
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants';

const Notification = ({ item }) => (
  <Link
    to={item.url}
    className={styles.contain}
    style={!item.read ? {border: '1px solid var(--secondary)', boxShadow: 'var(--shadow-secondary)'} : {}}
  >
    <img 
      src={(item.actionUser && item.actionUser.profileImage)
        ? item.actionUser.profileImage 
        : DEFAULT_PROFILE_IMAGE
      } 
      className={styles.image} 
      alt="" 
    />
    <span className={styles.message}>
      {item.message}
      <br />
      <span>{dateFns.distanceInWordsToNow(item.createdAt)} ago</span>
    </span>
    <MdChevronRight size={24} color="var(--text-light)" className={styles.icon} />
  </Link>
)

export default Notification;

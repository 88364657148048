import React from 'react';

import styles from './PingItem.module.css';
import { MdEmail } from 'react-icons/md';

const PingItem = ({ 
  ping = {}, 
  onClick = () => {},
  icon = false,
  actionIcon = false,
  imageStyle = {},
  style = {}
}) => {
  let item = ping;
  if (item.user) item = ping.user;
  return (
    <div
      className={styles.item}
      onClick={onClick}
      style={style}
    >
      {item.profileImage
        ? <img
            className={styles.image}
            src={item.profileImage}
            alt=""
            style={imageStyle}
          />
        : icon 
          ? <div 
              className={styles.icon}
              style={imageStyle}
            >
              {icon}
            </div>
          : <div 
              className={styles.email}
              style={imageStyle}
            >
              <MdEmail />
            </div>
      }
      {item.name
        ? <span className={styles.name}>{item.name}</span>
        : <span className={styles.value}>{item.value}</span>
      }
      {actionIcon &&
        <div className={styles.action}>
          {actionIcon}
        </div>
      }
    </div>
  )
}

export default PingItem;
import React from 'react';
import { MdWarning } from 'react-icons/md';

import styles from './ErrorMsg.module.css';
import funnies from '../../utils/funnies';

const ErrorMsg = ({
  error = {},
  icon = true,
  small = false,
  funny = true,
  align = 'center'
}) => (
  <div
    className={small
      ? styles.small
      : styles.large
    }
    style={{
      textAlign: align
    }}
  >
    {(icon && !small) &&
      <MdWarning
        size={50}
        className={styles.icon}
      />
    }
    <p
      className={small
        ? styles.smallMsg
        : styles.largeMsg
      }
    >
      {error.message}
    </p>
    {(funny && !small) &&
      <p className={styles.funnies}>
        {funnies()}
      </p>
    }
  </div>
);

export default ErrorMsg;

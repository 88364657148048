import React from 'react';

import styles from'./Button.module.css';

const Button = ({
  onClick = () => {},
  label = '',
  light = false,
  className,
  disabled,
  style = {},
  ...props
}) => (
  <button
    onClick={(e) => onClick(e)}
    className={`${light ? styles.light : styles.button} ${className ? className : ''}`}
    style={{ ...style, background: disabled ? 'var(--text-light)' : '' }}
    disabled={disabled}
    {...props}
  >
    {label}
  </button>
);

export default Button;

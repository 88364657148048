import React from 'react';
import { MdClear, MdTitle } from 'react-icons/md';
import gql from 'graphql-tag';

import styles from './GroupForm.module.css';
import Button from '../Common/Button';
import GroupInput from '../Pings/GroupInput';
import PingItem from '../Pings/PingItem';

class GroupForm extends React.Component {
  state = {
    title: this.props.group ? this.props.group.title : '',
    pings: this.props.group ? this.props.group.pings : [],
    value: '',
    loading: false
  }

  handleClear = (name, ref) => {
    this.setState({ [name]: '' });
    ref && ref.focus();
  }

  onChange = (e, name) => {
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  addPerson = (person) => {
    const { pings } = this.state;
    const newPings = pings.concat(person);

    this.setState({
      pings: newPings,
      value: ''
    });
  }

  delPerson = (person) => {
    const { pings } = this.state;
    const newPings = pings.filter((item) => item !== person);

    this.setState({
      pings: newPings
    });
  }

  formatPings = (pings, user = false) => {
    if (!user) {
      return pings.map((item) => (
        {
          type: item.type,
          value: item.id || item.value,
        }
      ))
    }
    else {
      return pings.map((item) => (
        {
          type: 'id',
          value: item.id || item.value,
          user: {
            id: item.id,
            name: item.name,
            profileImage: item.profileImage,
            __typename: 'User'
          },
          __typename: 'Ping'
        }
      ))
    }
  }

  submit = async  () => {
    this.setState({ loading: true });

    const { type, group, mutate, history, toggle } = this.props;
    const { title, pings = [] } = this.state;

    console.log(pings)

    const newGroup = Object.assign({}, group, {
      title,
      pings: pings.map(({ user, __typename, ...rest}) => rest)
    });

    console.log(newGroup)

    if (type === 'Create') {
      await mutate({
        variables: newGroup,
        refetchQueries: [{ query: GroupsQuery }],
        update: (cache, { data: { createGroup } }) => {
          /*
          const updateGroup = Object.assign({}, newGroup, {
            pings: this.formatPings(pings, true),
          });

          try {
            const data = cache.readQuery({ query: GroupsQuery });
            const formatted = Object.assign({}, createGroup, updateGroup);

            if (data) {
              const { getGroups } = data;
              console.log(data, getGroups);

              cache.writeQuery({ 
                query: GroupsQuery, 
                data: {
                  getGroups: {
                    total: getGroups.total + 1,
                    results: getGroups.results.push(formatted),
                    __typename: 'GroupList'
                  }
                }
              });
            }
          }
          catch (e) {
            console.log('No getGroups cache to update.');
          }
          */
        },
      });
    }
    else {
      await mutate({
        variables: {
          groupId: group.id,
          ...newGroup
        },
        refetchQueries: [{ query: GroupsQuery }],
        update: (cache, { data: { editGroup } }) => {
          /*
          let data = cache.readQuery({ query: GroupsQuery });
          const formatted = Object.assign({}, editGroup, updateGroup);

          console.log(data, editGroup, formatted)
        
          data.getGroups.total += 1;
          data.getGroups.results.push(formatted);
          
          cache.writeQuery({ query: GroupsQuery, data });
          */
        }
      })
    }

    this.setState({ loading: false });
    if (!toggle) {
      history.push('/Groups');
    }
    else {
      toggle();
    }
  }

  render() {
    const { title, value, pings = [] } = this.state;
    const { type, history, toggle } = this.props;

    return (
      <div className={styles.addModal}>
        <h5 className={styles.sectionHeader}>{type} Group</h5>
        <div className={styles.inputContain}>
          <div className={styles.inputIcon}>
            <MdTitle size={20} />
          </div>
          <input
            placeholder="Name your group"
            value={title}
            onChange={(e) => this.onChange(e, 'title')}
            ref={(el) => this.title = el}
            autoFocus
          />
          <div
            className={styles.clearContain}
            style={{ opacity: title !== '' ? 1 : 0 }}
            onClick={() => this.handleClear('title', this.title)}
          >
            <MdClear size={16} />
          </div>
        </div>
        <GroupInput 
          onChange={(e) => this.onChange(e, 'value')}
          value={value}
          addPing={this.addPerson}
          pings={pings}
          clear={() => this.handleClear('value')}
        />
        <div className={styles.container}>
          <p className={styles.header}>Current Members:</p>
          {pings.length
            ? pings.map((ping, i) => (
                <PingItem 
                  key={i}
                  onClick={() => this.delPerson(ping)}
                  ping={ping} 
                  actionIcon={<MdClear />}
                  imageStyle={{ margin: '3px 12px' }}
                />
              ))
            : <span className={styles.subHeader}>Nobody is in this group yet.</span>
          }
          <Button
            onClick={this.submit}
            label={type === 'Create' ? 'Create Group' : 'Save'}
            disabled={title === '' || this.state.loading}
            style={{ margin: '45px auto 15px' }}
          />
          <Button
            onClick={() => {
              if (!toggle) {
                history.goBack();
              }
              else {
                toggle();
              }
            }}
            label="Cancel"
            light
          />
        </div>
      </div>
    )
  }
}
/*
const SearchResultsQuery = gql`
  query search($query: String!, $pagination: Pagination!) {
    search(query: $query, pagination: $pagination) {
      people {
        id
        name
        profileImage
        isOrganization
      }
      totalResults
    }
  }
`;
*/
const GroupsQuery = gql`
  query getGroups {
    getGroups {
      total
      results {
        id
        title
        pings {
          type
          value
          user {
            id
            name
            profileImage
          }
        }
      }
    }
  }
`;

export default GroupForm;

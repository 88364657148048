import React from 'react';

import styles from './SignUpModal.module.css';

const SignUpModal = ({ hide = () => {}, history, id, title, type }) => {
  return (
    <div className={styles.contain}>
      <img src="https://appearix.com/favicon.png" alt="" className={styles.logo} />
      <div className={styles.textContain}>
        <p className={styles.info}>Please sign in before {type} <strong>{title}</strong></p>
        <div className={styles.row}>
          <button
            className={styles.signUp}
            onClick={() => {
              let state = { followUserIds: id };
              if (type === 'adding') state = { addEventIds: id };

              history.push({
                pathname: '/SignIn',
                state
              })
            }}
          >
            Sign In
          </button>
          <button
            className={styles.browse}
            onClick={() => hide()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignUpModal;

export default function getIdFromUrl(url) {
  const match = url.match(/-(\w+)$/);
  const secondaryMatch = url.match(/\/(\w+)$/);

  if (match) {
    return match[1];
  }
  else if (secondaryMatch) {
    return secondaryMatch[1];
  }
  return null;
}

function removeTypename(obj) {
  for (let key in obj) {
    key === '__typename' && delete obj[key];
    if (obj[key] instanceof Object) {
      for (let nestedKey in obj[key]) {
        nestedKey === '__typename' && delete obj[key][nestedKey];
      }
    }
  }
  return obj;
}

export default removeTypename;
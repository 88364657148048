import React from 'react';

import styles from './MenuModal.module.css';

const MenuModal = ({
  visible,
  toggle,
  items = [],
  tall = true
}) => (
  <div
    className={styles.contain}
    style={menuStyle[visible]}
  >
    <div
      className={styles.bg}
      onClick={toggle}
    />
    <div className={tall ? styles.tallMenu : styles.menu}>
      {items.map((item, i) => (
        <div
          key={i}
          onClick={item.onClick}
          className={styles.menuItem}
          style={item.style ? item.style : {}}
        >
          {item.label}
        </div>
      ))}
    </div>
  </div>
);

const menuStyle = {
  true: { visibility: 'visible', opacity: 1 },
  false: { visibility: 'hidden', opacity: 0 }
};

export default MenuModal;

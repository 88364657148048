import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { MdFindInPage } from 'react-icons/md';

import PersonItem from '../Common/PersonItem';
import EventItem from '../Common/EventItem';
import Loading from '../Common/Loading';
import styles from './SearchResults.module.css';
import { getUser } from '../../utils/userState';
import SourcePrompt from './SourcePrompt';
import ErrorMsg from '../Common/ErrorMsg';

const SearchResults = ({
  query,
  showAddToCal,
  hideAddToCal,
  toggleSignUp
}) => {
  const user = getUser();

  if (query === '') return (
    <div className={styles.emptyContain}>
      <MdFindInPage size={50} color="var(--main)" />
      <p>Start typing to search<br/>for people and events</p>
    </div>
  )
  else {
    return (
      <Query
        query={user ? SearchResultsQuery : PublicResultsQuery}
        variables={{
          query,
          pagination: {
            offset: 0,
            limit: 5
          }
        }}
        skip={query === ''}
        fetchPolicy="network-only"
      >
        {({ loading, error, data = {} }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorMsg error={error} />;
          if (data.search.totalResults === 0) return <SourcePrompt />;
          return (
            <div className={styles.contain}>
              {'search' in data && data.search.people.map((person, i) => (
                <PersonItem
                  key={i}
                  person={person}
                  toggleSignUp={toggleSignUp}
                />
              ))}
              {'search' in data && data.search.events.map((event, i) => (
                <EventItem
                  key={i}
                  event={event}
                  showAddToCal={showAddToCal}
                  hideAddToCal={hideAddToCal}
                  toggleSignUp={toggleSignUp}
                />
              ))}
            </div>
          );
        }}
      </Query>
    );
  }
}

const peopleFrag = gql`
  fragment PeopleFrag on User {
    id
    name
    profileImage
    upcomingEventsCount
    followersCount
    topUpcomingEvents {
      id
      title
      eventTimestampStart
    }
    address
  }
`;

const eventFrag = gql`
  fragment EventFrag on Event {
    id
    host {
      id
      name
      profileImage
    }
    title
    description
    image
    location {
      lat
      lon
    }
    address
    eventTimestampStart
    eventTimestampEnd
    createdAt
    addedCount
    hideTime
  }
`;

const SearchResultsQuery = gql`
  query search($query: String!, $pagination: Pagination!) {
    search(query: $query, pagination: $pagination) {
      people {
        ...PeopleFrag
        haveFollowed
      }
      events {
        ...EventFrag
        haveAdded
        topAdded {
          id
          name
          profileImage
        }
      }
      totalResults
    }
  }
  ${peopleFrag}
  ${eventFrag}
`;

const PublicResultsQuery = gql`
  query search($query: String!, $pagination: Pagination!) {
    search(query: $query, pagination: $pagination) {
      people {
        ...PeopleFrag
      }
      events {
        ...EventFrag
      }
      totalResults
    }
  }
  ${peopleFrag}
  ${eventFrag}
`;

export default SearchResults;

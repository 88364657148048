import React from 'react';

import styles from './AboutSection.module.css';

const AboutSection = ({
  header,
  subHeader,
  body,
  content,
  className = ''
}) => (
  <div className={`${styles.contain} ${className}`}>
    {header &&
      <h1 className={styles.header}>
        {header}
      </h1>
    }
    {subHeader &&
      <h2 className={styles.subHeader}>
        {subHeader}
      </h2>
    }
    {body &&
      <p className={styles.body}>
        {body}
      </p>
    }
    {content && content}
  </div>
);

export default AboutSection;
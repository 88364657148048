import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';

import { getUser } from '../../utils/userState';
import withContext from '../Context/withContext';
import styles from './ProfileContent.module.css';
import EventItem from '../Common/EventItem';
import Notification from './Notification';
import Loading from '../Common/Loading';
import Calendar from '../Common/Calendar';
import Empty from '../Common/Empty';
import InfiniteScroll from '../Common/InfiniteScroll';
import ErrorMsg from '../Common/ErrorMsg';
import adminLinkPermission from '../../utils/adminLinkPermission';
import { fakeCalendarDates } from '../../utils/fakeData';

class ProfileContent extends React.Component {
  state = {
    activeTab: 1,
    activeDate: undefined,
    showMenu: false,
    showTooltip: !this.user
  };

  user = getUser();

  toggleTab = (val) => {
    this.setState({ activeTab: val });
  }

  handleDateChange = (val) => {
    if (val) {
      this.setState({
        activeDate: val,
        dateRange: {
          timestampStart: val,
          timestampEnd: dateFns.endOfDay(val)
        }
      });
    }
    else {
      this.setState({
        activeDate: val,
        dateRange: undefined
      });
    }
  }

  followButton(profileData) {
    // if (!this.user) {
      return (
        <div
          onClick={() => this.props.showSignUp(profileData.profile.id, profileData.profile.name, 'following')}
          className={styles.followButton}
        >
          Follow
        </div>
      )
    // }
    /*
    else if (profileData.profile.id === this.user.id) {
      return (
        <Link
          to="/EditProfile"
          className={styles.followButton}
        >
          Edit Profile
        </Link>
      )
    }
    else if (profileData.profile.haveFollowed) {
      return (
        <Mutation
          mutation={UnfollowMutation}
          update={(cache) => {
            const { profile } = cache.readQuery({
              query: ProfileQuery,
              variables: {
                userId: profileData.profile.id
              }
            });
            cache.writeQuery({
              query: ProfileQuery,
              data: {
                profile: Object.assign({}, profile, {
                  haveFollowed: false,
                  followersCount: profile.followersCount - 1
                }
              )}
            });
          }}
        >
          {(unfollow) => {
            return (
              <div
                onClick={async () => {
                  try {
                    await unfollow({ variables: { unfollowUserId: profileData.profile.id } });
                  }
                  catch (error) {
                    console.error(error);
                  }
                }}
                className={styles.unfollowButton}
              >
                Following
              </div>
            );
          }}
        </Mutation>
      );
    }
    else {
      return (
        <Mutation
          mutation={FollowMutation}
          update={(cache) => {
            const { profile } = cache.readQuery({
              query: ProfileQuery,
              variables: {
                userId: profileData.profile.id
              }
            });
            cache.writeQuery({
              query: ProfileQuery,
              data: {
                profile: Object.assign({}, profile, {
                  haveFollowed: true,
                  followersCount: profile.followersCount + 1
                })
              }
            });
          }}
        >
          {(follow) => (
            <div
              onClick={async () => {
                try {
                  await follow({ variables: { userId: profileData.profile.id } });
                }
                catch (error) {
                  console.error(error);
                }
              }}
              className={styles.followButton}
            >
              Follow
            </div>
          )}
        </Mutation>
      );
    }
    */
  }

  renderTabs(isOwner, isOrg) {
    if (!isOwner && !isOrg) {
      return (
        <div className={styles.tabRow}>
          <div className={styles.innerTabs}>
            <div
              className={this.state.activeTab === 1 ? styles.activeTab : styles.tab}
              onClick={() => this.toggleTab(1)}
            >
              Added
            </div>
            <div
              className={this.state.activeTab === 0 ? styles.activeTab : styles.tab}
              onClick={() => this.toggleTab(0)}
            >
              Feed
            </div>
          </div>
        </div>
      )
    }
    return null;
  }

  showNotifications(match) {
    return (
      <Query
        query={NotificationsQuery}
        variables={{
          userId: this.props.pageUserId,
          pagination: {
            offset: 0,
            limit: 10
          }
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, fetchMore }) => {
          if (error) return <ErrorMsg error={error} />;

          const { notifications } = data;
          if (!notifications) return <Loading />;
          else if (notifications.results.length === 0) return <Empty page="notifications" />

          const loadMore = () => {
            fetchMore({
              variables: {
                pagination: {
                  offset: notifications ? notifications.results.length : 0,
                  limit: 10
                }
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const newRes = Object.assign({}, prev, {
                  notifications: {
                    total: prev.notifications.total,
                    results: [...prev.notifications.results, ...fetchMoreResult.notifications.results],
                    unread: 0,
                    __typename: 'EventList'
                  }
                });
                return newRes;
              }
            });
          };

          return (
            <InfiniteScroll
              loading={loading}
              getParent={() => this.parent}
              hasMore={notifications ? (notifications.results.length < notifications.total) : false}
              loadMore={loadMore}
              match={match}
            >
              <div className={styles.notificationsContain}>
                <p className={styles.sectionHeader}>
                  Your Notifications {data.notifications.unread
                    ? <span>({data.notifications.unread} unread)</span>
                    : '(0 unread)'
                  }
                </p>
                {notifications.results.map((notification, index) => (
                  <Notification key={index} item={notification} />
                ))}
              </div>
            </InfiniteScroll>
          );
        }}
      </Query>
    );
  }

  showEvents(match, isOrg) {
    return (
      <div className={styles.calContain}>
        <Calendar
          ref={(el) => this.calendar = el}
          onChange={this.handleDateChange}
          viewDate={this.state.activeDate}
          selectedDate={undefined}
          eventDates={fakeCalendarDates()}
        />
      </div>
    );

    /*
    if (!this.state.activeTab) {
      return (
        <Query
          query={this.user ? FeedQuery : PublicFeed}
          variables={{
            userId: this.props.pageUserId,
            pagination: {
              offset: 0,
              limit: 10
            }
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data, fetchMore }) => {
            if (error) return <ErrorMsg error={error} />;

            const { feed } = data;
            if (!feed) return <Loading />;
            else if (feed.results.length === 0 && !isOrg) return <Empty page="events" />

            const loadMore = () => {
              fetchMore({
                variables: {
                  pagination: {
                    offset: feed ? feed.results.length : 0,
                    limit: 10
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  const newRes = Object.assign({}, prev, {
                    feed: {
                      total: prev.feed.total,
                      results: [...prev.feed.results, ...fetchMoreResult.feed.results],
                      __typename: 'EventList'
                    }
                  });
                  return newRes;
                }
              });
            };

            return (
              <InfiniteScroll
                loading={loading}
                getParent={() => this.parent}
                hasMore={feed ? (feed.results.length < feed.total) : false}
                loadMore={loadMore}
                match={match}
              >
                <div className={styles.eventsContain}>
                  {feed.results.map((event, i) => (
                    <EventItem
                      key={i}
                      event={event}
                      showAddToCal={this.props.showAddToCal}
                      hideAddToCal={this.props.hideAddToCal}
                      toggleSignUp={this.props.showSignUp}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            );
          }}
        </Query>
      );
    }
    else {
      return (
        <div className={styles.homeContain}>
          <Query
            query={EventDatesQuery}
            variables={{
              userId: this.props.pageUserId,
              dateRange: {
                timestampStart: new Date(dateFns.format(dateFns.subYears(new Date(), 1), 'MM/DD/YYYY')),
                timestampEnd: new Date(dateFns.format(dateFns.addYears(new Date(), 5), 'MM/DD/YYYY'))
              }
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return (
                <div className={styles.calContain}>
                  <Calendar loading />
                </div>
              );
              if (error) return <ErrorMsg error={error} />;

              return (
                <div className={styles.calContain}>
                  <Calendar
                    ref={(el) => this.calendar = el}
                    onChange={this.handleDateChange}
                    viewDate={this.state.activeDate}
                    selectedDate={undefined}
                    eventDates={data.eventDates}
                  />
                </div>
              )
            }}
          </Query>
          <Query
            query={this.user ? EventsQuery : PublicEvents}
            variables={{
              userId: this.props.pageUserId,
              pagination: {
                offset: 0,
                limit: 10
              },
              dateRange: this.state.dateRange
            }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data, fetchMore }) => {
              if (error) return <ErrorMsg error={error} />;

              const { events } = data;
              if (!events) return <Loading />;
              else if (events.results.length === 0 && !isOrg) return <div className={styles.eventsList}><Empty page="events" /></div>

              const loadMore = () => {
                fetchMore({
                  variables: {
                    pagination: {
                      offset: events ? events.results.length : 0,
                      limit: 10
                    }
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const newRes = Object.assign({}, prev, {
                      events: {
                        total: prev.events.total,
                        results: [...prev.events.results, ...fetchMoreResult.events.results],
                        __typename: 'EventList'
                      }
                    });
                    return newRes;
                  }
                });
              };

              return (
                <div className={styles.eventsList}>
                  <InfiniteScroll
                    loading={loading}
                    getParent={() => this.parent}
                    hasMore={events ? (events.results.length < events.total) : false}
                    loadMore={loadMore}
                    match={match}
                  >
                    {events.results.map((event, i) => (
                      <EventItem
                        key={i}
                        event={event}
                        showAddToCal={this.props.showAddToCal}
                        hideAddToCal={this.props.hideAddToCal}
                        toggleSignUp={this.props.showSignUp}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              );
            }}
          </Query>
        </div>
      );
    }
    */
  }

  formatUrl = ({ url }) => {
    const matches = url.match(/\.[^.]*((\.com)|(\.edu)|(\.net)|(\.org))/);
    let matched = matches ? matches[0] : null;
    return matched ? matched.slice(1) : null;
  }

  render() {
    const { match, history } = this.props;

    const profile = this.user;
    const isOrg = () => false;
    const isOwner = () => false;

    return (
      <div className={styles.contain} ref={(ref) => this.parent = ref}>
        <div className={styles.outerHeader}>
          <div className={styles.header}>
            <div className={styles.infoCol}>
              <h3 className={styles.name}>{profile.name}</h3>
              {(!isOrg || profile.address) &&
                <p className={styles.location}>{profile.address ? profile.address : 'Some Place'}</p>
              }
              {(!isOrg || profile.bio) &&
                <p className={styles.about}>{profile.bio ? profile.bio : 'Some really cool and awesome bio.'}</p>
              }
              {profile.urls && profile.urls.length
                ? <a
                    className={styles.url}
                    href={profile.urls[0].url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.formatUrl(profile.urls[0])}
                  </a>
                : null
              }
              <div className={styles.statsRow}>
                <div
                  onClick={() => {
                    if (isOwner) {
                      history.push('/Home');
                    }
                    else {
                      this.toggleTab(1);
                    }
                  }}
                  className={styles.statItem}
                >
                  <h2 className={styles.stat}>{profile.upcomingEventsCount}</h2>
                  <p className={styles.statLabel}>Added</p>
                </div>
                <div
                  className={styles.statItem}
                  onClick={() => {
                    if (this.user) {
                      history.push(`/Following/${this.props.pageUserId}`)
                    }
                    else {
                      this.props.showSignUp && this.props.showSignUp(
                        null,
                        'following',
                        `viewing who ${profile.name} is`
                      )
                    }
                  }}
                >
                  <h2 className={styles.stat}>{profile.followingCount}</h2>
                  <p className={styles.statLabel}>Following</p>
                </div>
                <div
                  className={styles.statItem}
                  onClick={() => {
                    if (this.user) {
                      history.push(`/Followers/${this.props.pageUserId}`)
                    }
                    else {
                      this.props.showSignUp && this.props.showSignUp(
                        null,
                        'followers',
                        `viewing ${profile.name}'s`
                      )
                    }
                  }}
                >
                  <h2 className={styles.stat}>{profile.followersCount}</h2>
                  <p className={styles.statLabel}>Followers</p>
                </div>
              </div>
            </div>
            <div className={styles.profileCol}>
              <img
                src={profile.profileImage
                  ? profile.profileImage
                  : 'https://appearix.com/static/media/defaultProfile.png'
                }
                alt=""
                className={styles.profile}
              />
              {this.followButton({ profile })}
              {!this.user &&
                <div
                  className={styles.tooltip}
                  style={this.state.showTooltip
                    ? { opacity: 1 }
                    : { opacity: 0, visibility: 'hidden' }
                  }
                  onClick={() => this.setState({ showTooltip: false })}
                >
                  Click <strong>"Follow"</strong> to see their events in your feed
                  <br />
                  <span>tap to hide</span>
                </div>
              }
            </div>
          </div>
          {this.renderTabs(isOwner, isOrg)}
        </div>
      </div>
    );

    /*
    return (
      <Query
        query={this.user ? ProfileQuery : PublicProfile}
        variables={{
          userId: this.props.pageUserId
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorMsg error={error} />;

          const { profile } = data;
          const isOwner = this.user ? profile.id === this.user.id : false;
          const isOrg = profile.isOrganization;

          return (
            <div className={styles.contain} ref={(ref) => this.parent = ref}>
              <div className={styles.outerHeader}>
                <div className={styles.header}>
                  <div className={styles.infoCol}>
                    <h3 className={styles.name}>{profile.name}</h3>
                    {(!isOrg || profile.address) &&
                      <p className={styles.location}>{profile.address ? profile.address : 'Some Place'}</p>
                    }
                    {(!isOrg || profile.bio) &&
                      <p className={styles.about}>{profile.bio ? profile.bio : 'Some really cool and awesome bio.'}</p>
                    }
                    {profile.urls.length
                      ? <a
                          className={styles.url}
                          href={profile.urls[0].url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.formatUrl(profile.urls[0])}
                        </a>
                      : null
                    }
                    {adminLinkPermission({ isOrg, orgId: profile.id, user: this.user }) &&
                      <div>
                        <Link
                          to={`/CreateOrgEvent/${profile.id}`}
                          className={styles.adminLink}
                        >
                          Admin Create Event
                        </Link>
                        <Link
                          to={`/EditOrg/${profile.id}`}
                          className={styles.adminLink}
                        >
                          Admin Edit Profile
                        </Link>
                       </div>
                    }
                    <div className={styles.statsRow}>
                      <div
                        onClick={() => {
                          if (isOwner) {
                            history.push('/Home');
                          }
                          else {
                            this.toggleTab(1);
                          }
                        }}
                        className={styles.statItem}
                      >
                        <h2 className={styles.stat}>{profile.upcomingEventsCount}</h2>
                        <p className={styles.statLabel}>Added</p>
                      </div>
                      <div
                        className={styles.statItem}
                        onClick={() => {
                          if (this.user) {
                            history.push(`/Following/${this.props.pageUserId}`)
                          }
                          else {
                            this.props.showSignUp && this.props.showSignUp(
                              null,
                              'following',
                              `viewing who ${profile.name} is`
                            )
                          }
                        }}
                      >
                        <h2 className={styles.stat}>{profile.followingCount}</h2>
                        <p className={styles.statLabel}>Following</p>
                      </div>
                      <div
                        className={styles.statItem}
                        onClick={() => {
                          if (this.user) {
                            history.push(`/Followers/${this.props.pageUserId}`)
                          }
                          else {
                            this.props.showSignUp && this.props.showSignUp(
                              null,
                              'followers',
                              `viewing ${profile.name}'s`
                            )
                          }
                        }}
                      >
                        <h2 className={styles.stat}>{profile.followersCount}</h2>
                        <p className={styles.statLabel}>Followers</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.profileCol}>
                    <img
                      src={profile.profileImage
                        ? profile.profileImage
                        : 'https://appearix.com/static/media/defaultProfile.png'
                      }
                      alt=""
                      className={styles.profile}
                    />
                    {this.followButton(data)}
                    {!this.user &&
                      <div
                        className={styles.tooltip}
                        style={this.state.showTooltip
                          ? { opacity: 1 }
                          : { opacity: 0, visibility: 'hidden' }
                        }
                        onClick={() => this.setState({ showTooltip: false })}
                      >
                        Click <strong>"Follow"</strong> to see their events in your feed
                        <br />
                        <span>tap to hide</span>
                      </div>
                    }
                  </div>
                </div>
                {this.renderTabs(isOwner, isOrg)}
              </div>
              {isOwner
                ? <div className={styles.bottomContain}>
                    {this.showNotifications(match)}
                  </div>
                : <div className={styles.bottomContain}>
                    {this.showEvents(match, isOrg)}
                  </div>
              }
            </div>
          );
        }}
      </Query>
    )
    */
  }
}

const profileInfo = gql`
  fragment ProfileInfo on User {
    id
    name
    profileImage
    followingCount
    followersCount
    upcomingEventsCount
    address
    bio
    isOrganization
    urls {
      url
    }
  }
`;

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      ...ProfileInfo
      haveFollowed
    }
  }
  ${profileInfo}
`;

const PublicProfile = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      ...ProfileInfo
    }
  }
  ${profileInfo}
`;

const NotificationsQuery = gql`
  query notifications($pagination: Pagination!) {
    notifications(pagination: $pagination) {
      results {
        id
        type
        message
        actionUser {
          id
          name
          profileImage
        }
        user {
          id
          name
          profileImage
        }
        url
        image
        read
        createdAt
      }
      total
      unread
    }
  }
`;

const eventInfo = gql`
  fragment EventInfo on Event {
    id
    host {
      id
      name
      email
      profileImage
    }
    image
    title
    description
    location {
      lat
      lon
    }
    address
    eventTimestampStart
    eventTimestampEnd
    createdAt
    addedCount
    hideTime
    private
  }
`;

const FeedQuery = gql`
  query feed($userId: ID!, $pagination: Pagination!) {
    feed(userId: $userId, pagination: $pagination) {
      total
      results {
        ...EventInfo
        haveAdded
        topAdded {
          id
          name
          profileImage
        }
      }
    }
  }
  ${eventInfo}
`;

const PublicFeed = gql`
  query feed($userId: ID!, $pagination: Pagination!) {
    feed(userId: $userId, pagination: $pagination) {
      total
      results {
        ...EventInfo
      }
    }
  }
  ${eventInfo}
`;

const EventsQuery = gql`
  query events($userId: ID!, $pagination: Pagination!, $dateRange: DateRange) {
    events(userId: $userId, pagination: $pagination, dateRange: $dateRange) {
      total
      results {
        ...EventInfo
        haveAdded
        topAdded {
          id
          name
          profileImage
        }
      }
    }
  }
  ${eventInfo}
`;

const PublicEvents = gql`
  query events($userId: ID!, $pagination: Pagination!, $dateRange: DateRange) {
    events(userId: $userId, pagination: $pagination, dateRange: $dateRange) {
      total
      results {
        ...EventInfo
      }
    }
  }
  ${eventInfo}
`;

const EventDatesQuery = gql`
  query eventDates($userId: ID!, $dateRange: DateRange!) {
    eventDates(userId: $userId, dateRange: $dateRange)
  }
`;

const FollowMutation = gql`
  mutation follow($userId: ID!) {
    follow(userId: $userId)
  }
`;

const UnfollowMutation = gql`
  mutation unfollow($unfollowUserId: ID!) {
    unfollow(unfollowUserId: $unfollowUserId)
  }
`;

export default withContext(ProfileContent);

import React from 'react';

import styles from './EventSection.module.css';

const EventSection = ({ icon, content, iconStyle = {}, containStyle = {} }) => (
  <div className={styles.contain} style={containStyle}>
    {icon &&
      <div className={styles.icon} style={iconStyle}>
        {icon}
      </div>
    }
    <div className={styles.content} style={icon ? {} : {width: '100%'}}>
      {content}
    </div>
  </div>
);

export default EventSection;

import React from 'react';

import styles from './Search.module.css';
import SearchBoxLight from './SearchBoxLight';
import { getUser } from '../../utils/userState';
import TabBar from '../Common/TabBar';
import SearchResults from './SearchResults';
import Modal from '../Common/Modal';
import SignUpModal from '../Common/SignUpModal';

class Search extends React.Component {
  state = {
    value: '',
    focused: true,
    showSignUp: false,
    signUpInfo: false
  }

  user = getUser()

  onFocus = () => {
    this.setState({ focused: true });
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  onClear = () => {
    this.setState({ value: '' });
  }

  toggleSignUp = (id, title, type) => {
    this.setState({ signUpInfo: { id, title, type }, showSignUp: !this.state.showSignUp });
  }

  hideSignUp = () => {
    this.setState({ showSignUp: false });
  }

  render() {
    const { history, match } = this.props;
    return (
      <div className={styles.contain}>
        <div className={styles.searchBox}>
          <SearchBoxLight
            value={this.state.value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onClear={this.onClear}
            goBack={() => {history.goBack()}}
            autoFocus={true}
          />
        </div>
        <div className={styles.results}>
          <SearchResults
            query={this.state.value}
            history={history}
            toggleSignUp={this.toggleSignUp}
          />
        </div>
        <TabBar match={match} />
        {!this.user &&
          <Modal
            shown={this.state.showSignUp}
            hide={this.hideSignUp}
            containStyle={{ top: 0, bottom: 0 }}
            modalStyle={{ height: 'auto', width: '95%', maxWidth: '400px', borderRadius: 6 }}
            backgroundStyle={{ background: 'rgba(0,0,0,0.7)' }}
          >
            <SignUpModal
              hide={this.hideSignUp}
              history={history}
              id={this.state.signUpInfo.id}
              title={this.state.signUpInfo.title}
              type={this.state.signUpInfo.type}
            />
          </Modal>
        }
      </div>
    )
  }
}

export default Search;

import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import dateFns from 'date-fns';

import styles from './Home.module.css';
import TabBar from '../Common/TabBar';
import Header from '../Common/Header';
import EventItem from '../Common/EventItem';
import Calendar from '../Common/Calendar';
import Loading from '../Common/Loading';
import AddToCal from '../Common/AddToCal';
import InfiniteScroll from '../Common/InfiniteScroll';
import Button from '../Common/Button';
import ErrorMsg from '../Common/ErrorMsg';
import getTimezone from '../../utils/getTimezone';
import { getUser } from '../../utils/userState';
import { fakeEvents, fakeCalendarDates } from '../../utils/fakeData';

const EventsQuery = gql`
  query events($userId: ID!, $pagination: Pagination!, $dateRange: DateRange, $timezone: String) {
    events(userId: $userId, pagination: $pagination, dateRange: $dateRange, timezone: $timezone) {
      total
      results {
        id
        host {
          id
          name
          email
          profileImage
        }
        title
        image
        description
        location {
          lat
          lon
        }
        address
        eventTimestampStart
        eventTimestampEnd
        createdAt
        haveAdded
        addedCount
        topAdded {
          id
          name
          profileImage
        }
        hideTime
        private
      }
    }
  }
`;

const EventDatesQuery = gql`
  query eventDates($userId: ID!, $dateRange: DateRange!) {
    eventDates(userId: $userId, dateRange: $dateRange)
  }
`;

const events = {
  total: 5,
  results: [
    {
      id: '123',
      host: {
        id: '456',
        name: 'Brian Callery',
        email: 'brian@gmail.com',
        profileImage: ''
      },
      title: 'Landings at SQL',
      image: '',
      description: 'Fun landings with the boys at San Carlos Airport',
      location: {
        lat: '',
        lon: ''
      },
      address: '1800 Wallaby Drive, San Carlos CA 94070',
      eventTimestampStart: new Date(),
      eventTimestampEnd: new Date(),
      createdAt: new Date(),
      haveAdded: false,
      addedCount: 5,
      topAdded: {
        id: '232',
        name: 'Rebecca Smith',
        profileImage: ''
      },
      hideTime: false,
      private: false
    }
  ]
};

class Home extends React.Component {
  state = {
    activeDate: undefined,
    title: 'Added',
    dateRange: undefined,
    showAddToCal: false,
    eventToAdd: {},
    shareType: 'event'
  }

  user = getUser();

  handleDateChange = (val) => {
    let newState = {};

    if (val === this.state.activeDate || val === undefined) {
      newState.activeDate = undefined;
      newState.title = 'Added';
      newState.dateRange = undefined;
    }
    else {
      newState.activeDate = val;
      newState.title = dateFns.format(val, 'dddd, MMMM D');
      newState.dateRange = {
        timestampStart: val,
        timestampEnd: dateFns.endOfDay(val)
      };
    }

    this.setState(newState);
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({ showAddToCal: true, eventToAdd: eventInfo, shareType: type });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  render() {
    const { match, history } = this.props;
    const timezone = getTimezone();

    return (
       <div>
        <Header
          match={match}
          navTitle={this.state.title}
          backButton
          pageUserId={false}
          backFunc={() => {
            if (this.state.activeDate) {
              this.calendar.onDateClick(undefined)
            }
            else {
              history.goBack();
            }
          }}
          share={this.showAddToCal}
        />
        <div className="body">
          <div className={styles.eventsContain} ref={(ref) => this.parent = ref}>
            <div className={styles.homeContain}>
              <div className={styles.calContain}>
                <Calendar
                  ref={(el) => this.calendar = el}
                  onChange={this.handleDateChange}
                  viewDate={this.state.activeDate}
                  selectedDate={undefined}
                  eventDates={fakeCalendarDates()}
                />
              </div>
              {fakeEvents().results.map((eventRecord, index) => (
                <EventItem
                  event={eventRecord}
                  showAddToCal={this.showAddToCal}
                  hideAddToCal={this.hideAddToCal}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
        <TabBar match={match} />
      </div>
    );

    /*
    return (
      <div>
        <Header
          match={match}
          navTitle={this.state.title}
          backButton
          backFunc={() => {
            if (this.state.activeDate) {
              this.calendar.onDateClick(undefined)
            }
            else {
              history.goBack();
            }
          }}
          share={this.showAddToCal}
          pageUserId={this.user.id}
        />
        <div className="body">
          <div className={styles.eventsContain} ref={(ref) => this.parent = ref}>
            <div className={styles.homeContain}>
              <Query
                query={EventDatesQuery}
                variables={{
                  userId: this.user.id,
                  dateRange: {
                    timestampStart: new Date(dateFns.format(dateFns.subYears(new Date(), 1), 'MM/DD/YYYY')),
                    timestampEnd: new Date(dateFns.format(dateFns.addYears(new Date(), 5), 'MM/DD/YYYY'))
                  }
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data }) => {
                  if (loading) return (
                    <div className={styles.calContain}>
                      <Calendar loading />
                    </div>
                  );
                  if (error) return <ErrorMsg error={error} />;

                  return (
                    <div className={styles.calContain}>
                      <Calendar
                        ref={(el) => this.calendar = el}
                        onChange={this.handleDateChange}
                        viewDate={this.state.activeDate}
                        selectedDate={undefined}
                        eventDates={data.eventDates}
                      />
                    </div>
                  )
                }}
              </Query>
              <Query
                query={EventsQuery}
                variables={{
                  userId: this.user.id,
                  pagination: {
                    offset: 0,
                    limit: 10
                  },
                  dateRange: this.state.dateRange,
                  timezone
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data, fetchMore }) => {
                  if (error) return null;

                  let { events } = data;
                  if (!events) return <Loading />;
                  else if (events.results.length === 0) return (
                    <div className={styles.eventsList}>
                      <Button
                        label="Create Event"
                        onClick={() => history.push({
                          pathname: '/CreateEvent',
                          state: {
                            startDate: this.state.activeDate,
                            endDate: dateFns.addHours(this.state.activeDate, 1)
                          }
                        })}
                      />
                    </div>
                  );

                  const loadMore = () => {
                    fetchMore({
                      variables: {
                        pagination: {
                          offset: events ? events.results.length : 0,
                          limit: 10
                        }
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const newRes = Object.assign({}, prev, {
                          events: {
                            total: prev.events.total,
                            results: [...prev.events.results, ...fetchMoreResult.events.results],
                            __typename: 'EventList'
                          }
                        });
                        return newRes;
                      }
                    });
                  };

                  return (
                    <div className={styles.eventsList}>
                      <InfiniteScroll
                        loading={loading}
                        getParent={() => this.parent}
                        hasMore={events ? (events.results.length < events.total) : false}
                        loadMore={loadMore}
                        match={match}
                      >
                        {events && events.results.map((event, i) => (
                          <EventItem
                            event={event}
                            showAddToCal={this.showAddToCal}
                            hideAddToCal={this.hideAddToCal}
                            key={i}
                          />
                        ))}
                      </InfiniteScroll>
                    </div>
                  )
                }}
              </Query>
            </div>
          </div>
        </div>
        <TabBar match={match} />
        <AddToCal
          shown={this.state.showAddToCal}
          event={this.state.eventToAdd}
          hide={this.hideAddToCal}
          type={this.state.shareType}
        />
      </div>
    )
    */
  }
}

export default Home;

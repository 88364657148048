import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAdmin, hasExternalAccounts } from '../../utils/userState';

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    (isAdmin() || hasExternalAccounts()) ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }}/>
    )
  )}/>
);

export default AdminRoute;

import React from 'react';
import { Link } from 'react-router-dom';
import { ApolloConsumer, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  MdEmail, 
  MdSecurity, 
  MdAssignment, 
  MdWarning, 
  MdDeleteForever,
  MdInfo,
  MdAccountCircle,
  MdBuild,
  MdPeople
} from 'react-icons/md';
import { FaLaptopCode } from 'react-icons/fa';

import { getUser, signOut, USER_KEY } from '../../utils/userState';
import generateProfileEmbed from '../../utils/generateProfileEmbed';
import withContext from '../Context/withContext';
import styles from './Settings.module.css';
import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import Modal from '../Common/Modal';
import Button from '../Common/Button';
import AddToCal from '../Common/AddToCal';
import copyToClipboard from '../../utils/copyToClipboard';

async function signOutUtil(firebase, history, client) {
  try {
    await firebase.auth().signOut();
    signOut();
    client.resetStore();
    history.push('/');
  }
  catch (error) {
    console.error(error);
  }
}

class Settings extends React.Component {
  state = {
    deleteUser: false,
    confirmed: false,
    showShare: false,
    shareInfo: {},
    copiedEmbed: false
  }

  deleteUserUtil = async (firebase, history, client, user, deleteUser) => {
    try {
      const fireUser = firebase.auth().currentUser;
      await fireUser.delete();

      deleteUser(user.id);
      localStorage.setItem(USER_KEY, 'false');
      client.resetStore();
      history.push('/');
    }
    catch (e) {
      this.toggleConfirm();
    }
  }

  toggleConfirm = () => {
    this.setState({
      confirmed: !this.state.confirmed
    });
  }

  showShare = (info = {}) => {
    this.setState({
      showShare: true,
      shareInfo: info
    });
  }

  hideShare = () => {
    this.setState({ 
      showShare: false 
    });
  }

  render() {
    const { context: { getFirebase }, match, history } = this.props;
    const user = getUser();
    const firebase = getFirebase();

    return (
      <ApolloConsumer>
        {(client) => (
          <div>
            <Header
              match={match}
              history={history}
              navTitle="Settings"
              share={this.showShare}
              backButton
              pageUserId={user.id}
            />
            <div className="body">
              <div className={styles.contain}>
                <div
                  className={styles.topContain}
                  onClick={() => history.push('/Groups')}
                >
                  <div className={styles.inner}>
                    <div className={styles.buttonIcon}>
                      <MdPeople size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      My Groups
                    </p>
                  </div>
                </div>
                <div className={styles.buttonContain}>
                  <div className={styles.inner}>
                    <div className={styles.buttonIcon}>
                      <MdEmail size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      Email notifications 
                    </p>
                    <span className={styles.emailToggle}>
                      Coming Soon
                    </span>
                  </div>
                </div> 
                <div
                  className={styles.buttonContain}
                  onClick={() => {
                    copyToClipboard({
                      window,
                      document,
                      content: generateProfileEmbed({ 
                        userId: user.id 
                      }),
                      callback: () => this.setState({
                        copiedEmbed: true
                      })
                    });
                  }}
                >
                  <div className={styles.inner}>
                    <div className={styles.buttonIcon}>
                      <FaLaptopCode size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      Embed Profile
                    </p>
                    {this.state.copiedEmbed &&
                      <span className={styles.copiedEmbed}>
                        Copied to Clipboard
                      </span>
                    }
                  </div>
                </div>
                {user.admin &&
                  <div
                    className={styles.buttonContain}
                    onClick={() => history.push('/Admin')}
                  >
                    <div className={styles.inner}>
                      <div className={styles.buttonIcon}>
                        <MdBuild size={20} />
                      </div>
                      <p className={styles.buttonLabel}>
                        Admin
                      </p>
                    </div>
                  </div>
                }
                <div
                  className={styles.buttonContain}
                  onClick={() => signOutUtil(firebase, history, client)}
                >
                  <div className={styles.inner}>
                    <div className={styles.buttonIcon}>
                      <MdAccountCircle size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      Sign out
                    </p>
                  </div>
                </div>
                <Link
                  to={`/About`}
                  className={styles.topContain}
                >
                  <div className={styles.inner}>
                    <div className={styles.aboutIcon}>
                      <MdInfo size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      About us
                    </p>
                  </div>
                </Link>
                <Link
                  to={`/Privacy`}
                  className={styles.buttonContain}
                >
                  <div className={styles.inner}>
                    <div className={styles.aboutIcon}>
                      <MdSecurity size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      Privacy policy
                    </p>
                  </div>
                </Link>
                <Link
                  to={`/TOS`}
                  className={styles.buttonContain}
                >
                  <div className={styles.inner}>
                    <div className={styles.aboutIcon}>
                      <MdAssignment size={20} />
                    </div>
                    <p className={styles.buttonLabel}>
                      Terms of service
                    </p>
                  </div>
                </Link>
                {this.state.deleteUser
                  ? <Mutation mutation={DeleteUserMutation}>
                      {(deleteUser) => (
                        <div
                          className={styles.topContain}
                          onClick={() => this.deleteUserUtil(firebase, history, client, user, deleteUser)}
                        >
                          <div className={styles.inner}>
                            <div className={styles.buttonIcon}>
                              <MdWarning color="var(--secondary)" size={20} />
                            </div>
                            <p
                              className={styles.buttonLabel}
                              style={{ color: 'var(--secondary)' }}
                            >
                              ARE YOU SURE?
                            </p>
                          </div>
                        </div>
                      )}
                    </Mutation>
                  : <div
                      className={styles.topContain}
                      onClick={() => this.setState({ deleteUser: true })}
                    >
                      <div className={styles.inner}>
                        <div className={styles.deleteIcon}>
                          <MdDeleteForever size={20} />
                        </div>
                        <p className={styles.deleteLabel}>
                          Delete account
                        </p>
                      </div>
                    </div>
                }
              </div>
            </div>
            <TabBar match={match} />
            <Modal shown={this.state.confirmed}>
              <div className={styles.confirmation}>
                <h2>This operation is sensitive and requires recent authentication. Log in again before retrying this request.</h2>
                <Button
                  label="Sign Out"
                  onClick={() => signOutUtil(firebase, history, client)}
                />
                <Button
                  label="Cancel"
                  onClick={this.toggleConfirm}
                  light
                />
              </div>
            </Modal>
            <AddToCal
              shown={this.state.showShare}
              event={this.state.shareInfo}
              hide={this.hideShare}
              type="profile"
            />
          </div>
        )}
      </ApolloConsumer>
    );
  }
};

const DeleteUserMutation = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export default withContext(Settings);

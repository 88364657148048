import React from 'react';

import PersonItem from '../Common/PersonItem';

const Connections = ({
  items = [],
  toggleSignUp = false
}) => (
  items.map((item, i) => (
    <PersonItem
      person={item}
      toggleSignUp={toggleSignUp}
      key={i}
    />
  ))
);

export default Connections;

function pad(num) {
  const norm = Math.floor(Math.abs(num));
  return (norm < 10 ? '0' : '') + norm;
}

function getTimezone() {
  const now = new Date();
  const tzo = now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  return `${dif}${pad(tzo/60)}:${pad(tzo % 60)}`;
}

export default getTimezone;
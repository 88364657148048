import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { getUser } from '../../utils/userState';
// import styles from './Connections.module.css';
import TabBar from '../Common/TabBar';
import Header from '../Common/Header';
import PersonItem from '../Common/PersonItem';
import Loading from '../Common/Loading';
import Empty from '../Common/Empty';
import AddToCal from '../Common/AddToCal';
import InfiniteScroll from '../Common/InfiniteScroll';

const FollowersQuery = gql`
  query followers($userId: ID!, $pagination: Pagination!) {
    followers(userId: $userId, pagination: $pagination) {
      total
      results {
        id
        name
        profileImage
        haveFollowed
        upcomingEventsCount
        followersCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
        address
      }
    }
  }
`;

const FollowingQuery = gql`
  query following($userId: ID!, $pagination: Pagination!) {
    following(userId: $userId, pagination: $pagination) {
      total
      results {
        id
        name
        profileImage
        haveFollowed
        upcomingEventsCount
        followersCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
        address
      }
    }
  }
`;

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      id
      name
    }
  }
`;

class Connections extends React.Component {
  state = {
    activeIndex: 0,
    showAddToCal: false,
    eventToAdd: {},
    shareType: 'profile'
  }

  user = getUser();

  componentDidMount = () => {
    const { location } = this.props;
    if (location.state) {
      this.setState({ activeIndex: location.state.activeIndex || 0 });
    };
  }

  handleSwitch = (index, type) => {
    if (type === 'end') {
      this.setState({ activeIndex: index })
    }
  }

  showAddToCal = (eventInfo = {}, type = 'profile') => {
    this.setState({ showAddToCal: true, eventToAdd: eventInfo, shareType: type });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  render() {
    const { match, history } = this.props;
    const isFollowing = match.path === '/Following/:id';
    return (
      <div>
        <Header
          match={match}
          history={history}
          navTitle={
            <Query
              query={ProfileQuery}
              fetchPolicy="cache-first"
              variables={{
                userId: match.params.id
              }}
            >
              {({ loading, error, data }) => {
                const page = isFollowing ? 'Following' : 'Followers';
                if (loading) return page;
                if (error) return `${page} Error`;

                return `${data.profile.name}: ${isFollowing ? 'Following' : 'Followers'}`
              }}
            </Query>
          }
          backButton
          share={this.showAddToCal}
        />
        <div className="body">
          <div ref={(ref) => this.parent = ref}>
            {isFollowing
              ? <Query
                  query={FollowingQuery}
                  variables={{
                    userId: match.params.id,
                    pagination: {
                      offset: 0,
                      limit: 10
                    }
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ loading, error, data, fetchMore }) => {
                    if (error) return `Error! ${error.message}`;

                    const { following } = data;
                    if (!following) return <Loading />;
                    else if (following.results.length === 0) return <Empty page="following" />

                    const loadMore = () => {
                      fetchMore({
                        variables: {
                          pagination: {
                            offset: following ? following.results.length : 0,
                            limit: 10
                          }
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev;
                          const newRes = Object.assign({}, prev, {
                            following: {
                              total: prev.following.total,
                              results: [...prev.following.results, ...fetchMoreResult.following.results],
                              __typename: 'EventList'
                            }
                          });
                          return newRes;
                        }
                      });
                    };

                    return (
                      <InfiniteScroll
                        loading={loading}
                        getParent={() => this.parent}
                        hasMore={following ? (following.results.length < following.total) : false}
                        loadMore={loadMore}
                        match={match}
                      >
                        {following.results.map((person, i) => (
                          <PersonItem person={person} key={i} />
                        ))}
                      </InfiniteScroll>
                    );
                  }}
                </Query>
              : <Query
                  query={FollowersQuery}
                  variables={{
                    userId: match.params.id,
                    pagination: {
                      offset: 0,
                      limit: 10
                    }
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ loading, error, data, fetchMore }) => {
                    if (error) return `Error! ${error.message}`;

                    const { followers } = data;
                    if (!followers) return <Loading />;
                    else if (followers.results.length === 0) return <Empty page="followers" />

                    const loadMore = () => {
                      fetchMore({
                        variables: {
                          pagination: {
                            offset: followers ? followers.results.length : 0,
                            limit: 10
                          }
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev;
                          const newRes = Object.assign({}, prev, {
                            followers: {
                              total: prev.followers.total,
                              results: [...prev.followers.results, ...fetchMoreResult.followers.results],
                              __typename: 'EventList'
                            }
                          });
                          console.log(newRes)
                          return newRes;
                        }
                      });
                    };

                    return (
                      <InfiniteScroll
                        loading={loading}
                        getParent={() => this.parent}
                        hasMore={followers ? (followers.results.length < followers.total) : false}
                        loadMore={loadMore}
                        match={match}
                      >
                        {followers.results.map((person, i) => (
                          <PersonItem person={person} key={i} />
                        ))}
                      </InfiniteScroll>
                    );
                  }}
                </Query>
            }
          </div>
        </div>
        <AddToCal
          shown={this.state.showAddToCal}
          event={this.state.eventToAdd}
          hide={this.hideAddToCal}
          type={this.state.shareType}
        />
        <TabBar match={match} />
      </div>
    )
  }
}

export default Connections;

import React from 'react';
import { Router, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import AuthRoute from '../Common/AuthRoute';
import AdminRoute from '../Common/AdminRoute';
import AdminAndExternalAccountRoute from '../Common/AdminAndExternalAccountRoute';
import './App.css';
import Home from '../Home/Home';
import Events from '../Events/Events';
import Profile from '../Profile/Profile';
import EventForm from '../CreateEvent/EventForm';
import EventPage from '../EventPage/EventPage';
import SignIn from '../Auth/SignIn';
import Landing from '../Landing/Landing';
import Discover from '../Discover/Discover';
import Settings from '../Settings/Settings';
import EditProfile from '../EditProfile/EditProfile';
import CreateOrg from '../Admin/CreateOrg';
import CreateOrgEvent from '../Admin/CreateOrgEvent';
import EditOrg from '../Admin/EditOrg';
import EditOrgEvent from '../Admin/EditOrgEvent';
import Admin from '../Admin/Admin';
import Search from '../Search/Search';
import Loading from '../Common/Loading';
import About from '../About/About';
import Privacy from '../About/Privacy';
import TOS from '../About/TOS';
import Connections from '../Connections/Connections';
import Added from '../Connections/Added';
import Groups from '../Groups/Groups';
import CreateGroup from '../Groups/CreateGroup';
import EditGroup from '../Groups/EditGroup';
import Unsubscribe from '../Unsubscribe/Unsubscribe';
import { GOOGLE_ANALYTICS_ID } from '../../utils/constants';
import AddExternalAccount from '../Admin/AddExternalAccount';

const history = createBrowserHistory();

history.listen((location) => {
  window.gtag('config', GOOGLE_ANALYTICS_ID, {
    'page_path': location.pathname,
  });
});

/*
    <AuthRoute path="/Home" component={Home} />
    <AuthRoute path="/Discover" component={Discover} />
    <AuthRoute path="/Events" component={Events} />
    <Route path="/" exact component={Landing} />
*/

const App = () => (
  <Router history={history}>
    <div>
      <Route path="/" exact component={Home} />
      <Route path="/Home" component={Home} />
      <Route path="/Events" component={Events} />
      <Route path="/Discover" component={Discover} />
      <Route path="/Preset/:id" component={Landing} />
      <Route path="/SignIn" component={SignIn} />
      <Route path="/Event/:id" component={EventPage} />
      <Route path="/Profile/:id" component={Profile} />
      <Route path="/Added/:id" component={Added} />
      <Route path="/Search" component={Search} />
      <Route path="/Loading" component={Loading} />
      <Route path="/About" component={About} />
      <Route path="/Privacy" component={Privacy} />
      <Route path="/TOS" component={TOS} />
      <Route path="/Unsubscribe/:email" component={Unsubscribe} />
      <AuthRoute path="/Followers/:id" component={Connections} />
      <AuthRoute path="/Following/:id" component={Connections} />
      <AuthRoute path="/EditProfile" component={EditProfile} />
      <AuthRoute path="/Settings" component={Settings} />
      <AuthRoute path="/CreateEvent" component={EventForm} />
      <AuthRoute path="/EditEvent/:id" component={EventForm} />
      <AdminAndExternalAccountRoute path="/CreateOrg" component={CreateOrg} />
      <AdminAndExternalAccountRoute path="/CreateOrgEvent/:id" component={CreateOrgEvent} />
      <AdminAndExternalAccountRoute path="/EditOrg/:id" component={EditOrg} />
      <AdminAndExternalAccountRoute path="/EditOrgEvent/:id" component={EditOrgEvent} />
      <AdminRoute path="/Admin" component={Admin} />
      <AuthRoute path="/Groups" component={Groups} />
      <AuthRoute path="/CreateGroup" component={CreateGroup} />
      <AuthRoute path="/EditGroup/:id" component={EditGroup} />
      <AdminRoute path="/AddExternalAccount" component={AddExternalAccount} />
    </div>
  </Router>
);

export default App;

import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

import styles from './Overlay.module.css';
import { getUser } from '../../utils/userState';

class Overlay extends React.Component {
  state = {
    step: 1
  }

  user = getUser()

  nextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }

  hide = () => {
    const { hideOverlay, history } = this.props;

    hideOverlay();
    history.push('/Discover');
  }

  render() {
    return (
      <div className={styles.overlay}>
        <div className={styles.inner}>
          <div
            className={styles.step1}
            style={{opacity: this.state.step === 1 ? 1 : 0 }}
          >
            <p className={styles.step1label}>A feed of fun events</p>
          </div>
          <div
            className={styles.step2}
            style={{opacity: this.state.step === 2 ? 1 : 0 }}
          >
            <p className={styles.step2label}>Find people to follow</p>
          </div>
          <div
            className={styles.step3}
            style={{opacity: this.state.step === 3 ? 1 : 0 }}
          >
            <p className={styles.step3label}>Create events</p>
          </div>
          <div
            className={styles.step4}
            style={{opacity: this.state.step === 4 ? 1 : 0 }}
          >
            <p className={styles.step4label}>Events you've added</p>
          </div>
          <div
            className={styles.step5}
            style={{opacity: this.state.step === 5 ? 1 : 0 }}
          >
            <p className={styles.step5label}>Your Profile</p>
          </div>
          <div
            className={styles.step6}
            style={{opacity: this.state.step === 6 ? 1 : 0 }}
          >
            <p className={styles.step6label}>Share with the world</p>
          </div>
          <div className={styles.centerContain}>
            <p className={styles.message}>Take a quick tour of the app.</p>
            {this.state.step === 6
              ? this.user.name === 'Hurloon Wrangler'
                ? <div className={styles.buttonRow}>
                    <Link
                      to={{
                        pathname: '/EditProfile',
                        state: { newUser: true }
                      }}
                      className={styles.next}
                    >
                      Edit Profile
                      <MdArrowForward size={16} style={{marginLeft: 6}} />
                    </Link>
                    <div
                      onClick={this.hide}
                      className={styles.close}
                    >
                      Skip
                    </div>
                  </div>
                : <div
                    onClick={this.hide}
                    className={styles.next}
                  >
                    Finish
                    <MdArrowForward size={16} style={{marginLeft: 6}} />
                  </div>
              :  <div className={styles.buttonRow}>
                  <div
                    onClick={this.nextStep}
                    className={styles.next}
                  >
                    Next
                    <MdArrowForward size={16} style={{marginLeft: 6}} />
                  </div>
                  <div
                    onClick={this.hide}
                    className={styles.close}
                  >
                    Close
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Overlay;

import React from 'react';
import { MdHelp } from 'react-icons/md';

import styles from './SourcePrompt.module.css';

const SourcePrompt = () => (
  <div className={styles.contain}>
    <MdHelp size={50} color="var(--main)" />
    <p className={styles.message}>
      We couldn't find anything for that.
    </p>
    <p className={styles.prompt}>
      Tell us what you're looking for at:
    </p>
    <p className={styles.email}>
      info@appearix.com
    </p>
  </div>
);

export default SourcePrompt;

import React from 'react';
import {
  MdInfoOutline,
  MdLocationOn,
  MdClear,
  MdAccessTime
} from 'react-icons/md';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gql from 'graphql-tag';
import { graphql, compose, ApolloConsumer } from 'react-apollo';
import dateFns from 'date-fns';
import isUrl from 'is-url';

import styles from './EventForm.module.css';
import Button from '../Common/Button';
import TabBar from '../Common/TabBar';
import Header from '../Common/Header';
import ImageInput from '../Common/ImageInput';
import PingInput from '../Pings/PingInput';
import { getUser } from '../../utils/userState';
import AddToCal from '../Common/AddToCal';
import ErrorMsg from '../Common/ErrorMsg';
import PrivacyToggle from './PrivacyToggle';

const user = getUser();

const roundTo15Minutes = (date) => {
  var roundedMinutes = Math.ceil(dateFns.getMinutes(date) / 15) * 15;
  return dateFns.setMinutes(dateFns.startOfMinute(date), roundedMinutes);
}

class CreateEvent extends React.Component {
  state = {
    editEvent: false,
    image: '',
    showImage: false,
    title: '',
    titleFocus: false,
    startDate: roundTo15Minutes(new Date()),
    endDate: dateFns.addHours(roundTo15Minutes(new Date()), 1),
    description: '',
    descFocus: false,
    location: null,
    address: '',
    addressFocus: false,
    private: false,
    pings: [],
    tagInput: '',
    tags: [],
    errorMsg: '',
    urls: [],
    loading: false,
    showAddToCal: false,
    eventToAdd: {},
    shareType: 'profile',
  }

  user = getUser();

  componentDidMount = () => {
    const { location } = this.props;
    if (location.state) {
      let newState = {};
      Object.keys(location.state).map((item) => newState[item] = location.state[item]);
      if (!newState.endDate) newState.endDate = dateFns.addHours(newState.startDate, 1);
      this.setState(newState);
    };
  }

  onSubmit = async (urls) => {
    try {
      if (this.state.title.trim() === '') throw Error("Please enter an event title.")
      if (this.state.startDate === '') throw Error("Please enter a start date.")

      if (!this.state.editEvent) {
        this.setState({ loading: true });

        const pings = this.state.pings.map(({ user, __typename, ...rest }) => rest);

        // Create Event
        let event = {
          title: this.state.title,
          image: this.state.image,
          description: this.state.description,
          eventTimestampStart: dateFns.parse(this.state.startDate),
          eventTimestampEnd: dateFns.parse(this.state.endDate),
          address: this.state.address,
          location: this.state.location,
          recurring: false,
          tags: [],
          private: this.state.private,
          urls: urls,
          pings
        };

        console.log(event);

        this.props.history.push('/Home');

        /*
        const { data } = await this.props.createEvent({
          variables: {
            event: event
          }
        });

        if (data) {
          this.props.history.push({
            pathname: `/Event/${data.createEvent.id}`,
            state: { created: true }
          });
        }
        */
      }
      else {
        this.setState({ loading: true });

        // Edit event
        let event = {
          id: this.state.editEvent.id,
          title: this.state.title,
          image: this.state.image,
          description: this.state.description,
          eventTimestampStart: dateFns.parse(this.state.startDate).toISOString(),
          eventTimestampEnd: dateFns.parse(this.state.endDate).toISOString(),
          address: this.state.address,
          location: this.state.location,
          private: this.state.private,
          urls: urls
        };

        const diffArr = Object.keys(this.state.editEvent).filter(item => this.state.editEvent[item] !== event[item]);
        const diffObj = {}
        diffArr.map((item, i) => (
          diffObj[item] = event[item]
        ))

        let data;
        if (diffObj !== {}) {
          data = await this.props.editEvent({
            variables: {
              eventId: event.id,
              event: diffObj
            }
          });
        }

        if (data || diffObj === {}) {
          this.props.history.push(`/Event/${this.state.editEvent.id}`);
        }
      }
    }
    catch (error) {
      this.setState({ errorMsg: error.message, loading: false });
    }
  }

  handleChange = (e, state) => {
    const val = state === 'address' ? e : e.target.value;
    if (state === 'startDate') this.setState({
      [state]: val,
      endDate: dateFns.addHours(roundTo15Minutes(val), 1)
    });
    else this.setState({ [state]: val });
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const res = results[0];
        this.setState({ address: res.formatted_address });
        return res;
      })
      .then(re => getLatLng(re))
      .then(({ lat, lng }) => {
        this.setState({ location: { lat: lat.toString(), lon: lng.toString() }})
      })
      .catch(error => console.error('Error', error));
  };

  handleFocus = (name) => {
    this.setState({ [name]: true });
  }

  handleBlur = (name) => {
    this.setState({ [name]: false });
  }

  handleClear = (name, ref) => {
    if (ref) ref.focus();
    this.setState({ [name]: '' });
  }

  handleCalChange = (val, name) => {
    let prefix;

    if (name.startsWith('s')) {
      prefix = 'S';
      this.setState({
        [name]: new Date(val),
        [`show${prefix + name.slice(1)}`]: false,
        endDate: val
      });
    }
    else {
      prefix = 'E';
      this.setState({
        [name]: new Date(val),
        [`show${prefix + name.slice(1)}`]: false
      });
    }
  }

  handleTimeChange = (val, name) => {
    this.setState({ [name]: val })
  }

  addGroupPing = (group) => {
    this.setState({
      pings: [
        ...new Set([
          ...this.state.pings,
          ...group.pings
        ]
      )]
    });
  }

  addPing = (ping) => {
    const newPing = [...new Set([...this.state.pings, ping])];
    this.setState({ pings: newPing });
  }

  delPing = (ping) => {
    const newPing = this.state.pings.filter(item => item.value !== ping.value);
    this.setState({ pings: newPing });
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({ showAddToCal: true, eventToAdd: eventInfo, shareType: type });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  focusInput = (ref) => {
    ref.focus();
  }

  render() {
    const { match, history } = this.props;
    return (
      <div>
        <Header
          match={match}
          history={history}
          navTitle={this.state.editEvent ? 'Edit Event' : 'Create Event'}
          backButton
          share={this.showAddToCal}
          pageUserId={this.user.id}
        />
        <div className="body">
          <ApolloConsumer>
            {(client) => (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const strings = this.state.description.split(' ');
                  let urls = [];

                  for (const item of strings) {
                    if (isUrl(item)) {
                      const { data } = await client.query({
                        query: UrlPreviewQuery,
                        variables: { url: item }
                      });

                      if (data) {
                        if (data.urlPreview.__typename) {
                          delete data.urlPreview.__typename;
                        }
                        urls.push(data.urlPreview);
                      }
                    }
                  };

                  this.onSubmit(urls);
                }}
              >
                <div className={styles.section}>
                  <div className={styles.innerSection}>
                    <div className={styles.inputIcon}></div>
                    <div className={styles.inputContain}>
                      <div
                        className={styles.titleClear}
                        style={{opacity: this.state.title !== '' && this.state.titleFocus ? 1 : 0 }}
                        onClick={() => {this.handleClear('title', this.title)}}
                      >
                        <MdClear size={16} />
                      </div>
                      <input
                        ref={el => this.title = el}
                        value={this.state.title}
                        onChange={(e) => {this.handleChange(e, 'title')}}
                        placeholder="Event title"
                        className={styles.titleInput}
                        onFocus={() => {this.handleFocus('titleFocus')}}
                        onBlur={() => {this.handleBlur('titleFocus')}}
                        autoFocus
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.innerSection}>
                    <div
                      onClick={() => {this.focusInput(this.start)}}
                      className={styles.inputIcon}
                    >
                      <MdAccessTime size={20} color="var(--main)" />
                    </div>
                    <div className={styles.inputContain}>
                      <div className={styles.inputRow}>
                        <input
                          ref={(el) => {this.start = el}}
                          value={dateFns.isValid(new Date(this.state.startDate))
                            ? dateFns.format(this.state.startDate, 'YYYY-MM-DDTHH:mm')
                            : dateFns.format(new Date(), 'YYYY-MM-DDTHH:mm')
                          }
                          onChange={(e) => {this.handleChange(e, 'startDate')}}
                          placeholder="Start date"
                          type="datetime-local"
                          id="startDate"
                          className={styles.dateInput}
                          style={{marginBottom: 0}}
                        />
                      </div>
                      <div className={styles.inputRow}>
                        <input
                          value={dateFns.isValid(new Date(this.state.endDate))
                            ? dateFns.format(this.state.endDate, 'YYYY-MM-DDTHH:mm')
                            : dateFns.isValid(new Date(this.state.startDate))
                            ? dateFns.format(dateFns.addHours(this.state.startDate, 1), 'YYYY-MM-DDTHH:mm')
                            : dateFns.format(dateFns.addHours(new Date(), 1), 'YYYY-MM-DDTHH:mm')
                          }
                          onChange={(e) => {this.handleChange(e, 'endDate')}}
                          placeholder="End date"
                          type="datetime-local"
                          id="endDate"
                          min={dateFns.isValid(new Date(this.state.startDate))
                            ? dateFns.format(this.state.startDate, 'YYYY-MM-DDTHH:mm')
                            : false
                          }
                          className={styles.dateInput}
                          style={{marginTop: 0}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.innerSection}>
                    <div
                      className={styles.inputIcon}
                      onClick={() => this.focusInput(this.desc)}
                    >
                      <MdInfoOutline size={20} color="var(--main)" />
                    </div>
                    <div className={styles.inputContain}>
                      <div
                        className={styles.clearContain}
                        style={{opacity: this.state.description !== '' && this.state.descFocus ? 1 : 0 }}
                        onClick={() => {this.handleClear('description', this.desc)}}
                      >
                        <MdClear size={16} />
                      </div>
                      <input
                        ref={(el) => {this.desc = el}}
                        value={this.state.description}
                        onChange={(e) => {this.handleChange(e, 'description')}}
                        placeholder="Add description or url"
                        className={styles.textInput}
                        onFocus={() => {this.handleFocus('descFocus')}}
                        onBlur={() => {this.handleBlur('descFocus')}}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.innerSection}>
                    <div
                      className={styles.inputIcon}
                      onClick={() => this.focusInput(this.address)}
                    >
                      <MdLocationOn size={20} color="var(--main)" />
                    </div>
                    <div className={styles.inputContain}>
                      <div
                        className={styles.clearContain}
                        style={{opacity: this.state.address !== '' && this.state.addressFocus ? 1 : 0 }}
                        onClick={() => {this.handleClear('address', this.address)}}
                      >
                        <MdClear size={16} />
                      </div>
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={(e) => {this.handleChange(e, 'address')}}
                        onSelect={this.handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                ref: (el) => {this.address = el},
                                placeholder: 'Add address',
                                className: styles.textInput,
                                onFocus: () => {this.handleFocus('addressFocus')},
                                onBlur: () => {this.handleBlur('addressFocus')}
                              })}
                            />
                            <div className={styles.placesAutocomplete}>
                              {loading && <div className={styles.address}>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? styles.activeAddress
                                  : styles.address;
                                return (
                                  <div {...getSuggestionItemProps(suggestion, { className })}>
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                </div>
                <ImageInput
                  onChange={(img) => this.setState({ image: img })}
                  onClose={() => this.setState({ image: '' })}
                  image={this.state.image}
                  expanded={this.state.showImage}
                />
                <PrivacyToggle
                  value={this.state.private}
                  toggle={(val) => this.setState({ private: val })}
                />
                {/*!this.state.editEvent &&
                  <PingInput
                    add={this.addPing}
                    del={this.delPing}
                    addGroup={this.addGroupPing}
                    pings={this.state.pings}
                    autoFocus={false}
                    match={match}
                    history={history}
                    flat
                  />
                */}
                {/*this.state.errorMsg && <ErrorMsg error={{ message: this.state.errorMsg }} small />*/}
                <Button
                  type="submit"
                  disabled={this.state.loading}
                  label={this.state.loading
                    ? 'Loading...'
                    : this.state.editEvent
                      ? 'Edit Event'
                      : 'Create Event'
                  }
                />
              </form>
            )}
          </ApolloConsumer>
        </div>
        <TabBar match={match} />
        <AddToCal
          shown={this.state.showAddToCal}
          event={this.state.eventToAdd}
          hide={this.hideAddToCal}
          type={this.state.shareType}
        />
      </div>
    )
  }
}

/*
<div className={styles.tagInputContain}>
  <input
    value={this.state.tagInput}
    onChange={(e) => {this.handleChange(e, 'tagInput')}}
    placeholder="Search for tags"
    className={styles.tagInput}
  />
  <button className={styles.addTagButton}>
    <MdAdd size={20} color="white" />
  </button>
</div>
*/

const UrlPreviewQuery = gql`
  query urlPreview($url: String!) {
    urlPreview(url: $url) {
      url
      title
      image
      snippet
      icon
    }
  }
`;

const CreateEventMutation = gql`
  mutation createEvent($event: EventInput!) {
    createEvent(event: $event) {
      id
      host {
        id
        name
        email
        profileImage
      }
      title
      added {
        id
        name
        email
        profileImage
      }
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      urls {
        url
        title
        image
        snippet
        icon
      }
      private
    }
  }
`;

const EventsQuery = gql`
  query events($userId: ID!, $pagination: Pagination!, $dateRange: DateRange) {
    events(userId: $userId, pagination: $pagination, dateRange: $dateRange) {
      id
      host {
        id
        name
        email
        profileImage
      }
      title
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      haveAdded
      addedCount
      private
    }
  }
`;

const EditEventMutation = gql`
  mutation editEvent($eventId: ID!, $event: EditEventInput!) {
    editEvent(eventId: $eventId, event: $event) {
      id
      host {
        id
        name
        email
        profileImage
      }
      title
      added {
        id
        name
        email
        profileImage
      }
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      urls {
        url
        title
        image
        snippet
        icon
      }
      private
    }
  }
`;

const EventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      host {
        id
        name
        email
        profileImage
      }
      title
      added {
        id
        name
        email
        profileImage
      }
      image
      description
      location {
        lat
        lon
      }
      address
      eventTimestampStart
      eventTimestampEnd
      createdAt
      haveAdded
      private
    }
  }
`;

const EventDatesQuery = gql`
  query eventDates($userId: ID!, $dateRange: DateRange!) {
    eventDates(userId: $userId, dateRange: $dateRange)
  }
`;

export default compose(
  graphql(CreateEventMutation, {
    name: 'createEvent',
    refetchQueries: [
      {
        query: EventsQuery,
        variables: {
          userId: user ? user.id : null,
          pagination: {
            offset: 0,
            limit: 50
          }
        }
      },
      {
        query: EventDatesQuery,
        variables: {
          userId: user ? user.id : null,
          dateRange: {
            timestampStart: new Date(dateFns.format(dateFns.subYears(new Date(), 1), 'MM/DD/YYYY')),
            timestampEnd: new Date(dateFns.format(dateFns.addYears(new Date(), 5), 'MM/DD/YYYY'))
          }
        }
      }
    ],
  }),
  graphql(EditEventMutation, {
    name: 'editEvent',
    update: (store, { data: { editEvent } }) => {
      const { event } = store.readQuery({
        query: EventQuery,
        variables: {
          eventId: this.props.match.params.id
        }
      });
      store.writeQuery({
        query: EventQuery,
        data: { event: Object.assign({}, event, {}) }
      });
    }
  })
)(CreateEvent);

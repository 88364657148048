import React from 'react';

import styles from './About.module.css';
import AboutHeader from './AboutHeader';
import AboutSection from './AboutSection';
import MenuModal from '../Common/MenuModal';
import TabBar from '../Common/TabBar';

class Privacy extends React.Component {
  state = {
    showMenu: false
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    const { history, match } = this.props;
    
    return (
      <div>
        <AboutHeader 
          title="Privacy Policy"
          history={history}
          toggle={this.toggleMenu}
        />
        <div className={styles.contain}>
          <AboutSection 
            className={styles.first}
            header="Privacy Policy"
          />
          <AboutSection 
            subHeader="1.1 Basic Account Information"
            body="You don’t have to create an account to use some of our service features, such as searching and viewing public Appearix profiles or events. If you do choose to create an account, you must provide us with some personal data so that we can provide our services to you. On Appearix this includes a display name, a username, a password, and an email address or phone number. Your display name and username are always public, but you can use either your real name or a pseudonym. You can also create and manage multiple Appearix accounts, for example to express different parts of your identity."  
          />
          <AboutSection 
            subHeader="1.2 Public Information"
            body={`Most activity on Appearix is public, including your profile information, your event feed, added calendar, language, when you created your account, and and certain information like the date, time, and application and version of Appearix you are using. The people you follow and who follow you, and events you add, are also public. Information posted about you by other people who use our services may also be public. For example, others may ping you via email, SMS or Appearix notifications about an event.${'\n\n'}You are responsible for any information you provide through our services, and you should think carefully about what you make public, especially if it is sensitive information. If you update your public information on Appearix, such as by deleting an event or deactivating your account, we will reflect your updated content on Appearix.com, Appearix for iOS, and Appearix for Android.${'\n\n'}In addition to providing your public information to the world directly on Appearix, we also use technology like application programming interfaces (APIs) and embeds to make that information available to websites, apps, and others for their use - for example, displaying a calendar on a website. We generally make this content available in limited quantities for free.`}
          />
          <AboutSection 
            subHeader="1.3 Contact Information and Address Books"
            body={`We use your contact information, such as your email address or phone number, to authenticate your account and keep it - and our services - secure, and to help prevent spam, fraud, and abuse. We also use contact information to personalize our services, enable certain account features (for example, for login verification or Appearix via SMS), and to send you information about our services. If you provide us with your phone number, you agree to receive text messages from Appearix to that number as your country’s laws allow. Appearix also uses your contact information to market to you as your country’s laws allow, and to help others find your account if your settings permit, including through third-party services and client applications. You can use your settings for email and mobile notifications to control notifications you receive from Appearix. You can also unsubscribe from a notification by following the instructions contained within the notification or here.${'\n\n'}If you email us, we will keep the content of your message, your email address, and your contact information to respond to your request.`}
          />
          <AboutSection 
            subHeader="1.4 Direct Messages and Non-Public Communications"
            body="We provide certain features that let you communicate more privately or control who sees your content. For example, you can use pinging to have non-public notifications on Appearix. When you communicate with others by sending or receiving these private communications, we will store and process your communications and information related to them. This includes link scanning for malicious content, detection of spam and prohibited images, and review of reported issues. We also use information about whom you have communicated with and when (but not the content of those communications) to better understand the use of our services, to protect the safety and integrity of our platform, and to show more relevant content. We share the content of your private communications with the people you’ve sent them to. Note that if you interact in a way that would ordinarily be public with Appearix content shared with you via private communication, for instance by adding an event, those interactions will be public. When you use private communications, remember that recipients have their own copy of your communications on Appearix - even if you delete your copy of those messages from your account - which they may duplicate, store, or re-share."
          />
          <AboutSection 
            subHeader="2 Additional Information We Receive About You"
            body="We receive certain information when you use our services or other websites or mobile applications that include our content, and from third parties including advertisers. Like the information you share with us, we use the data below to operate our services."
          />
          <AboutSection 
            subHeader="2.1 Location Information"
            body={`We require information about your signup and current location, which we get from signals such as your IP address or device settings, to securely and reliably set up and maintain your account and to provide our services to you.${'\n\n'}Subject to your settings, we may collect, use, and store additional information about your location - such as your current precise position or places where you’ve previously used Appearix - to operate or personalize our services including with more relevant content like local trends, stories, ads, and suggestions for people to follow.`}
          />
          <AboutSection 
            subHeader="2.2 Links"
            body={`In order to operate our services, we keep track of how you interact with links across our services. This includes links in emails we send you and links in Appearix content that appears on other websites or mobile applications.${'\n\n'}If you click on an external link or ad on our services, that advertiser or website operator might figure out that you came from Appearix, along with other information associated with the ad you clicked such as characteristics of the audience it was intended to reach. They may also collect other personal data from you, such as cookie identifiers or your IP address.`}
          />
          <AboutSection 
            subHeader="2.3 Cookies"
            body="A cookie is a small piece of data that is stored on your computer or mobile device. Like many websites, we use cookies and similar technologies to collect additional website usage data and to operate our services. Cookies are not required for many parts of our services such as searching and looking at public profiles. Although most web browsers automatically accept cookies, many browsers’ settings can be set to decline cookies or alert you when a website is attempting to place a cookie on your computer. However, some of our services may not function properly if you disable cookies. When your browser or device allows it, we use both session cookies and persistent cookies to better understand how you interact with our services, to monitor aggregate usage patterns, and to personalize and otherwise operate our services such as by providing account security, personalizing the content we show you including ads, and remembering your language preferences. We do not support the Do Not Track browser option. You can learn more about how we use cookies and similar technologies here."
          />
          <AboutSection 
            subHeader="2.4 Log Data"
            body={`We receive information when you view content on or otherwise interact with our services, which we refer to as “Log Data,” even if you have not created an account. For example, when you visit our websites, sign into our services, interact with our email notifications, use your account to authenticate to a third-party service, or visit a third-party service that includes Appearix content, we may receive information about you. This Log Data includes information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile carrier, device information (including device and application IDs), search terms, and cookie information. We also receive Log Data when you click on, view, or interact with links on our services, including when you install another application through Appearix. We use Log Data to operate our services and ensure their secure, reliable, and robust performance. For example, we use Log Data to protect the security of accounts and to determine what content is popular on our services. We also use this data to improve the content we show you, including ads.${'\n\n'}We use information you provide to us and data we receive, including Log Data and data from third parties, to make inferences like what topics you may be interested in  and what languages you speak. This helps us better design our services for you and personalize the content we show you, including ads.`}
          />
          <AboutSection 
            subHeader="2.5 Appearix for Web Data"
            body="When you view our content on third-party websites that integrate Appearix content such as embedded calendars or feeds timelines, we may receive Log Data that includes the web page you visited. We use this information to better understand the use of our services, to protect the safety and integrity of our platform, and to show more relevant content, including ads. We do not associate this web browsing history with your name, email address, phone number, or username, and we delete, obfuscate, or aggregate it after no longer than 30 days. We do not collect this data from browsers that we believe to be located in the European Union or EFTA States."
          />
          <AboutSection 
            subHeader="2.6 Advertisers and Other Ad Partners"
            body={`Advertising revenue allows us to support and improve our services. We use the information described in this Privacy Policy to help make our advertising more relevant to you, to measure its effectiveness, and to help recognize your devices to serve you ads on and off of Appearix. Our ad partners and affiliates share information with us such as browser cookie IDs, mobile device IDs, hashed email addresses, demographic or interest data, and content viewed or actions taken on a website or app. Some of our ad partners, particularly our advertisers, also enable us to collect similar information directly from their website or app by integrating our advertising technology.${'\n\n'}Appearix adheres to the Digital Advertising Alliance Self-Regulatory Principles for Online Behavioral Advertising (also referred to as “interest-based advertising”) and respects the DAA’s consumer choice tool for you to opt out of interest-based advertising at https://optout.aboutads.info. In addition, our ads policies prohibit advertisers from targeting ads based on categories that we consider sensitive or are prohibited by law, such as race, religion, politics, sex life, or health. Learn more about your privacy options for interest-based ads here and about how ads work on our services here.${'\n\n'}If you are an advertiser or a prospective advertiser, we process your personal data to help offer and provide our advertising services. You can update your data in your Appearix Ads dashboard or by contacting us directly as described in this Privacy Policy.`}
          />
          <AboutSection 
            subHeader="2.7 Other Third Parties and Affiliates"
            body={`We may receive information about you from third parties who are not our ad partners, such as others on Appearix, partners who help us evaluate the safety and quality of content on our platform, our corporate affiliates, and other services you link to your Appearix account.${'\n\n'}You may choose to connect your Appearix account to accounts on another service, and that other service may send us information about your account on that service. We use the information we receive to provide you features like cross-posting or cross-service authentication, and to operate our services. For integrations that Appearix formally supports, you may revoke this permission at any time from your application settings; for other integrations, please visit the other service you have connected to Appearix.`}
          />
          <AboutSection 
            subHeader="2.8 Personalizing Across Your Devices"
            body="When you log into Appearix on a browser or device, we will associate that browser or device with your account for purposes such as authentication, security, and personalization. Subject to your settings, we may also associate your account with browsers or devices other than those you use to log into Appearix (or associate your logged-out device or browser with other browsers or devices). We do this to operate and personalize our services."
          />
          <AboutSection 
            subHeader="3 Information We Share and Disclose"
            body="As noted above, Appearix is designed to broadly and instantly disseminate information you share publicly through our services. In the limited circumstances where we disclose your private personal data, we do so subject to your control, because it’s necessary to operate our services, or because it’s required by law."
          />
          <AboutSection 
            subHeader="3.1 Sharing You Control"
            body={`We share or disclose your personal data with your consent or at your direction, such as when you authorize a third-party web client or application to access your account or when you direct us to share your feedback with a business. If you’ve shared information with someone else who accesses Appearix through a third-party service, keep in mind that the information may be shared with the third-party service.${'\n\n'}Subject to your settings, we also may provide certain third parties with personal data to help us offer or operate our services. The information we share with these partners does not include your name, email address, phone number, or Appearix username, but some of these partnerships may allow the information we share to be linked to other personal information if the partner gets your consent first.`}
          />
          <AboutSection 
            subHeader="3.2 Service Providers"
            body="We engage service providers to perform functions and provide services to us in the United States, Ireland, and other countries. For example, we use a variety of third-party services to help operate our services, such as hosting our various blogs and wikis, and to help us understand the use of our services, such as Google Analytics. We may share your private personal data with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures, and on the condition that the third parties use your private personal data only on our behalf and pursuant to our instructions."
          />
          <AboutSection 
            subHeader="3.3 Law, Harm, and the Public Interest"
            body="Notwithstanding anything to the contrary in this Privacy Policy or controls we may otherwise offer to you, we may preserve, use, or disclose your personal data if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our services, or to explain why we have removed content or accounts from our services; to address fraud, security, or technical issues; or to protect our rights or property or the rights or property of those who use our services. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your personal data."
          />
          <AboutSection 
            subHeader="3.4 Affiliates and Change of Ownership"
            body="In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your personal data may be sold or transferred as part of that transaction. This Privacy Policy will apply to your personal data as transferred to the new entity. We may also disclose personal data about you to our corporate affiliates in order to help operate our services and our affiliates’ services, including the delivery of ads."
          />
          <AboutSection 
            subHeader="3.5 Non-Personal Information"
            body="We share or disclose non-personal data, such as aggregated information like the total number of times people engaged with an event, the number of people who clicked on a particular link, or reports to advertisers about how many people saw or clicked on their ads."
          />
          <AboutSection 
            subHeader="4 Managing Your Personal Information With Us"
            body="You control the personal data you share with us. You can access or rectify this data at any time. You can also deactivate your account. We also provide you tools to object, restrict, or withdraw consent where applicable for the use of data you have provided to Appearix. And we make the data you shared through our services portable and provide easy ways for you to contact us."
          />
          <AboutSection 
            subHeader="4.1 Accessing or Rectifying Your Personal Data"
            body="If you have registered an account on Appearix, we provide you with tools and account settings to access, correct, delete, or modify the personal data you provided to us and associated with your account."
          />
          <AboutSection 
            subHeader="4.2 Deletion"
            body={`We keep Log Data for a maximum of 18 months. When deactivated, your Appearix account, including your display name, username, and public profile, will no longer be viewable on Appearix.com, Appearix for iOS, and Appearix for Android. For up to 30 days after deactivation it is still possible to restore your Appearix account if it was accidentally or wrongfully deactivated.${'\n\n'}Keep in mind that search engines and other third parties may still retain copies of your public information, like your profile information and public events, even after you have deleted the information from our services or deactivated your account.`}
          />
          <AboutSection 
            subHeader="4.3 Settings"
            body="When you are logged into your Appearix account, you can manage your privacy settings at any time."
          />
          <AboutSection 
            subHeader="4.4 Additional Information or Assistance"
            body={`Thoughts or questions about this Privacy Policy? Please let us know by contacting us here or writing to us at the appropriate address below.${'\n\n'}If you live in the United States, the data controller responsible for your personal data is Appearix, Inc. with an address of:${'\n\n'}Appearix, Inc.${'\n'}Attn: Privacy Policy Inquiry${'\n'}1001 Elwell Court${'\n'}Palo Alto, CA 94303`}
          />
          <AboutSection 
            subHeader="5 Children and Our Services"
            body="Our services are not directed to children, and you may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf)."
          />
          <AboutSection 
            subHeader="6 Changes to This Privacy Policy"
            body="We may revise this Privacy Policy from time to time. The most current version of the policy will govern our processing of your personal data and will always be at https://Appearix.com/privacy. If we make a change to this policy that, in our sole discretion, is material, we will notify you via an @Appearix update or email to the email address associated with your account. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy."
            className={styles.last}
          />
        </div>
        <TabBar match={match} />
        <MenuModal 
          visible={this.state.showMenu}
          toggle={this.toggleMenu}
          tall={false}
          items={[
            {
              label: 'About us',
              onClick: () => history.push('/About')
            },
            {
              label: 'Terms of Service',
              onClick: () => history.push('/TOS')
            }
          ]}
        />
      </div>
    )
  }
}

export default Privacy;
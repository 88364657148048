import React from 'react';

import styles from './PrivacyToggle.module.css';
import IconInput from './IconInput';
import { MdLock } from 'react-icons/md';

const PrivacyToggle = ({ toggle, value }) => (
  <IconInput
    icon={
      <MdLock
        size={20}
        color="var(--main)"
      />
    }
    content={
      <div style={{ padding: '12px 0' }}>
        <div className={styles.row}>
          <div
            onClick={() => toggle(false)}
            className={!value
              ? styles.active
              : styles.button
            }
          >
            Public
          </div>
          <div
            onClick={() => toggle(true)}
            className={value
              ? styles.active
              : styles.button
            }
          >
            Private
          </div>
        </div>
        {value &&
          <p className={styles.message}>
            Only people who've been pinged can see this event.
          </p>
        }
      </div>
    }
  />
);

export default PrivacyToggle;

import React from 'react';
import { MdClear, MdMyLocation } from 'react-icons/md';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

// import styles from './Discover.module.css';
import styles from '../Home/Home.module.css';
import withContext from '../Context/withContext';
import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import Loading from '../Common/Loading';
import PersonItem from '../Common/PersonItem';
import AddToCal from '../Common/AddToCal';
import Empty from '../Common/Empty';
import InfiniteScroll from '../Common/InfiniteScroll';
import TagsRow from './TagsRow';
import { DISCOVER_TAGS, SF_GEO } from '../../utils/constants';
import { getLocation, getUser } from '../../utils/userState';
import { shuffle } from '../../utils/shuffle';
import ErrorMsg from '../Common/ErrorMsg';
import { fakeEvents } from '../../utils/fakeData';
import EventItem from '../Common/EventItem';

class Discover extends React.Component {
  constructor(props) {
    super(props);

    const address = JSON.parse(localStorage.getItem('formattedAddress') || 'false');
    const latLong = JSON.parse(localStorage.getItem('latLong'));

    const { context } = this.props;
    const activeTag = context.getDiscoverTag();

    this.state = {
      showAddToCal: false,
      eventToAdd: {},
      shareType: 'event',
      location: latLong ? latLong : SF_GEO,
      address: address ? address : '',
      showModal: false,
      loading: false,
      activeTag: activeTag ? activeTag : false
    }
  }

  tags = DISCOVER_TAGS
  user = getUser();

  componentWillMount() {
    this.tags = shuffle(DISCOVER_TAGS);
    if (this.state.activeTag) {
      this.tags = this.tags.filter(item => item !== this.state.activeTag);
    }
  }

  selectTag = (tag) => {
    const { context } = this.props;

    if (tag === this.state.activeTag) {
      this.tags.unshift(tag);
      this.setState({
        activeTag: false
      });
      context.setDiscoverTag(false);
    }
    else {
      if (this.state.activeTag) this.tags.unshift(this.state.activeTag);
      this.tags = this.tags.filter(item => item !== tag);
      this.setState({
        activeTag: tag
      });
      context.setDiscoverTag(tag);
    }

    document.documentElement.scrollTop = 0;
  }

  clearLocation = () => {
    this.address.focus();
    this.setState({ address: '', location: false });
    localStorage.setItem('formattedAddress', '');
    localStorage.setItem('latLong', 'false')
  }

  showAddToCal = (eventInfo = {}, type = 'event') => {
    this.setState({ showAddToCal: true, eventToAdd: eventInfo, shareType: type });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
    this.address && this.address.focus();
    document.documentElement.scrollTop = 0;
  }

  locationChange = (address) => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.setState({ loading: true });
    geocodeByAddress(address)
      .then(results => {
        const res = results[0];
        this.setState({ address: res.formatted_address });
        localStorage.setItem('formattedAddress', JSON.stringify(res.formatted_address));
        return res;
      })
      .then(re => getLatLng(re))
      .then(({ lat, lng }) => {
        const coords = { lat: lat.toString(), lon: lng.toString() };
        this.parent.scrollTop = 0;
        this.setState({ location: coords, showModal: false, loading: false })
        localStorage.setItem('latLong', JSON.stringify(coords));
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    const { match } = this.props;
    const location = getLocation();

    const usedLoc = this.state.location
      ? this.state.location
      : location
        ? location
        : SF_GEO;

    return (
      <div>
        <Header
          match={match}
        />
        <div className="body">
          <div className={styles.eventsContain}>
            <div className={styles.homeContain}>
              {fakeEvents().results.map((eventRecord, index) => (
                <EventItem
                  event={eventRecord}
                  showAddToCal={this.showAddToCal}
                  hideAddToCal={this.hideAddToCal}
                  key={index}
                />
              ))}
           </div>
          </div>
        </div>
        <TabBar match={match} />
      </div>
    )

    /*
    return (
      <div>
        <Header
          match={match}
          customNav={
            <TagsRow
              locationToggle={this.toggleModal}
              activeTag={this.state.activeTag}
              tags={this.tags}
              selectTag={this.selectTag}
              light
            />
          }
          share={this.showAddToCal}
          pageUserId={this.user.id}
        />
        <div className="body">
          {this.state.showModal &&
            <div className={styles.locationModal}>
              <p className={styles.locationHeader}>Change Location</p>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.locationChange}
                onSelect={this.handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className={styles.locationContain}>
                      <div className={styles.locationIcon}>
                        <MdMyLocation size={20} />
                      </div>
                      <input
                        {...getInputProps({
                          ref: (el) => {this.address = el},
                          placeholder: 'Change location',
                          className: styles.locationInput,
                          autoFocus: true
                        })}
                      />
                      <div
                        onClick={this.clearLocation}
                        className={styles.locationClear}
                        style={{opacity: this.state.address !== '' ? 1 : 0 }}
                      >
                        <MdClear size={12} />
                      </div>
                    </div>
                    <div className={styles.placesAutocomplete}>
                      {loading && <div className={styles.address}>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? styles.activeAddress
                          : styles.address;
                        return (
                          <div {...getSuggestionItemProps(suggestion, { className })} key={i}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                      {this.state.loading && <Loading />}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div className={styles.closeContain}>
                <div onClick={this.toggleModal} className={styles.closeModal}>Close</div>
              </div>
            </div>
          }
          <div className={styles.contain} ref={(ref) => this.parent = ref}>
            <div className={styles.eventsList}>
              <Query
                query={DiscoverQuery}
                variables={{
                  pagination: {
                    offset: 0,
                    limit: 10
                  },
                  location: usedLoc,
                  sortTag: this.state.activeTag
                    ? this.state.activeTag
                    : undefined
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data, fetchMore }) => {
                  if (error) return <ErrorMsg error={error} />;

                  const { discover } = data;
                  if (!discover) return <Loading />;
                  else if (discover.results.length === 0 && !this.state.activeTag) return <Empty page="discover" />

                  const loadMore = () => {
                    fetchMore({
                      variables: {
                        pagination: {
                          offset: discover ? discover.results.length : 0,
                          limit: 10
                        }
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const newRes = Object.assign({}, prev, {
                          discover: {
                            total: prev.discover.total,
                            results: [...prev.discover.results, ...fetchMoreResult.discover.results],
                            __typename: 'EventList'
                          }
                        });
                        return newRes;
                      }
                    });
                  };

                  return (
                    <InfiniteScroll
                      loading={loading}
                      getParent={() => this.parent}
                      hasMore={discover
                        ? (discover.results.length < discover.total && discover.results.length > 9)
                        : false
                      }
                      loadMore={loadMore}
                      match={match}
                    >
                      <div>
                        <h3 className={styles.sectionHeader}>Discover</h3>
                        {discover.results.map((person, i) => (
                          <PersonItem person={person} key={i} />
                        ))}
                      </div>
                    </InfiniteScroll>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
        <TabBar match={match} />
        <AddToCal
          shown={this.state.showAddToCal}
          event={this.state.eventToAdd}
          hide={this.hideAddToCal}
          type={this.state.shareType}
        />
      </div>
    )
    */
  }
};

const DiscoverQuery = gql`
  query discover($pagination: Pagination!, $location: GeoPointInput, $sortTag: String) {
    discover(pagination: $pagination, location: $location, sortTag: $sortTag) {
      total
      results {
        id
        profileImage
        name
        haveFollowed
        upcomingEventsCount
        followersCount
        topUpcomingEvents {
          id
          title
          eventTimestampStart
        }
      }
    }
  }
`;

/*
OLD LOCATION HEADER

<p className={styles.sectionHeader}>
  Users nearby
  <span className={styles.locationHeader} onClick={this.toggleModal}>
    <MdMyLocation size={18} />
    {this.state.location ? this.state.address : location ? 'Current location' : 'Enter your location'}
  </span>
</p>
*/

export default withContext(Discover);

import React from 'react';
import { 
  MdPeople, 
  MdKeyboardArrowDown, 
  MdKeyboardArrowUp 
} from 'react-icons/md';

import styles from './Group.module.css';
import DeleteGroupButton from './DeleteGroupButton';
import PingItem from '../Pings/PingItem';

class Group extends React.Component {
  state = {
    expanded: false,
    deleted: false
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  toggleDelete = () => {
    this.setState({
      deleted: !this.state.deleted
    });
  }

  render() {
    const { group, history } = this.props;
    const { title, pings } = group;
    const { expanded, deleted } = this.state;

    const deletedStyle = { 
      background: deleted 
        ? 'var(--text-light)' 
        : 'var(--main)' 
    };

    return (
      <div className={styles.contain}>
        <div 
          className={styles.topContain} 
          onClick={this.toggleExpanded}
          style={expanded ? { borderColor: '#eee' } : {}}
        >
          <div 
            className={styles.icon}
            style={deletedStyle}
          >
            <MdPeople size={20} />
          </div>
          <div className={styles.textContain}>
            <p className={styles.title}>{title}</p>
            <span className={styles.subtitle}>{pings.length} members</span>
          </div>
          <div className={styles.actionContain}>
            <div className={styles.expand}>
              {expanded
                ? <MdKeyboardArrowUp size={20} />
                : <MdKeyboardArrowDown size={20} />
              }
            </div>
          </div>
        </div>
        <div 
          className={styles.bottomContain}
          style={!expanded ? { height: 0 } : { padding: 12 }}
        >
          {pings.length
            ? pings.map((ping, i) => (
                <PingItem
                  ping={ping}
                  key={i} 
                  onClick={() => history.push(`/Profile/${ping.user.id}`)}
                  imageStyle={{ margin: '4px 17px 4px 5px' }}
                />
              ))
            : <span className={styles.span}>Nobody is in this group yet.</span>
          }
          <div className={styles.buttonRow}>
            <button 
              onClick={() => history.push(`/EditGroup/${group.id}`)}
              className={styles.editButton}
              disabled={deleted}
              style={deletedStyle}
            >
              Edit Group
            </button>
            <DeleteGroupButton 
              group={group} 
              toggleDelete={this.toggleDelete}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Group;
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import styles from './Admin.module.css';
import ImageInput from '../Common/ImageInput';
import removeEmptyFields from '../../utils/removeEmptyFields';
import removeTypename from '../../utils/removeTypename';

const defaultState = {
  name: '',
  profileImage: null,
  address: '',
  location: null,
  urls: '',
  tags: '',
  bio: '',
  imgUrl: '',
  msg: null
};

class OrgForm extends React.Component {
  state = {
    ...defaultState,
    ...this.props.defaultValues ? removeTypename(removeEmptyFields(this.props.defaultValues)) : {},
    urls: this.props.defaultValues && this.props.defaultValues.urls ? this.props.defaultValues.urls.map(url => url.url).join(',') : '',
    tags: this.props.defaultValues && this.props.defaultValues.tags ? this.props.defaultValues.tags.join(',') : '',
  };

  componentDidMount() {
    this.setState({});
  }

  editOrg = async (editUser) => {
    try {
      this.setState({ msg: null });
      const res = await editUser({
        variables: {
          userId: this.props.id,
          user: this.buildOrg()
        }
      });
      if (res.data.editUser) {
        alert('Successfully edited organization');
      }
      else {
        this.setState({ msg: 'Failed to edit organization, please try again' });
      }
    }
    catch (error) {
      this.setState({ msg: `Error: ${error } during edit organization` });
      console.error(error);
    }
  }

  uploadOrg = async (createOrg) => {
    try {
      this.setState({ msg: null });
      const res = await createOrg({ variables: { org: this.buildOrg() } });
      if (res.data.createOrg) {
        window.location.reload();
        alert('Successfully created organization');
      }
      else {
        this.setState({ msg: 'Failed to create organization, please try again' });
      }
    }
    catch (error) {
      this.setState({ msg: `Error: ${error} during organization creation` });
      console.error(error);
    }
  }

  splitByComma = (str) => {
    if (str.length > 0) {
      return str.split(',').map(str => str.trim());
    }
    return [];
  }

  buildOrg = () => {
    const img = this.state.profileImage ? this.state.profileImage : this.state.imgUrl.length > 0 ? this.state.imgUrl : null;

    const org = {
      name: this.state.name,
      profileImage: img,
      address: this.state.address.length > 0 ? this.state.address : null,
      location: this.state.location,
      urls: this.splitByComma(this.state.urls).map(url => { return { url } }),
      tags: this.splitByComma(this.state.tags),
      bio: this.state.bio.length > 0 ? this.state.bio : null
    };

    return org;
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ location: { lat: `${latLng.lat}`, lon: `${latLng.lng}` } }))
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <div className={styles.formContainer}>
        {this.props.id ? <h1>Edit Appearix Organization: {this.props.id}</h1> : <h1>Create Appearix Organization</h1>}
        Name
        <input className={styles.input} value={this.state.name} onChange={e => this.setState({ name: e.target.value })} type="text" placeholder="Name"></input>
        <br />
        <br />
        Image
        <ImageInput
          onChange={(img) => {this.setState({ profileImage: img })}}
          onClose={() => {this.setState({ profileImage: null })}}
          image={this.state.profileImage}
          expanded={this.state.profileImage}
        />
        <input className={styles.input} type="text" value={this.state.imgUrl} onChange={e => this.setState({ imgUrl: e.target.value })} placeholder="Or image url"></input>
        <br />
        <br />
        Bio
        <textarea className={styles.input} value={this.state.bio} onChange={e => this.setState({ bio: e.target.value })} rows="5" placeholder="Bio"></textarea>
        <br />
        <br />
        Location
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: `location-search-input ${styles.input}`,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <br />
        URLs
        <textarea className={styles.input} value={this.state.urls} onChange={e => this.setState({ urls: e.target.value })} rows="5" placeholder="Urls separated by comma (https://google.com/, https://example.com/)"></textarea>
        <br />
        <br />
        Tags
        <textarea className={styles.input} value={this.state.tags} onChange={e => this.setState({ tags: e.target.value })} rows="5" placeholder="Tags separated by comma (boulder, skiing)"></textarea>
        <br />
        {
          this.props.id
          ?
            <Mutation
              mutation={EditProfileMutation}
            >
              {(editUser) => (
                <button className={styles.submitButton} onClick={() => this.editOrg(editUser)} disabled={!this.state.name.length > 0}>Edit Organization</button>
              )}
            </Mutation>
          :
            <Mutation
              mutation={CreateOrgMutation}
            >
              {(createOrg) => (
                <button className={styles.submitButton} onClick={() => this.uploadOrg(createOrg)} disabled={!this.state.name.length > 0}>Create Organization</button>
              )}
            </Mutation>
        }
        {this.state.msg && <p>{this.state.msg}</p>}
      </div>
    );
  }
}

const CreateOrgMutation = gql`
  mutation createOrg($org: CreateOrgInput!) {
    createOrg(org: $org)
  }
`;

const EditProfileMutation = gql`
  mutation editUser($userId: ID!, $user: EditUserInput) {
    editUser(userId: $userId, user: $user) {
      user {
        id
      }
    }
  }
`;

export default OrgForm;

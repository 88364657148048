export default async function getIpAddress() {
  try {
    const res = await fetch('https://api.ipify.org/?format=json');
    const data = await res.json();
    return data && data.ip;
  }
  catch (error) {
    console.error(error);
  }
}

import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import styles from './SignIn.module.css';
import { signIn, setLocation, isSignedIn } from '../../utils/userState';
import withContext from '../Context/withContext';
import Loading from '../Common/Loading';

class SignIn extends React.Component {
  state = {
    bigWindow: window.innerWidth > 500 ? true : false,
    loading: false,
    private: false
  }

  firebase = this.props.context.getFirebase();

  uiConfig = {
    // signInFlow: 'popup',
    signInFlow: 'redirect',
    signInOptions: [
      this.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      this.firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      this.firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      this.firebase.auth.EmailAuthProvider.PROVIDER_ID,
      this.firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (authResult) {
          this.signInSuccess();
        }
        return false;
      }
    }
  };

  componentWillMount() {
    if (isSignedIn()) {
      this.props.history.push('/Events');
    }

    this.unregisterAuthObserver = this.firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.signInSuccess();
      }
    });

    const { location } = this.props;
    if (location && location.state) {
      if (location.state.followUserIds) {
        localStorage.setItem('followUserIds', JSON.stringify(location.state.followUserIds));
      }
      if (location.state.addEventIds) {
        localStorage.setItem('addEventIds', JSON.stringify(location.state.addEventIds));
      }
    };

    // Test if using safari private browsing mode
    try {
      window.openDatabase(null, null, null, null);
    }
    catch (e) {
      this.setState({
        private: true
      });
    }
  }

  signInSuccess = async () => {
    try {
      this.setState({ loading: true });

      const followUserIds = localStorage.getItem('followUserIds') || '[]';
      const addEventIds = localStorage.getItem('addEventIds') || '[]';

      const token = await this.firebase.auth().currentUser.getIdToken(true);
      const { data } = await this.props.mutate({
        variables: {
          token,
          followUserIds: JSON.parse(followUserIds),
          addedEvents: JSON.parse(addEventIds)
        }
      });

      signIn({ token: data.userAuth.token, user: data.userAuth.user });

      setLocation();

      this.props.history.push('/Events');
    }
    catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { history } = this.props;
    return (
      <div className={styles.contain}>
        <h1 className={styles.logo}>appear<span>ix</span></h1>
        {this.state.private
          ? <h4 style={{ color: 'var(--text-dark)'}}>Private browsing may cause<br/>problems with sign in!</h4>
          : <h4>You could be there!</h4>
        }
        {this.state.loading && <Loading />}
        {<StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={this.firebase.auth()} />}
        <h5 onClick={() => history.goBack()}>
          Cancel
        </h5>
      </div>
    );
  }
}

const signInWithContext = withContext(SignIn);

const SignInMutation = gql`
  mutation userAuth($token: String!, $followUserIds: [String], $addedEvents: [String]) {
    userAuth(token: $token, followUserIds: $followUserIds, addedEvents: $addedEvents) {
      user {
        id
        email
        profileImage
        name
        newUser
        admin
        externalAccounts
      }
      token
      success
    }
  }
`;

export default graphql(SignInMutation)(signInWithContext);

import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { MdLocationOn, MdAccountCircle, MdAssignment, MdClear } from 'react-icons/md';

import styles from './EditProfile.module.css';
import Header from '../Common/Header';
import TabBar from '../Common/TabBar';
import Loading from '../Common/Loading';
import { getUser, setToken, setUser } from '../../utils/userState';
import circles from '../../images/profileCircles.svg';
import ImageInput from '../Common/ImageInput';
import AddToCal from '../Common/AddToCal';
import Button from '../Common/Button';
import ErrorMsg from '../Common/ErrorMsg';

const ProfileQuery = gql`
  query profile($userId: ID!) {
    profile(userId: $userId) {
      id
      name
      profileImage
      haveFollowed
      followingCount
      followersCount
      upcomingEventsCount
      address
      bio
    }
  }
`;

const EditProfileMutation = gql`
  mutation editUser($userId: ID!, $user: EditUserInput) {
    editUser(userId: $userId, user: $user) {
      user {
        id
        email
        name
        profileImage
        address
        location {
          lat
          lon
        }
        bio
      }
      token
    }
  }
`;

const defaults = {
  name: 'Hurloon Wrangler',
  address: 'Some Place',
  bio: 'Some really cool and awesome bio.',
  profileImage: 'https://appearix.com/static/media/defaultProfile.png',
  location: {lat: '', lon: ''}
};

class EditBody extends React.Component {
  state = {
    newUser: false,
    nameFocuses: false,
    name: this.props.profile.name || defaults['name'],
    addressFocused: false,
    address: this.props.profile.address || defaults['address'],
    location: this.props.profile.location || { lat: '', lon: '' },
    bioFocuses: false,
    bio: this.props.profile.bio || defaults['bio'],
    profileImage: this.props.profile.profileImage || defaults['profileImage'],
    upcomingEventsCount: this.props.profile.upcomingEventsCount || 0,
    followingCount: this.props.profile.followingCount || 0,
    followersCount: this.props.profile.followersCount || 0,
    loading: false
  }

  user = getUser()

  componentDidMount() {
    const { location } = this.props;
    if (location.state) {
      this.setState({ newUser: location.state.newUser });
    }
  }

  clear = (name, ref) => {
    if (ref) ref.focus();
    this.setState({ [name]: '' });
  }

  handleChange = (e, name) => {
    let val = name === 'address' ? e : e.target.value;
    this.setState({ [name]: val });
  }

  handleFocus = (name) => {
    this.setState({ [name]: true });
  }

  handleBlur = (name) => {
    this.setState({ [name]: false });
  }

  focusInput = (ref) => {
    ref.focus();
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => results[0])
      .then(res => {
        this.setState({ address: res.formatted_address });
        return res;
      })
      .then(re => getLatLng(re))
      .then(({ lat, lng }) => {
        this.setState({ location: { lat: lat.toString(), lon: lng.toString() }})
      })
      .catch(error => console.error('Error', error));
  };

  onSubmit = async (editProfile) => {
    if (this.state.name === '') {
      this.setState({ errorMsg: 'You must have a name.' });
      return false;
    }

    const user = {
      name: this.state.name,
      profileImage: this.state.profileImage,
      bio: this.state.bio,
      address: this.state.address,
      location: this.state.location
    }

    if (user.location.lat.length === 0) {
      user.location = null;
    }

    const { error, data } = await editProfile({
      variables: {
        userId: this.user.id,
        user: user
      }
    });

    if (error) {
      console.error(error);
      this.setState({ loading: false });
    }

    if (data) {
      setToken(data.editUser.token);
      setUser(data.editUser.user);
      this.props.history.push(`/Profile/${this.user.id}`);
    }
  }

  render() {
    return (
      <Mutation mutation={EditProfileMutation}>
        {(editProfile, { data }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ loading: true });
              this.onSubmit(editProfile);
            }}
          >
            {this.state.newUser &&
              <div className={styles.midSection}>
                <img src={circles} alt="" className={styles.circles} />
                <div className={styles.midContain}>
                  <h2 className={styles.midHeader}>Update your profile</h2>
                  <p className={styles.midText}>This is your temporary user profile. Please update it.</p>
                </div>
              </div>
            }
            <div className={styles.header}>
              <div className={styles.innerSection}>
                <div className={styles.infoCol}>
                  <h3 className={styles.name}>{this.state.name}</h3>
                  <p className={styles.location}>{this.state.address}</p>
                  <p className={styles.about}>{this.state.bio}</p>
                  <div className={styles.statsRow}>
                    <div className={styles.statItem}>
                      <h2 className={styles.stat}>{this.state.upcomingEventsCount}</h2>
                      <p className={styles.statLabel}>Upcoming</p>
                    </div>
                    <div className={styles.statItem}>
                      <h2 className={styles.stat}>{this.state.followingCount}</h2>
                      <p className={styles.statLabel}>Following</p>
                    </div>
                    <div className={styles.statItem}>
                      <h2 className={styles.stat}>{this.state.followersCount}</h2>
                      <p className={styles.statLabel}>Followers</p>
                    </div>
                  </div>
                </div>
                <div className={styles.profileCol}>
                  <img
                    src={this.state.profileImage}
                    alt=""
                    className={styles.profile}
                  />
                  <Button
                    type="submit"
                    className={styles.unfollowButton}
                    disabled={this.state.loading}
                    label={this.state.loading ? 'Loading...' : 'Save Profile'}
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputs}>
              <div className={styles.inputSection}>
                <div className={styles.innerSection}>
                  <div
                    className={styles.inputIcon}
                    onClick={() => this.focusInput(this.name)}
                  >
                    <MdAccountCircle size={20} color="var(--main)" />
                  </div>
                  <div className={styles.inputContain}>
                    <div
                      className={styles.clearContain}
                      style={{opacity: this.state.name !== '' && this.state.nameFocused ? 1 : 0 }}
                      onClick={() => {this.clear('name', this.name)}}
                    >
                      <MdClear size={16} />
                    </div>
                    <input
                      ref={(el) => this.name = el}
                      value={this.state.name}
                      onChange={(e) => {this.handleChange(e, 'name')}}
                      placeholder="My name"
                      className={styles.titleInput}
                      onFocus={() => {this.handleFocus('nameFocused')}}
                      onBlur={() => {this.handleBlur('nameFocused')}}
                    />
                  </div>
                </div>
              </div>
              <ImageInput
                onChange={(img) => {this.setState({ profileImage: img })}}
                onClose={() => {this.setState({ profileImage: '' })}}
                image={this.state.profileImage}
                expanded={this.state.showImage}
              />
              <div className={styles.inputSection}>
                <div className={styles.innerSection}>
                  <div
                    className={styles.inputIcon}
                    onClick={() => this.focusInput(this.location)}
                  >
                    <MdLocationOn size={20} color="var(--main)" />
                  </div>
                  <div className={styles.inputContain}>
                    <div
                      className={styles.clearContain}
                      style={{opacity: this.state.address !== '' && this.state.addressFocused ? 1 : 0 }}
                      onClick={() => {this.clear('address', this.location)}}
                    >
                      <MdClear size={16} />
                    </div>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={(e) => {this.handleChange(e, 'address')}}
                      onSelect={this.handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              ref: (el) => this.location = el,
                              placeholder: 'My location',
                              className: styles.input,
                              onFocus: () => {this.handleFocus('addressFocused')},
                              onBlur: () => {this.handleBlur('addressFocused')}
                            })}
                          />
                          <div className={styles.placesAutocomplete}>
                            {loading && <div className={styles.address}>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? styles.activeAddress
                                : styles.address;
                              return (
                                <div {...getSuggestionItemProps(suggestion, { className })}>
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
              <div className={styles.inputSection}>
                <div className={styles.innerSection}>
                  <div
                    className={styles.inputIcon}
                    onClick={() => this.focusInput(this.bio)}
                  >
                    <MdAssignment size={20} color="var(--main)" />
                  </div>
                  <div className={styles.inputContain}>
                    <div
                      className={styles.clearContain}
                      style={{opacity: this.state.bio !== '' && this.state.bioFocused ? 1 : 0 }}
                      onClick={() => {this.clear('bio', this.bio)}}
                    >
                      <MdClear size={16} />
                    </div>
                    <input
                      ref={(el) => this.bio = el}
                      value={this.state.bio}
                      onChange={(e) => {this.handleChange(e, 'bio')}}
                      placeholder="A little bit about me"
                      className={styles.input}
                      onFocus={() => {this.handleFocus('bioFocused')}}
                      onBlur={() => {this.handleBlur('bioFocused')}}
                    />
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                disabled={this.state.loading}
                label={this.state.loading
                  ? 'Loading...'
                  : 'Save Profile'
                }
              />
            </div>
          </form>
        )}
      </Mutation>
    )
  }
}

class EditProfile extends React.Component {
  state = {
    showAddToCal: false,
    eventToAdd: {},
    shareType: 'profile'
  }

  showAddToCal = (eventInfo = {}, type = 'profile') => {
    this.setState({ showAddToCal: true, eventToAdd: eventInfo, shareType: type });
  }

  hideAddToCal = () => {
    this.setState({ showAddToCal: false })
  }

  render() {
    const { match, history, location } = this.props;
    const user = getUser();

    return (
      <div>
        <Header
          match={match}
          history={history}
          navTitle="Edit Profile"
          backButton
          share={this.showAddToCal}
          pageUserId={user.id}
        />
        <div className="body">
          <Query
            query={ProfileQuery}
            variables={{
              userId: user.id
            }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return <ErrorMsg error={error} />;

              return (
                <EditBody
                  profile={data.profile}
                  history={history}
                  location={location}
                />
              )
            }}
          </Query>
        </div>
        <TabBar match={match} />
        <AddToCal
          shown={this.state.showAddToCal}
          event={this.state.eventToAdd}
          hide={this.hideAddToCal}
          type={this.state.shareType}
        />
      </div>
    )
  }
}

export default EditProfile;

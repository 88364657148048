import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import GroupForm from './GroupForm';
import Loading from '../Common/Loading';
import ErrorMsg from '../Common/ErrorMsg';

const EditGroup = ({ history, match }) => {
  const id = match.params.id;
  return (
    <Query
      query={GroupQuery}
      variables={{
        groupId: id
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <ErrorMsg error={error} />;

        const { getGroup } = data;

        return (
          <Mutation
            mutation={EditGroupMutation}
            awaitRefetchQueries
            refetchQueries={() => [{ query: GroupsQuery }]}
          >
            {(editGroup) => (
              <GroupForm 
                type="Edit"
                mutate={editGroup}
                group={getGroup}
                history={history}
                match={match}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  )
}

const EditGroupMutation = gql`
  mutation editGroup($groupId: ID!, $title: String, $pings: [PingInput]!) {
    editGroup(groupId: $groupId, title: $title, pings: $pings)
  }
`;

const GroupQuery = gql`
  query getGroup($groupId: ID!) {
    getGroup(groupId: $groupId) {
      id
      title
      pings {
        type
        value
        user {
          id
          name
          profileImage
        }
      }
    }
  }
`;

const GroupsQuery = gql`
  query getGroups {
    getGroups {
      total
      results {
        id
        title
        pings {
          type
          value
        }
      }
    }
  }
`;

export default EditGroup;
import React from 'react';
import { Link } from 'react-router-dom';
import { MdAdd, MdCheck, MdAccessTime, MdLock } from 'react-icons/md';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import dateFns from 'date-fns';

import styles from './EventItem.module.css';
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants';
import { getUser } from '../../utils/userState';

const AddEventMutation = gql`
  mutation addEvent($eventId: ID!) {
    addEvent(eventId: $eventId)
  }
`;

const RemoveEventMutation = gql`
  mutation addEvent($eventId: ID!) {
    removeEvent(eventId: $eventId)
  }
`;

const EventQuery = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      haveAdded
    }
  }
`;

const EventItem = ({ 
  event,
  showAddToCal = null, 
  hideAddToCal = null,
  toggleSignUp = () => {}
}) => {
  const date = new Date(event.eventTimestampStart);
  const isPast = dateFns.isPast(date);
  const user = getUser();

  const renderAddButton = () => {
    if (!user) {
      return (
        <div
          className={styles.addContain}
          onClick={() => toggleSignUp(event.id, event.title, 'adding')}
        >
          <MdAdd
            size={25}
            className={styles.addIcon}
            color={isPast
              ? 'var(--text-light)'
              : 'var(--text)'
            }
          />
        </div>
      )
    }
    else if (event.haveAdded) {
      return (
        <Mutation
          mutation={RemoveEventMutation}
          update={(cache) => {
            cache.writeQuery({
              query: EventQuery,
              data: { event: Object.assign({}, event, { haveAdded: false, addedCount: event.addedCount - 1 }) }
            });
          }}
        >
          {(removeEvent) => (
            <div
              onClick={() => {
                if (!isPast) {
                  removeEvent({ variables: { eventId: event.id } });
                  hideAddToCal && hideAddToCal(event);
                }
              }}
              className={styles.addContain}
            >
              <MdCheck
                size={25}
                className={styles.addIcon}
                color={isPast
                  ? 'var(--text-light)'
                  : event.haveAdded
                    ? 'var(--main)'
                    : 'var(--text)'
                }
              />
            </div>
          )}
        </Mutation>
      )
    }
    else {
      return (
        <Mutation
          mutation={AddEventMutation}
          update={(cache) => {
            cache.writeQuery({
              query: EventQuery,
              data: { event: Object.assign({}, event, { haveAdded: true, addedCount: event.addedCount + 1 }) }
            });
          }}
        >
          {(addEvent) => (
            <div
              onClick={() => {
                if (!isPast) {
                  addEvent({ variables: { eventId: event.id } });
                  showAddToCal && showAddToCal(Object.assign({}, event, { haveAdded: true }));
                }
              }}
              className={styles.addContain}
            >
              <MdAdd
                size={25}
                className={styles.addIcon}
                color={isPast
                  ? 'var(--text-light)'
                  : event.haveAdded
                    ? 'var(--main)'
                    : 'var(--text)'
                }
              />
            </div>
          )}
        </Mutation>
      )
    }
  }

  const renderTime = (event) => {
    let time = ''

    if (event.eventTimestampEnd) {
      if (event.eventTimestampStart === event.eventTimestampEnd) {
        time += dateFns.format(event.eventTimestampStart, 'h:mma')
      }
      else {
        time += dateFns.format(event.eventTimestampStart, 'h:mma')
        time += dateFns.format(event.eventTimestampEnd, ' - h:mma')
      }
    }
    else {
      time += dateFns.format(event.eventTimestampStart, 'h:mma')
    }

    return time;
  }

  return (
    <div className={styles.contain}>
      <div className={styles.topRow}>
        <div
          className={styles.dateContain}
          style={{ backgroundColor: isPast
            ? 'var(--text-light)'
            : event.private
              ? 'var(--secondary)'
              : event.haveAdded
                ? 'var(--main)'
                : '#555'
          }}
        >
          {date && 
            <p className={styles.date}>
              {event.private && <MdLock className={styles.lock} size={14} />}
              {dateFns.format(date, 'MMM D')}
            </p>
          }
        </div>
        {!event.hideTime
          ? <p className={styles.time}> <MdAccessTime color="var(--text)" size={16} />{renderTime(event)}</p>
          : <p className={styles.time}></p>
        }
        {renderAddButton()}
      </div>
      <Link
        to={`/Event/${event.id}`}
        className={styles.middleRow}
      >
        <div
          className={styles.mainContain}
          style={event.image ? {width: 'calc(100% - 80px)'} : {width: '100%'}}
        >
          <div className={styles.descContain}>
            {event.title && <p className={styles.title}>{event.title}</p>}
            {event.description && <p className={styles.description}>{event.description}</p>}
            {event.host &&
              <div className={styles.organizer}>
                <img
                  src={event.host.profileImage
                    ? event.host.profileImage
                    : DEFAULT_PROFILE_IMAGE
                  }
                  alt=""
                  className={styles.organizerImage}
                />
                <span className={styles.inlineTail}>{event.host.name}</span>
              </div>
            }
            {event.address && 
              <p className={styles.location}>
                {event.address.length > 20
                  ? event.address.match(/([A-Z][a-z]+\s?)+,\s[A-Z]{2}/g)
                  : event.address
                }
              </p>
            }
          </div>
          {(event.topAdded && event.topAdded.length > 0) &&
            <div className={styles.bottomRow}>
              <p className={styles.addedCount}>
                <span>Added by</span>
                <img
                  src={event.topAdded[0].profileImage
                    ? event.topAdded[0].profileImage
                    : DEFAULT_PROFILE_IMAGE
                  }
                  alt=""
                  className={styles.topProfile}
                />
                <span className={styles.inlineTail}>
                  <strong>{event.topAdded[0].name}</strong>&nbsp;{event.addedCount - 1 > 0 && `+${event.addedCount - 1} more`}
                </span>
              </p>
            </div>
          }
        </div>
        {event.image &&
          <div className={styles.imageContain}>
            <img src={event.image} className={styles.image} alt="" />
          </div>
        }
      </Link>
    </div>
  );
};

/*

  {event.host &&
    <div className={styles.organizer}>
      <img
        src={event.host.profileImage}
        className={styles.hostImage}
        alt=""
      />
      <p>
        <strong>{event.host.name}</strong>
        <br />
        <span>Organized this event</span>
      </p>
    </div>
  }

  {date && <p className={styles.month}>{date.getMonth() + 1}.{date.getDate()}</p>* /}

  {/*<div className={styles.buttonRow}>
    <div className={styles.addEventButton}><span>+</span></div>
    <div className={styles.going}><span>8</span></div>
  </div>}
*/

export default EventItem;
